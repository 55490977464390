// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_albumsContainer__Hjmbj {
    width: 256px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000; 
    border-right: 1px solid #ddd;
    background-color: #fff;
}

.Home_albumsList__S2C13 {
    position: sticky;
    top: 0;
    border-bottom: 1px solid #ddd;
}

.Home_albumsControls__hWeku {
    border-top: 1px solid #f9f9f9;
    margin-top: auto;
}

.Home_imagesContainer__aVtV6 {
    flex: 1 1;
    box-sizing: border-box;
    margin: 0;
    margin-left: 256px;
}

.Home_imagesList__qimrX {
    margin: 0 !important;
    padding: 20px 10px;
}

@media (max-width: 768px) {
    
    .Home_albumsContainer__Hjmbj {
        position: static;
    }

    .Home_albumsList__S2C13, .Home_albumsControls__hWeku {
        position: static;
        background-color: inherit;
    }

    .Home_albumsContainer__Hjmbj, .Home_imagesContainer__aVtV6 {
        width: 100%;
        height: auto;
        margin-left: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS;IACT,aAAa;IACb,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IAEI,gBAAgB;IAChB,MAAM;IACN,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,sBAAsB;IACtB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;;IAEI;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;QAChB,yBAAyB;IAC7B;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,cAAc;IAClB;AACJ","sourcesContent":[".albumsContainer {\n    width: 256px;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    margin-top: 64px;\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    z-index: 1000; \n    border-right: 1px solid #ddd;\n    background-color: #fff;\n}\n\n.albumsList {\n    position: -webkit-sticky;\n    position: sticky;\n    top: 0;\n    border-bottom: 1px solid #ddd;\n}\n\n.albumsControls {\n    border-top: 1px solid #f9f9f9;\n    margin-top: auto;\n}\n\n.imagesContainer {\n    flex: 1;\n    box-sizing: border-box;\n    margin: 0;\n    margin-left: 256px;\n}\n\n.imagesList {\n    margin: 0 !important;\n    padding: 20px 10px;\n}\n\n@media (max-width: 768px) {\n    \n    .albumsContainer {\n        position: static;\n    }\n\n    .albumsList, .albumsControls {\n        position: static;\n        background-color: inherit;\n    }\n\n    .albumsContainer, .imagesContainer {\n        width: 100%;\n        height: auto;\n        margin-left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"albumsContainer": `Home_albumsContainer__Hjmbj`,
	"albumsList": `Home_albumsList__S2C13`,
	"albumsControls": `Home_albumsControls__hWeku`,
	"imagesContainer": `Home_imagesContainer__aVtV6`,
	"imagesList": `Home_imagesList__qimrX`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contract_contract_form__NXZYa {
  margin-top: 5px;
}

.Contract_form_item__5iTl2 {
  margin-bottom: 8px;
}

.Contract_select__waoKo {
  width: 100%;
}

.Contract_select_slider__IY5rN {
  width: 100%;
}

.Contract_input__1pwxI, .Contract_datepicker__8REz\\+ {
  width: 100%;
}

.Contract_input_slider__dkXt-, .Contract_datepicker_slider__JRs5q {
  width: 100%;
}

.Contract_text_wrapper__laNLq {
  margin-top: 5px;
}

.Contract_text__IV97a {
  margin-bottom: 0;
}

.Contract_text_light__TMUSB {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
}

.Contract_text_normal__RwYwG {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
}

.Contract_title__Xr\\+yt {
  margin-bottom: 4px;
} `, "",{"version":3,"sources":["webpack://./src/components/Contract/Contract.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".contract_form {\n  margin-top: 5px;\n}\n\n.form_item {\n  margin-bottom: 8px;\n}\n\n.select {\n  width: 100%;\n}\n\n.select_slider {\n  width: 100%;\n}\n\n.input, .datepicker {\n  width: 100%;\n}\n\n.input_slider, .datepicker_slider {\n  width: 100%;\n}\n\n.text_wrapper {\n  margin-top: 5px;\n}\n\n.text {\n  margin-bottom: 0;\n}\n\n.text_light {\n  font-size: 14px;\n  font-weight: normal;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.text_normal {\n  font-size: 14px;\n  font-weight: normal;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.title {\n  margin-bottom: 4px;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contract_form": `Contract_contract_form__NXZYa`,
	"form_item": `Contract_form_item__5iTl2`,
	"select": `Contract_select__waoKo`,
	"select_slider": `Contract_select_slider__IY5rN`,
	"input": `Contract_input__1pwxI`,
	"datepicker": `Contract_datepicker__8REz+`,
	"input_slider": `Contract_input_slider__dkXt-`,
	"datepicker_slider": `Contract_datepicker_slider__JRs5q`,
	"text_wrapper": `Contract_text_wrapper__laNLq`,
	"text": `Contract_text__IV97a`,
	"text_light": `Contract_text_light__TMUSB`,
	"text_normal": `Contract_text_normal__RwYwG`,
	"title": `Contract_title__Xr+yt`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { Select, Tag } from 'antd';
import { 
  QuestionCircleOutlined, 
  StopOutlined, 
  ExclamationCircleOutlined, 
  CheckSquareOutlined, 
  ClockCircleOutlined
} from '@ant-design/icons';
import cn from 'classnames';
import s from './License.module.css'; 

const LICENSE_STATUSES = {
  NO_LICENSE: 'no_license',
  EXPIRED: 'expired',
  EXPIRING_SOON: 'expiring_soon',
  LIMITED_ACCESS: 'limited_access',
  FREE_ACCESS: 'free_access'
};

const LICENSE_CONFIG = {
  [LICENSE_STATUSES.NO_LICENSE]: {
    icon: <QuestionCircleOutlined />,
    text: 'Без лицензии',
    color: '#ccc'
  },
  [LICENSE_STATUSES.EXPIRED]: {
    icon: <StopOutlined style={{ color: '#ee2250' }} />,
    text: 'Лицензия истекла',
    color: '#ee2250'
  },
  [LICENSE_STATUSES.EXPIRING_SOON]: {
    icon: <ClockCircleOutlined style={{ color: '#dcb400' }} />,
    text: 'Лицензия истекает',
    color: '#dcb400'
  },
  [LICENSE_STATUSES.LIMITED_ACCESS]: {
    icon: <ExclamationCircleOutlined style={{ color: '#dcb400' }} />,
    text: 'Ограниченный доступ',
    color: '#dcb400'
  },
  [LICENSE_STATUSES.FREE_ACCESS]: {
    icon: <CheckSquareOutlined style={{ color: '#66cc66' }} />,
    text: 'Свободный доступ',
    color: '#66cc66'
  }
};

const License = ({ 
  licenseStatus, 
  editable = false, 
  title = false, 
  isCard = false, 
  isSlider = false, 
  onChange,
  timeLeft,
  contractDateTo,
  showTitle = true
}) => {
  const [selectedLicenseStatus, setSelectedLicenseStatus] = useState(licenseStatus || '');

  const licenseStatusList = Object.entries(LICENSE_CONFIG).map(([value, config]) => ({
    value,
    label: (
      <span>
        {config.icon}
        {` ${config.text}`}
      </span>
    ),
    status: (
      <span>
        {`${config.text} `}
        {config.icon}
      </span>
    ),
    color: config.color
  }));

  const handleLicenseStatusChange = (value) => {
    setSelectedLicenseStatus(value || '');
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    setSelectedLicenseStatus(licenseStatus || '');
  }, [licenseStatus]);

  const selectedStatus = licenseStatusList.find(item => item.value === selectedLicenseStatus);

  const formatContractDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const renderLicenseInfo = () => {
    if (!timeLeft && !contractDateTo) return null;
    
    return (
      <div className={s.license_info}>
        {timeLeft && (
          <div className={s.time_left}>
            {timeLeft}
          </div>
        )}
        {contractDateTo && (
          <div className={s.contract_date}>
            Срок действия до: {formatContractDate(contractDateTo)}
          </div>
        )}
      </div>
    );
  };

  const renderLicenseStatus = () => {
    if (isCard) {
      return (
        <div className={s.text_wrapper}>
          <span className={s.text}>
            {selectedStatus ? (
              <div>
                {LICENSE_CONFIG[selectedStatus.value].text} {React.cloneElement(LICENSE_CONFIG[selectedStatus.value].icon)}
              </div>
            ) : (
              <div className={s.text_light}>Статус не указан</div>
            )}
          </span>
          {renderLicenseInfo()}
        </div>
      );
    }
    
    return (
      <div>
        {selectedStatus ? (
          <Tag color={selectedStatus.color} icon={React.cloneElement(LICENSE_CONFIG[selectedStatus.value].icon, { style: { color: 'white' } })}>
            {LICENSE_CONFIG[selectedStatus.value].text}
          </Tag>
        ) : (
          <div>Статус не указан</div>
        )}
        {renderLicenseInfo()}
      </div>
    );
  };

  return (
    <div className={s.license_container}>
      {title && showTitle && (
        <div className={s.license_title}>
          <b>Статус лицензии:</b>
        </div>
      )}
      {editable ? (
        <Select
          size={isSlider ? 'small' : 'large'}
          placeholder='Статус лицензии'
          value={selectedLicenseStatus}
          className={cn(s.select, {[s.select_slider]: isSlider})}
          onChange={handleLicenseStatusChange}
          options={isSlider ? licenseStatusList : [
            {
              value: '',
              label: 'Не выбрано',
            },
            ...licenseStatusList
          ]}
        />
      ) : renderLicenseStatus()}
    </div>
  );
};

export default License;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Col, Button, Modal } from 'antd';
import { CloudUploadOutlined, CheckCircleOutlined, SyncOutlined, ReloadOutlined } from '@ant-design/icons';

import {
  ImagesContainer,
} from '../../components/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';
import { apiFTPInfo, apiFTPCheck } from '../../shared/api';

import s from '../../forms/EditAlbumIdImageForm/EditAlbumIdImageForm.module.css';
import styleUsers from '../../pages/Users/Users.module.css'

const Console = () => {

  const navigate = useNavigate();

  const { state, dispatch } = useContext(providerContext);
  const { getAlbums, getImages, setImagesParams  } = getMethods(dispatch);

  const [FTPInfo, setFTPInfo] = useState(false);
  const [ftpInfoModal, setFtpInfoModal] = useState(false);
  const [updateCountdown, setUpdateCountdown] = useState(10);

  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingAlbums, setLoadingAlbumbs] = useState(true);

  useEffect(() => {

    if (state.whoam?.role < 2) {
      navigate('/');
    }

  }, [state.whoam?.role]);

  useEffect(() => {
    getFtpInfoFromAPI();
  }, []);

  useEffect(() => {
    refresh(true);
  }, [ state.imagesParamsConsole ]); 

  useEffect(() => {
    if(state.albums != null) {
      setLoadingAlbumbs(false);
    }
  }, [state.albums]);

  useEffect(() => {
    if(state.images != null) {
      setLoadingImages(false);
    }
  }, [state.images]);

  // Refresh button

  const refresh = (showLoading = true) => {
    try {
      setLoadingImages(showLoading);
      getAlbums();
      getImages(state.imagesParamsConsole);
      getFtpInfoFromAPI();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Pagination

  const handleSearchFormChange = (newForm, refresh = false) => {
    if (refresh || JSON.stringify(state.imagesParamsConsole) !== JSON.stringify({ ...state.imagesParamsConsole, ...newForm })) {
      setImagesParams({ page: (refresh ? state.imagesParamsConsole.page : 1), ...newForm }, true);
      window.scrollTo(0, 0);
    }
  };

  // CheckFTP (start)
  
  const checkFtp = async () => {
    try {
      const FTPInfoResponse = await apiFTPCheck();
      setFTPInfo(FTPInfoResponse);
      setFtpInfoModal(true);
    } catch (error) {
      console.error('Error fetching FTP info:', error.message);
    }
  }

  // Refreshing   

  useEffect(() => {
    let intervalId;

    const startInterval = () => {
      intervalId = setInterval(() => {
        setUpdateCountdown(prevCountdown => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    };

    startInterval();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (ftpInfoModal) {
      if (updateCountdown === 0) {
        refresh(false);
        setUpdateCountdown(10);
      }
    } else {
      setUpdateCountdown(10);
    }
  }, [updateCountdown, ftpInfoModal]);

  const getFtpInfoFromAPI = async () => {
    try {
      const FTPInfoResponse = await apiFTPInfo();
      setFTPInfo(FTPInfoResponse);
    } catch (error) {
      console.error('Error fetching FTP info:', error.message);
    }
  };

  // Report download

  const downloadJSON = (data) => {
    const jsonData = JSON.stringify(data, null, 2);

    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'ftp_scanning_report.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div className={styleUsers.user_header} style={{ gap: '20px' }}>
        <div>
          <Row style={{ gap: '20px' }}>
            <Col className={s.user_btn}>
              <Button icon={<ReloadOutlined />} style={{ color: '#29355c' }} size='large' type="default" block onClick={refresh} />
            </Col>
            <Col className={styleUsers.user_total} style={{ alignItems: 'center' }}>
              <Row style={{ gap: '20px' }}>
                <Col className={styleUsers.user_total}>
                  <b>Изображений:</b>
                  {state?.imagesMeta?.total !== undefined
                    ?
                    (state?.imagesMeta?.total)
                    :
                    <SyncOutlined spin />
                  }
                </Col>
                <Col className={styleUsers.user_total}>
                  <b>Альбомов:</b>
                  {state?.albums?.length !== undefined
                    ?
                    (state?.albums?.length)
                    :
                    <SyncOutlined spin />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Row style={{ gap: '20px' }}>
            <Col className={styleUsers.user_btn}>
              <Button
                size="large"
                type="primary"
                icon={<CloudUploadOutlined/>}
                onClick={checkFtp}
                disabled={(FTPInfo === false || !FTPInfo?.finished)}
              >
                Проверить FTP
              </Button>
            </Col>
            { FTPInfo?.scaned_ago &&
            <Col className={styleUsers.user_total} style={{ alignItems: 'center' }}>
              <div>
                <a onClick={() => {setFtpInfoModal(!ftpInfoModal)}} style={{ color: '#999', textDecoration: 'underline' }}>
                  {FTPInfo?.finished ?
                    <>
                      <CheckCircleOutlined style={{ color: '#66cc66', marginRight: '10px' }} />
                      {FTPInfo?.scaned_ago}
                    </>
                    :
                    <>
                      <SyncOutlined style={{ color: '#dcb400', marginRight: '10px' }} spin />
                      {FTPInfo?.scaned_ago}
                    </>
                  }
                </a>
              </div>
            </Col>
            }
          </Row>
        </div>
      </div>

      <ImagesContainer images={state.images} meta={state.imagesMeta} albums={state.albums} handleSearchFormChange={handleSearchFormChange} loadingImages={loadingImages} loadingAlbums={loadingAlbums} isLoaded={!loadingImages && !loadingAlbums} logsView />  
                                                                                                    
      <Modal
        centered
        footer={null}
        onCancel={() => setFtpInfoModal(!ftpInfoModal)}
        open={ftpInfoModal}
      >
        {FTPInfo ? <div style={{ padding: '40px' }}>

          {/* <div style={{ borderBottom: '1px solid #eee', padding: '5px 0px', marginBottom: '20px' }}>
            <b>Последняя проверка была:</b> {FTPInfo?.date ?? '-'}
          </div> */}

          <div>
            <div style={{ borderBottom: '1px solid #eee', display: 'flex', fontSize: '18px', padding: '5px 0px' }}>
              Сканирование FTP{ FTPInfo?.scan_in_process === false ? <CheckCircleOutlined style={{ color: '#66cc66', marginLeft: '5px' }} /> : <SyncOutlined style={{ color: '#dcb400', marginLeft: '5px' }} spin />}
            </div>
            { FTPInfo?.scan_in_process === false ? 
            <div style={{ padding: '20px 0'}}>
              <Button type="default" onClick={() => {downloadJSON(FTPInfo?.scan_result)}}>Скачать отчет</Button>
            </div>
            :
            <div style={{ padding: '20px 0', color: '#999'}}>Сканирование директорий в процессе...</div>
            }
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: 'calc(50% - 5px)', padding: '5px 0px' }}>
              <div style={{ borderBottom: '1px solid #eee', display: 'flex', fontSize: '18px', padding: '5px 0px' }}>
                Папки (альбомы) { FTPInfo?.scan_in_process === false && FTPInfo?.albums_in_process === 0 ? <CheckCircleOutlined style={{ color: '#66cc66', marginLeft: '5px' }} /> : <SyncOutlined style={{ color: '#dcb400', marginLeft: '5px' }} spin />}
              </div>
              <p><b>Обнаружено папок:</b> {FTPInfo?.albums_total ?? '-'} </p>
              <p><b>Дубли:</b> {FTPInfo?.albums_doubled ?? '-'} </p>
              <p><b>Создано:</b> {FTPInfo?.albums_created ?? '-'} </p>
              <p><b>В процессе:</b> {FTPInfo?.albums_in_process ?? '-'}</p>
            </div>
            <div style={{ width: 'calc(50% - 5px)', padding: '5px 0px' }}>
              <div style={{ borderBottom: '1px solid #eee', display: 'flex', fontSize: '18px', padding: '5px 0px' }}>
                Файлы (изображения) { FTPInfo?.scan_in_process === false && FTPInfo?.images_in_process === 0 ? <CheckCircleOutlined style={{ color: '#66cc66', marginLeft: '5px' }} /> : <SyncOutlined style={{ color: '#dcb400', marginLeft: '5px' }} spin />}
              </div>
              <p><b>Прогресс:</b> {FTPInfo?.images_in_process && FTPInfo?.images_total ? `${100 -Math.ceil((FTPInfo.images_in_process / FTPInfo.images_total) * 100)}%` : '-'} </p>
              <p><b>Обнаружено файлов:</b> {FTPInfo?.images_total ?? '-'} </p>
              <p><b>Дубли:</b> {FTPInfo?.images_doubled ?? '-'} </p>
              <p><b>НЕ изображения:</b> {FTPInfo?.images_incorrect ?? '-'} </p>
              <p><b>Создано:</b> {FTPInfo?.images_created ?? '-'} </p>
              <p><b>В процессе:</b> {FTPInfo?.images_in_process ?? '-'}</p>
            </div>
          </div>
          
        </div> : <div style={{ padding: '40px' }}>
          Проверка не производилась  
        </div>}
        
        {FTPInfo?.finished ?
          <div key={1} className={s.buttons_wrapper} style={{ color: '#999' }}>
            Проверено {FTPInfo ? FTPInfo.date : "..."}
          </div>
          :
          <div key={1} className={s.buttons_wrapper} style={{ color: '#999' }}>
            Информация обновится автоматически через: {updateCountdown} сек.
          </div>
        }
      </Modal>

    </div>
  );
};

export default Console;

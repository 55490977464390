import React, { useState, useEffect, useRef } from 'react';
import { Image as AntImage } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

/**
 * Компонент для загрузки изображений
 * @param {string} previewUrl - URL для загрузки изображения с сервера
 * @param {string} mimeType - MIME-тип изображения
 * @param {string} alt - Альтернативный текст
 * @param {Object} style - Стили для изображения
 * @param {string} className - CSS-классы для изображения
 * @param {boolean} preview - Включить предпросмотр по клику
 * @param {function} onLoadComplete - Колбэк после загрузки изображения
 */
const LazyImage = ({ 
  previewUrl, 
  mimeType,
  alt = '',
  style = {},
  className = '',
  preview = false,
  onLoadComplete = () => {}
}) => {
  // Состояние загрузки изображения
  const [isLoading, setIsLoading] = useState(true);
  // URL изображения
  const [imageUrl, setImageUrl] = useState('');
  // Отслеживаем монтирование компонента
  const isMounted = useRef(true);
  // Флаг для отслеживания, была ли начата загрузка
  const hasStartedLoading = useRef(false);

  // Эффект для отслеживания размонтирования компонента
  useEffect(() => {
    return () => {
      isMounted.current = false;
      // Освобождаем URL объекта при размонтировании
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  // Эффект для загрузки изображения сразу при монтировании компонента
  useEffect(() => {
    // Если нет URL или уже начали загрузку, выходим
    if (!previewUrl || !mimeType || hasStartedLoading.current) {
      if (!previewUrl || !mimeType) {
        setIsLoading(false);
      }
      return;
    }

    // Отмечаем, что загрузка началась
    hasStartedLoading.current = true;
    
    // Формируем URL для прокси
    const directImageUrl = `/yandex-proxy/?url=${encodeURIComponent(previewUrl)}`;
    
    // Устанавливаем URL напрямую
    setImageUrl(directImageUrl);
    
    // Создаем стандартный объект Image для предзагрузки
    // Используем глобальный конструктор Image из window
    const imgElement = document.createElement('img');
    
    imgElement.onload = () => {
      if (isMounted.current) {
        setIsLoading(false);
        onLoadComplete();
      }
    };
    
    imgElement.onerror = () => {
      if (isMounted.current) {
        // Даже при ошибке скрываем индикатор загрузки
        setIsLoading(false);
        onLoadComplete();
      }
    };
    
    // Начинаем загрузку
    imgElement.src = directImageUrl;
    
    // Добавляем таймаут для гарантированного скрытия индикатора загрузки
    const loadingTimeout = setTimeout(() => {
      if (isMounted.current && isLoading) {
        setIsLoading(false);
        onLoadComplete();
      }
    }, 3000); // 3 секунды максимального ожидания
    
    return () => {
      clearTimeout(loadingTimeout);
      imgElement.src = '';
    };
  }, [previewUrl, mimeType, onLoadComplete, isLoading]);

  return (
    <div 
      className={`image-container ${className}`}
      style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '120px' }}
    >
      {isLoading && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 2 }}>
          <SyncOutlined style={{ fontSize: 24, color: '#ccc' }} spin />
        </div>
      )}
      {imageUrl && (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img 
            src={imageUrl} 
            alt={alt} 
            style={{ 
              maxWidth: '100%', 
              maxHeight: '150px', 
              objectFit: 'contain',
              ...style 
            }} 
            className={className}
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
          />
          
          {/* Если нужен предпросмотр, добавляем скрытый компонент Image */}
          {preview && (
            <div style={{ display: 'none' }}>
              <AntImage 
                src={imageUrl} 
                preview={{ visible: false }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LazyImage; 
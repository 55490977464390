import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Select, DatePicker, Button, Typography } from 'antd';
import { CloseCircleOutlined, MinusCircleOutlined, ReloadOutlined, UploadOutlined, FolderOutlined, TagOutlined } from '@ant-design/icons';

import { License } from '../../components/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

import s from './FilterImagesForm.module.css';

const FilterImagesForm = ({ albums = [], activeAlbumId = null, handleSearchFormChange, handleActiveAlbumChange, isEditableImage, tagToAdd }) => {
  const { Option } = Select;
  const { Text } = Typography;

  const { state, dispatch } = useContext(providerContext);
  const { discoverTags } = getMethods(dispatch);

  const [searchOptions, setSearchOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const activeAlbum = activeAlbumId ? state.albums.find(album => album.id === activeAlbumId) : null;
  const searchPlaceholder = activeAlbum ? `Поиск в альбоме "${activeAlbum.title}"` : 'Глобальный поиск';

  const handleSearchTags = (value) => {
    setSearchQuery(value);
    if (value.length > 0) {
      discoverTags(value);
    } else {
      setSearchOptions([]);
    }
  };

  const [tags, setTags] = useState(state.imagesParams.tags);
  const [dateRange, setDateRange] = useState(state.imagesParams.dateRange);
  const [orientation, setOrientation] = useState(state.imagesParams.orientation);
  const [licenseStatus, setLicenseStatus] = useState(state.imagesParams.license_status);
  const [sortBy, setSortBy] = useState('total');

  function getPathForAlbum(album, albumsMap) {
    let path = [];
    let currentAlbum = album;
    
    while (currentAlbum && currentAlbum.parent_id !== 0 && currentAlbum.parent_id !== null) {
        currentAlbum = albumsMap.find(item => item.id === currentAlbum.parent_id);
        if (currentAlbum) {
          path.unshift(currentAlbum.title);
        }
    }

    path.unshift('.');
    
    return path.join(' / '); 
  }

  useEffect(() => {
    const filteredTags = state.discoverTags.filter((tag) => !tags.includes(tag.value));
    if(searchOptions !== filteredTags) {

      let albums = [];

      if (searchQuery.length > 1) {
        albums = state.albums
          .filter(album => 
            album.title.toLowerCase().includes(searchQuery.toLowerCase()) && 
            album.id !== state.imagesParams.album_id &&
            !state.albums.some(childAlbum => childAlbum.parent_id === album.id)
          ) 
          .slice(0, 3) 
          .map(album => ({
            type: "album",
            album_id: album.id,
            key: "album:" + album.id,
            value: album.title,
            path: getPathForAlbum(album, state.albums),
            last: false,
          }))
          .map((album, index, array) => {
            if (index === array.length - 1) {
              return { ...album, last: true };
            }
            return album;
          });
      }
      
      setSearchOptions([...albums, ...filteredTags]);
    }
  }, [state.discoverTags])

  useEffect(() => {
    if (tagToAdd) {
      setDateRange([]);
      setOrientation("");
      setLicenseStatus(null);
      setTags([tagToAdd]);
    }
  }, [tagToAdd]);

  const handleSubmit = (refresh = false) => {
    const formData = {
      tags,
      dateRange: dateRange,
      date_from: dateRange && dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null,
      date_to: dateRange && dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null,
      orientation,
      license_status: licenseStatus,
      sort_by: sortBy,
    };

    handleSearchFormChange(formData, refresh);
  };

  useEffect(() => {
    handleSubmit();
  }, [tags, dateRange, orientation, licenseStatus, sortBy]);

  const handleChangeTags = (value) => {
    const option = searchOptions.find(option => option.type == "album" && value.includes(option.key)) ?? null;
    if (option !== null) {
      handleActiveAlbumChange(option.album_id);
      return;
    }
    if (JSON.stringify(tags) !== JSON.stringify(value)) {
      setTags(value);
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dateRange != dates) {
      setDateRange(dates);
    }
  };

  const handleOrientationChange = (value) => {
    if (orientation != value) {
      setOrientation(value);
    }
  };

  const handleLicenseStatusChange = (value) => {
    if (licenseStatus != value) {
      setLicenseStatus(value);
    }
  };

  const handleSortChange = (value) => {
    if (sortBy !== value) {
      setSortBy(value);
    }
  };

  const handleReset = () => {
    setTags([]);
    setDateRange([]);
    setOrientation("");
    setLicenseStatus(null);
    setSortBy('total');
  };

  const isFormEmpty = tags.length === 0 && !dateRange?.length && !orientation && !licenseStatus && sortBy === 'total';

  return (
    <div className={s.filter_wrapper}>
      <div className={s.form_container}>
        <div className={s.form_fields}>
          <div className={s.field_group}>
            <Text className={s.field_label}>{searchPlaceholder}</Text>
            <Select
              mode="multiple"
              className={s.w_full}
              size='large'
              filterOption={false}
              placeholder="Теги"
              onSearch={handleSearchTags}
              onChange={handleChangeTags}
              onSelect={() => {
                setSearchOptions([]);
                setSearchQuery('');
              }}
              onBlur={() => {
                setSearchOptions([]);
                setSearchQuery('');
              }}
              notFoundContent={
                <div className={s.filter_select}>
                  {searchQuery ?
                    (
                      <span className={s.grey}>
                        <MinusCircleOutlined /> Теги и альбомы не найдены
                      </span>
                    )
                    :
                    (
                      <span className={s.blue}>
                        &uarr; Введите запрос &uarr;
                      </span>
                    )}
                </div>
              }
              value={tags}
              maxTagCount='responsive'
            >
              {searchOptions.map(option => (
                  <Option key={option.key} value={option.type === "album" ? option.key : option.value} className={`${s.option} ${option.type === "album" && option.last && s.option_album_last}`} >
                      {option.type === "album"
                        ? <div className={s.option_album_item}>
                            <div className={s.option_item_title}>
                              <FolderOutlined className={s.option_icon} /> <b>{option.value}</b>
                            </div> <span className={s.option_album_item_hint}>{option.path}</span>
                          </div>
                        : 
                          <div className={s.option_item_title}>
                            <TagOutlined className={s.option_icon} /> {option.value}
                          </div>
                        }
                  </Option>
              ))}
            </Select>
          </div>
          
          <div className={s.field_group}>
            <Text className={s.field_label}>Дата съёмки</Text>
            <DatePicker.RangePicker
              size='large'
              className={s.w_full}
              separator={null}
              placeholder={['Дата', '']}
              format='DD-MM-YY'
              onChange={handleDateRangeChange}
              value={dateRange}
            />
          </div>
          
          <div className={s.field_group}>
            <Text className={s.field_label}>Ориентация</Text>
            <Select
              placeholder='Ориентация'
              className={s.w_full}
              size='large'
              onChange={handleOrientationChange}
              value={orientation}
              options={[
                {
                  value: "",
                  label: 'Не выбрано',
                },
                {
                  value: 'horizontal',
                  label: 'Горизонтальная',
                },
                {
                  value: 'vertical',
                  label: 'Вертикальная',
                }
              ]}
            />
          </div>
          
          <div className={s.field_group}>
            <Text className={s.field_label}>Лицензия</Text>
            <License 
              editable 
              onChange={handleLicenseStatusChange} 
              licenseStatus={licenseStatus} 
            />
          </div>
          
          <div className={s.field_group}>
            <Text className={s.field_label}>Сортировка</Text>
            <Select
              placeholder='Выберите сортировку'
              className={s.w_full}
              size='large'
              onChange={handleSortChange}
              value={sortBy}
              options={[
                {
                  value: 'total',
                  label: 'Cкачивания + лайки',
                },
                {
                  value: 'likes',
                  label: 'По лайкам',
                },
                {
                  value: 'downloads',
                  label: 'По скачиваниям',
                },
                {
                  value: 'default',
                  label: 'По порядку',
                }
              ]}
            />
          </div>
        </div>
        
        <div className={s.buttons_container}>
          <Button 
            icon={<CloseCircleOutlined />} 
            className={s.reset_button}
            size='large' 
            type="default" 
            onClick={handleReset}
            disabled={isFormEmpty}
          />
          
          <Button 
            icon={<ReloadOutlined />} 
            className={s.refresh_button} 
            size='large' 
            type="default" 
            onClick={() => handleSubmit(true)} 
          />
          
          {isEditableImage && 
            <Link to='/images/yandex'>
              <Button icon={<UploadOutlined />} className={s.upload_button} size='large' type="primary" />
            </Link>
          }
        </div>
      </div>
    </div>
  );
};

export default FilterImagesForm;

const storedToken = localStorage.getItem('token');

export const initialState = {

  // AUTHENTICATION

  isLoggedIn: !!storedToken,
  token: storedToken,
  whoam: null,

  // IMAGES
  
  images: null,
  imagesMeta: [],

  discoverTags: [],
  imagesParams: { page: 1, album_id: null, tags: [], dateRange: [], date_from: null, date_to: null, orientation: "", license: "" },
  imagesParamsConsole: { page: 1, openai_status: null, thumbnails_status: null, album_tags_status: null, tags_status: null, dateRange: [], date_from: null, date_to: null, orientation: "", license: "" },

  // ALBUMS

  albums: null,

  // USERS

  users: null,
  usersMeta: [],

  // YANDEX UPLOAD

  yandexUpload: {
    status: null,
    token_expires_at: null,
    structure: null,
    progress: null,
    error_message: null,
    oauth_url: null,
  },

}

import React from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  LeftOutlined,
  PartitionOutlined,
  RightOutlined,
} from '@ant-design/icons';

import cn from 'classnames';
import s from '../../components/AlbumsContainer/AlbumsContainer.module.css';
import style from '../../pages/Home/Home.module.css'

const AlbumsList = ({ albums, activeAlbumId, onClick, onDelete, onEdit, editable = true, isModal = false }) => {

  const location = useLocation();
  const currentPath = location.pathname;

  const getDisplayedAlbum = () => {
    if (activeAlbumId === null) {
      return null;
    }

    const activeAlbum = albums.find((album) => album.id === activeAlbumId);

    if (!activeAlbum) {
      return null;
    }

    // Has children
    if (albums.some((album) => album.parent_id === activeAlbum.id)) {
      return activeAlbum;
    }

    // Doesn't have children
    return albums.find((album) => album.id === activeAlbum.parent_id) || null;
  };

  const displayedAlbum = getDisplayedAlbum();
  const displayedAlbumParentId = displayedAlbum?.id || null;
  const displayedAlbums = albums.filter((album) => album.parent_id === (displayedAlbum?.id || null));

  return (
    <div>
      <Row className={style.albumsList}>
        { displayedAlbumParentId === null && (
          <Col className={cn(s.text_wrapper, {[s.text_modal]: !isModal })}>
            <h4 className={s.album_title}>
              {isModal ? 'Корневой альбом' : 'Все альбомы'}
            </h4>
            {isModal && activeAlbumId === null && (
              <CheckCircleOutlined className={cn(s.title_icon, s.left10)}/>
            )}
            {!isModal && activeAlbumId === null && (
              currentPath === '/upload'
                ?
                <CheckCircleOutlined className={cn(s.title_icon, s.left20)}/>
                :
                <EyeOutlined className={cn(s.title_icon, s.left20)}/>
            )}
          </Col>
        )}
        { displayedAlbumParentId != null && (
          <Col className={s.title_col}>
            <Button
              type='link'
              onClick={() => {
                if (displayedAlbumParentId !== null) {
                  onClick(albums?.find((album) => album.id === displayedAlbum.parent_id) || null);
                }
              }}
              className={cn(s.title_button, {[s.title_modal]: isModal })}
            >
              <div className={s.title_text}>
                <LeftOutlined className={s.title_left} />
                <h4>{ displayedAlbum?.title }</h4>
              </div>
              {isModal && activeAlbumId === displayedAlbum?.id && (
                <CheckCircleOutlined className={cn(s.title_icon)}/>
              )}
              {!isModal && activeAlbumId === displayedAlbum?.id && (
                currentPath === '/upload'
                  ?
                  <CheckCircleOutlined className={s.title_icon}/>
                  :
                  <EyeOutlined className={s.title_icon}/>
              )}
            </Button>
          </Col>
        )}
      </Row>
      <div className={cn(s.items_wrapper, {[s.items_modal]: isModal})}>
        <ul className={s.list_wrapper}>
          {displayedAlbums.map((album) => (
            <li key={album.id}>
              <Button
                type='text'
                onClick={() => !editable && onClick(album)}
                className={cn(s.list, {[s.list_selected]: activeAlbumId === album.id, [s.list_manage]: editable})}
              >
                {isModal && activeAlbumId !== album.id && !albums.some((child) => child.parent_id === album.id) &&  (
                  <EllipsisOutlined className={s.title_icon_light}/>
                )}
                {isModal && activeAlbumId !== album.id && albums.some((child) => child.parent_id === album.id) &&  (
                  <PartitionOutlined className={s.title_icon_light}/>
                )}
                {isModal && activeAlbumId === album.id && (
                  <CheckCircleOutlined className={s.title_icon} />
                )}
                <span className={s.list_item}>
                  {album.title}
                </span>
                {
                  editable &&
                  <div className={s.list_buttons}>
                    <Button
                      onClick={() => onEdit(album)}
                      icon={<EditOutlined />}
                      key="list-loadmore-edit"
                      size='small'
                      shape="circle"
                    />
                    <Button
                      onClick={() => onDelete(album)}
                      icon={<DeleteOutlined />}
                      danger
                      key="list-loadmore-more"
                      size='small'
                      shape='circle'
                    />
                  </div>
                }
                {!editable && albums.some((child) => child.parent_id === album.id) && (
                  <RightOutlined className={cn(s.title_icon, s.left_auto)}/>
                )}
                {!editable && !isModal && activeAlbumId === album.id && (
                  currentPath === '/upload'
                    ?
                    <CheckCircleOutlined className={cn(s.title_icon, s.left_auto)}/>
                    :
                    <EyeOutlined className={cn(s.title_icon, s.left_auto)}/>
                )}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AlbumsList;

import CreateAlbumForm from './CreateAlbumForm/CreateAlbumForm';
import EditAlbumForm from './EditAlbumForm/EditAlbumForm';
import DeleteAlbumForm from './DeleteAlbumForm/DeleteAlbumForm';
import CreateUserForm from './CreateUserForm/CreateUserForm';
import EditUserForm from './EditUserForm/EditUserForm';
import DeleteUserForm from './DeleteUserForm/DeleteUserForm';
import UploadImagesForm from './UploadImagesForm/UploadImagesForm';
import FilterImagesForm from './FilterImagesForm/FilterImagesForm';
import EditAlbumIdImageForm from './EditAlbumIdImageForm/EditAlbumIdImageForm';
import EditTagsLicenseImageForm from './EditTagsLicenseImageForm/EditTagsLicenseImageForm';
import DeleteImageForm from './DeleteImageForm/DeleteImageForm';

export {
  CreateAlbumForm,
  EditAlbumForm,
  DeleteAlbumForm,

  CreateUserForm,
  EditUserForm,
  DeleteUserForm,

  UploadImagesForm,
  FilterImagesForm,

  EditAlbumIdImageForm,
  EditTagsLicenseImageForm,
  DeleteImageForm,
};

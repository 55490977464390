import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

import cn from 'classnames';
import s from './Name.module.css';

const Name = ({ name, originalName, editable, title, isSlider, onChange }) => {
  const [selectedName, setSelectedName] = useState(name || '');

  const handleNameChange = (e) => {
    const value = e.target.value;
    setSelectedName(value);
    onChange(value);
  };

  useEffect(() => {
    setSelectedName(name || '');
  }, [name]);

  const displayName = selectedName || originalName || 'Без имени';

  return (
    <div>
      {title && (
        <div>
          <b>Имя изображения:</b>
        </div>
      )}
      {editable ? (
        <Input
          size={isSlider ? 'small' : 'large'}
          placeholder="Введите имя изображения"
          value={selectedName}
          className={cn(s.input, { [s.input_slider]: isSlider })}
          onChange={handleNameChange}
        />
      ) : (
        <div className={s.text_wrapper}>
          <div className={s.text_normal}>
            {displayName}
          </div>
        </div>
      )}
    </div>
  );
};

export default Name; 
import React, { useEffect, useContext, useState } from 'react';

import { Table, Tag, Space, Button, message, Popconfirm } from 'antd';

import { SyncOutlined, PictureOutlined, TagOutlined, TagsOutlined } from '@ant-design/icons';

import ImagesLogsSkeleton from './ImagesLogsSkeleton.jsx';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

const ImagesLogs = ({ images, albums, meta, handleSearchFormChange, handlePageChange, setAlbumSlider, setSelectedImage, loadingImages, loadingAlbums }) => {

    const { dispatch } = useContext(providerContext);
    const { rethumbnailingImages, retaggingImages, albumRetaggingImages } = getMethods(dispatch);
    const [messageApi, contextHolder] = message.useMessage();

    const [selectedIds, setSelectedIds] = useState([]);
    const [filteredOpenAIStatus, setFilteredOpenAIStatus] = useState(null);
    const [filteredThumbnailsStatus, setFilteredThumbnailsStatus] = useState(null);
    const [filteredAlbumTagsStatus, setFilteredAlbumTagsStatus] = useState(null);
    
    const columns =
    [
        {
            title: '', dataIndex: 'url_thumbnail', key: 'image', fixed: 'left', width: 90,
            customCell: (text) => ({
                style: {
                    backgroundColor: '#000',
                    height: '80px',
                },
            }),
            render: (text) => text ?
                <div style={{ width: '80px', height: '80px', borderRadius: '0',position: 'relative', overflow: 'hidden', flex: '1' }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            minWidth: '80px',
                            minHeight: '80px',
                            backgroundImage: `url(${text})`,
                            filter: 'blur(10px)',
                            backgroundSize: 'cover',
                            scale: '1.04',
                            backgroundPosition: 'center',
                        }}
                    />
                    <img
                        src={text}
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            zIndex: 1
                        }}
                    />
                </div>
                :
                <div style={{ color: '#ccc', fontSize: '16px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#eee', width: '80px', height: '80px', borderRadius: '0',position: 'relative', overflow: 'hidden' }}>
                    <SyncOutlined spin />
                </div>
        },
        {
            title: 'ID', dataIndex: 'id', key: 'id', width: 90,
        },
        {
            title: 'Время', dataIndex: 'created_at', key: 'type', width: 90,
        },
        {
            title: 'Тип', dataIndex: 'upload_type', key: 'type', width: 90,
            render: (text, record) => {
                switch (text) {
                    case 'manual':
                        return (<span>WEB</span>);
                    break;
                    case 'ftp':
                        return (<span>FTP</span>);
                    break;
                    case 'yandex':
                        return (<span>Яндекс</span>);
                    break;
                }
            },
        },
        {
            title: 'Кропы', dataIndex: 'thumbnails_status', key: 'thumbnails_status', width: 90,
            render: (text, record) => {
                let tagColor;
                let tagContent;
                let attemptsContent;
                let dateContent;

                switch (text) {
                    case 'success':
                        tagColor = 'green';
                        tagContent = 'Успешно';
                    break;
                    case 'in_queue':
                        tagColor = 'yellow';
                        tagContent = 'В очереди';
                    break;
                    case 'in_process':
                        tagColor = 'blue';
                        tagContent = 'В процессе';
                    break;
                    case 'error':
                        tagColor = 'red';
                        tagContent = 'Ошибка';
                    break;
                    default:
                        tagColor = 'default';
                        tagContent = 'Не запущено';
                }

                if (record.thumbnails_attempts !== undefined) {
                    attemptsContent = `Попытки: ${record.thumbnails_attempts}`;
                }

                if (record.thumbnails_status_at !== null) {
                    dateContent = `${record.thumbnails_status_at}`;
                }

                return (
                    <div>
                        <Tag color={tagColor}>{tagContent}</Tag>
                        {attemptsContent && <div style={{ color: '#999', fontSize: '12px' }}>{attemptsContent}</div>}
                        {dateContent && <div style={{ color: '#999', fontSize: '12px' }}>{dateContent}</div>}
                    </div>
                );
            },
            filters: [
                { text: 'Успешно', value: 'success' },
                { text: 'В процессе', value: 'in_process' },
                { text: 'В очереди', value: 'in_queue' },
                { text: 'Ошибка', value: 'error' },
                { text: 'Не запущено', value: 'undefined' },
            ],
            filterMultiple: false,
            defaultFilteredValue: filteredThumbnailsStatus,
        },
        {
            title: 'ИИ', dataIndex: 'openai_status', key: 'openai_status', width: 90,
            render: (text, record) => {
                let tagColor;
                let tagContent;
                let attemptsContent;
                let dateContent;

                switch (text) {
                    case 'success':
                        tagColor = 'green';
                        tagContent = 'Успешно';
                    break;
                    case 'cancelled':
                        tagColor = 'orange';
                        tagContent = 'Отменено';
                    break;
                    case 'in_queue':
                        tagColor = 'yellow';
                        tagContent = 'В очереди';
                    break;
                    case 'in_process':
                        tagColor = 'blue';
                        tagContent = 'В процессе';
                    break;
                    case 'error':
                        tagColor = 'red';
                        tagContent = 'Ошибка';
                    break;
                    default:
                        tagColor = 'default';
                        tagContent = 'Не запущено';
                }

                if (record.openai_attempts !== undefined) {
                    attemptsContent = `Попытки: ${record.openai_attempts}`;
                }

                if (record.openai_status_at !== null) {
                    dateContent = `${record.openai_status_at}`;
                }

                return (
                    <div>
                        <Tag color={tagColor}>{tagContent}</Tag>
                        {attemptsContent && <div style={{ color: '#999', fontSize: '12px' }}>{attemptsContent}</div>}
                        {dateContent && <div style={{ color: '#999', fontSize: '12px' }}>{dateContent}</div>}
                    </div>
                );
            },
            filters: [
                { text: 'Успешно', value: 'success' },
                { text: 'В процессе', value: 'in_process' },
                { text: 'В очереди', value: 'in_queue' },
                { text: 'Ошибка', value: 'error' },
                { text: 'Не запущено', value: 'undefined' },
            ],
            filterMultiple: false,
            defaultFilteredValue: filteredOpenAIStatus,
        },
        {
            title: 'А.Теги', dataIndex: 'album_tags_status', key: 'album_tags_status', width: 90,
            render: (text, record) => {
                let tagColor;
                let tagContent;
                let attemptsContent;
                let dateContent;

                switch (text) {
                    case 'success':
                        tagColor = 'green';
                        tagContent = 'Успешно';
                    break;
                    case 'in_queue':
                        tagColor = 'yellow';
                        tagContent = 'В очереди';
                    break;
                    case 'in_process':
                        tagColor = 'blue';
                        tagContent = 'В процессе';
                    break;
                    case 'error':
                        tagColor = 'red';
                        tagContent = 'Ошибка';
                    break;
                    default:
                        tagColor = 'default';
                        tagContent = 'Не запущено';
                }

                if (record.album_tags_attempts !== undefined) {
                    attemptsContent = `Попытки: ${record.album_tags_attempts}`;
                }

                if (record.album_tags_status_at !== null) {
                    dateContent = `${record.album_tags_status_at}`;
                }

                return (
                    <div>
                        <Tag color={tagColor}>{tagContent}</Tag>
                        {attemptsContent && <div style={{ color: '#999', fontSize: '12px' }}>{attemptsContent}</div>}
                        {dateContent && <div style={{ color: '#999', fontSize: '12px' }}>{dateContent}</div>}
                    </div>
                );
            },
            filters: [
                { text: 'Успешно', value: 'success' },
                { text: 'В процессе', value: 'in_process' },
                { text: 'В очереди', value: 'in_queue' },
                { text: 'Ошибка', value: 'error' },
                { text: 'Не запущено', value: 'undefined' },
            ],
            filterMultiple: false,
            defaultFilteredValue: filteredAlbumTagsStatus,
        },
        {
            title: 'Альбом', dataIndex: 'album_id', key: 'album', width: 256,
            render: (text) => text ?
                <div>{albums.find((album) => album.id == text)?.title || "Корневой альбом"}</div>
            :
                <div></div>
        },
        // {
        //     title: 'Теги', dataIndex: 'tags', key: 'tags',
        //     render: (text, record) => (
        //         <Tags imageTags={text} imageAlbumTags={record.album_tags} />
        //       ),
        // },
    ];
    
    const customLocale = {
        emptyText: 'Нет данных',
        filterReset: 'Сброс',
    };

    const rowClassName = (record, index) => {
        return 'pointer-cursor'; 
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (filteredOpenAIStatus != filters.openai_status || filteredThumbnailsStatus != filters.thumbnails_status || filteredAlbumTagsStatus != filters.album_tags_status) {

            setFilteredOpenAIStatus(filters.openai_status);
            setFilteredThumbnailsStatus(filters.thumbnails_status);
            setFilteredAlbumTagsStatus(filters.album_tags_status);

            const formData = {
                page: pagination.current,
                openai_status: (filters.openai_status),
                thumbnails_status: (filters.thumbnails_status),
                album_tags_status: (filters.album_tags_status),
            };
    
            handleSearchFormChange(formData, false);
        }
    };
    
    return (
        <>
        {contextHolder}

        {selectedIds != [] && selectedIds.length > 0 && (
            <div
                style={{
                    position: 'fixed',
                    top: '16px',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    background: '#fff',
                    padding: '20px',
                    color: '#000',
                    borderRadius: '10px',
                    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
                    zIndex: 11,
                }}
            >
            <Space size="middle">
                <span><b>Выбрано:</b> {selectedIds.length}</span>
                <Popconfirm
                    title="Перегенерация миниатюр"
                    description="Вы уверены в этом действии?"
                    okText="Подтверждаю"
                    cancelText="Отменить"
                    onConfirm={() => {
                        messageApi.open({
                            type: 'loading',
                            content: 'Перегенерация миниатюр запущена',
                            duration: 8,
                        });

                        rethumbnailingImages(selectedIds);
                        setSelectedIds([]);
                        handleSearchFormChange({}, true);
                    }}
                    >
                    <Button
                        icon={<PictureOutlined />}
                        key="selected-rethumbnails"
                        shape="circle"
                    />
                </Popconfirm>
                
                <Popconfirm
                    title="Перегенерация тегов"
                    description="Вы уверены в этом действии?"
                    okText="Подтверждаю"
                    cancelText="Отменить"
                    onConfirm={() => {
                        messageApi.open({
                            type: 'loading',
                            content: 'Пересоздание тегов запущено',
                            duration: 8,
                        });

                        retaggingImages(selectedIds);
                        setSelectedIds([]);
                        handleSearchFormChange({}, true);
                    }}
                >
                    <Button
                        icon={<TagsOutlined />}
                        key="selected-retagging"
                        shape='circle'
                    />
                </Popconfirm>
                
                <Popconfirm
                    title="Пересоздание тегов по альбому"
                    description="Вы уверены в этом действии?"
                    okText="Подтверждаю"
                    cancelText="Отменить"
                    onConfirm={() => {
                        messageApi.open({
                            type: 'loading',
                            content: 'Пересоздание тегов по альбому запущено',
                            duration: 8,
                        });

                        albumRetaggingImages(selectedIds);
                        setSelectedIds([]);
                        handleSearchFormChange({}, true);
                    }}
                >
                    <Button
                        icon={<TagOutlined />}
                        key="selected-album-retagging"
                        shape='circle'
                    />
                </Popconfirm>
            </Space>
            </div>
        )}

        {!loadingAlbums && !loadingImages && images ?
            <Table
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedIds,
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedIds(selectedRowKeys);
                    },
                }}
                rowKey="id"
                columns={columns}
                dataSource={images}
                onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
                locale={customLocale}
                rowClassName={rowClassName}
                scroll={{ x: 1400 }}
                tableLayout='auto'
                bordered
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log(event);
                            if (event.target.cellIndex !== 0) {
                                setSelectedImage(rowIndex);
                                setAlbumSlider(true);
                            }
                        },
                    };
                }}
                pagination={{
                    onChange: (newPage) => {handleSearchFormChange({page: newPage})},
                    total: meta?.total,
                    pageSize: meta?.per_page,
                    current: meta?.current_page,
                    defaultCurrent: 1,
                    showSizeChanger: false,
                    position: ['bottomCenter'],
                }}
            />
        :
            <ImagesLogsSkeleton count={9} />
        }
        </>
    );
}

export default ImagesLogs;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users_user_header__qF\\+wn {
	position: sticky;
	top: 64px;
	padding: 20px 20px;
	margin: 0;
	border-bottom: 1px solid rgba(5, 5, 5, 0.06);
	background-color: #f2f2f1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 4
}

.Users_user_total__5tX-L {
	text-align: left;
	display: flex;
	justify-content: flex-start;
	gap: 5px;
	font-size: 16px;
}

.Users_user_btn__cdlgW {
	display: flex;
	align-items: center;
	text-align: right;
	justify-content: flex-end;
}

.Users_user_table__A3ymZ {
	position: relative;
	z-index: 1;
}

.Users_blue__uBtKP {
	color: #29355c;
}

.Users_not_found__JzbxY {
	padding: 10px;
	text-align: center;
}

@media screen and (max-width: 480px) {
	.Users_user_header__qF\\+wn {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/Users/Users.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,SAAS;CACT,kBAAkB;CAClB,SAAS;CACT,4CAA4C;CAC5C,yBAAyB;CACzB,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB;AACD;;AAEA;CACC,gBAAgB;CAChB,aAAa;CACb,2BAA2B;CAC3B,QAAQ;CACR,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,UAAU;AACX;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC;EACC,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;CACV;AACD","sourcesContent":[".user_header {\n\tposition: sticky;\n\ttop: 64px;\n\tpadding: 20px 20px;\n\tmargin: 0;\n\tborder-bottom: 1px solid rgba(5, 5, 5, 0.06);\n\tbackground-color: #f2f2f1;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tz-index: 4\n}\n\n.user_total {\n\ttext-align: left;\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tgap: 5px;\n\tfont-size: 16px;\n}\n\n.user_btn {\n\tdisplay: flex;\n\talign-items: center;\n\ttext-align: right;\n\tjustify-content: flex-end;\n}\n\n.user_table {\n\tposition: relative;\n\tz-index: 1;\n}\n\n.blue {\n\tcolor: #29355c;\n}\n\n.not_found {\n\tpadding: 10px;\n\ttext-align: center;\n}\n\n@media screen and (max-width: 480px) {\n\t.user_header {\n\t\tflex-direction: column;\n\t\talign-items: flex-start;\n\t\tgap: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_header": `Users_user_header__qF+wn`,
	"user_total": `Users_user_total__5tX-L`,
	"user_btn": `Users_user_btn__cdlgW`,
	"user_table": `Users_user_table__A3ymZ`,
	"blue": `Users_blue__uBtKP`,
	"not_found": `Users_not_found__JzbxY`
};
export default ___CSS_LOADER_EXPORT___;

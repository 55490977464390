// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Date_datepicker__wqeCE {
  width: 100%;
  margin-top: 5px;
}

.Date_datepicker_slider__ECV1F {
  width: 100%;
}

.Date_text_wrapper__MW7xO {
  margin-top: 5px;
}

.Date_text_normal__LcbGp {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
} `, "",{"version":3,"sources":["webpack://./src/components/Date/Date.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".datepicker {\n  width: 100%;\n  margin-top: 5px;\n}\n\n.datepicker_slider {\n  width: 100%;\n}\n\n.text_wrapper {\n  margin-top: 5px;\n}\n\n.text_normal {\n  font-size: 14px;\n  font-weight: normal;\n  color: rgba(0, 0, 0, 0.85);\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepicker": `Date_datepicker__wqeCE`,
	"datepicker_slider": `Date_datepicker_slider__ECV1F`,
	"text_wrapper": `Date_text_wrapper__MW7xO`,
	"text_normal": `Date_text_normal__LcbGp`
};
export default ___CSS_LOADER_EXPORT___;

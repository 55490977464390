import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';

import s from './DeleteUserForm.module.css';

const DeleteUserForm = ({ deletingUser, onSubmit, onCancel, isOpen }) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setUserId(deletingUser?.id);
  }, [deletingUser])

  const handleSubmit = () => {
    onSubmit(userId);
    onCancel();
  };

  return (
    <Modal
      centered
      open={isOpen}
      onCancel={onCancel}
      styles={{ body: { padding: '20px' } }}
      footer={null}
    >
      <h4 className={s.title}>Подтвердите удаление</h4>
      <div className={s.wrapper}>
        <Button
          size="large"
          onClick={onCancel}
        >
          Отменить
        </Button>
        <Button
          danger
          size="large"
          onClick={handleSubmit}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteUserForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.License_license_container__LuYZ5 {
	margin-top: 0;
}

.License_license_title__R21HU {
	margin-bottom: 5px;
}

.License_select__phQ2V {
	width: 100%;
}

.License_select_slider__dHACH {
	margin-top: 5px;
}

.License_text_wrapper__wYxG- {
	display: flex;
	flex-direction: column;
}

.License_text__JA-TR {
	margin: 0;
}

.License_text_light__2jDMf {
	font-weight: lighter;
	margin-left: 5px;
}

.License_license_info__rJiv0 {
	margin-top: 5px;
	font-size: 0.9em;
	color: #666;
}

.License_time_left__KRP-r {
	margin-bottom: 2px;
}

.License_contract_date__h-Y8t {
	font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/License/License.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,oBAAoB;CACpB,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,WAAW;AACZ;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".license_container {\n\tmargin-top: 0;\n}\n\n.license_title {\n\tmargin-bottom: 5px;\n}\n\n.select {\n\twidth: 100%;\n}\n\n.select_slider {\n\tmargin-top: 5px;\n}\n\n.text_wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.text {\n\tmargin: 0;\n}\n\n.text_light {\n\tfont-weight: lighter;\n\tmargin-left: 5px;\n}\n\n.license_info {\n\tmargin-top: 5px;\n\tfont-size: 0.9em;\n\tcolor: #666;\n}\n\n.time_left {\n\tmargin-bottom: 2px;\n}\n\n.contract_date {\n\tfont-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"license_container": `License_license_container__LuYZ5`,
	"license_title": `License_license_title__R21HU`,
	"select": `License_select__phQ2V`,
	"select_slider": `License_select_slider__dHACH`,
	"text_wrapper": `License_text_wrapper__wYxG-`,
	"text": `License_text__JA-TR`,
	"text_light": `License_text_light__2jDMf`,
	"license_info": `License_license_info__rJiv0`,
	"time_left": `License_time_left__KRP-r`,
	"contract_date": `License_contract_date__h-Y8t`
};
export default ___CSS_LOADER_EXPORT___;

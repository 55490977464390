import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import cn from 'classnames';
import s from './Date.module.css';

dayjs.extend(utc);

const DateComponent = ({ date, editable, title, isSlider, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(date ? dayjs(date).utc(true) : null);

  const handleDateChange = (value) => {
    setSelectedDate(value);
    onChange(value ? value.format('YYYY-MM-DD') : null);
  };

  useEffect(() => {
    setSelectedDate(date ? dayjs(date).utc(true) : null);
  }, [date]);

  const displayDate = selectedDate ? selectedDate.format('DD.MM.YYYY') : 'Дата не указана';

  return (
    <div>
      {title && (
        <div>
          <b>Дата изображения:</b>
        </div>
      )}
      {editable ? (
        <DatePicker
          size={isSlider ? 'small' : 'large'}
          placeholder="Выберите дату"
          value={selectedDate}
          className={cn(s.datepicker, { [s.datepicker_slider]: isSlider })}
          onChange={handleDateChange}
          format="DD.MM.YYYY"
        />
      ) : (
        <div className={s.text_wrapper}>
          <div className={s.text_normal}>
            {displayDate}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateComponent; 
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Col, Tooltip } from 'antd';
import { WarningOutlined, DownloadOutlined, HeartOutlined, HeartFilled, UpOutlined, DownOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Tags, License } from '../index.js';
import styles from './ImageCard.module.css';
import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

const ImageCard = ({ 
  image, 
  album, 
  index, 
  handleTagClick, 
  setSelectedImage, 
  setAlbumSlider
}) => {
  const [expanded, setExpanded] = useState(false);
  const cardRef = useRef(null);
  const [isExpandNeeded, setIsExpandNeeded] = useState(false);

  const { dispatch } = useContext(providerContext);
  const { toggleLikeImage } = getMethods(dispatch);
  
  const handleLike = (imageId) => {
    toggleLikeImage(imageId);
  };
  
  const handleExpandChange = (isExpanded) => {
    setExpanded(isExpanded);
    
    if (isExpanded && cardRef.current) {
      setTimeout(() => {
        const rect = cardRef.current.getBoundingClientRect();
        if (rect.bottom > window.innerHeight) {
          cardRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
        }
      }, 0);
    }
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (expanded && cardRef.current && !event.target.closest(`.${styles.tagBlock}`)) {
        setExpanded(false);
      }
    };
    
    if (expanded) {
      document.addEventListener('click', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [expanded]);
  
  // Локальный компонент кнопки расширения
  const ExpansionButton = ({ expanded, toggleExpanded, className }) => (
    <div 
      className={className || styles.expansionButtonContainer}
      style={{ 
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        zIndex: 10,
        pointerEvents: 'auto',
        opacity: 1
      }}
    >
      <div
        style={{
          width: '26px',
          height: '26px',
          borderRadius: '10px',
          background: 'rgba(255, 255, 255, 0.95)',
          border: '1px dashed #d9d9d9',
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          margin: '0'
        }}
        onClick={toggleExpanded}
      >
        {expanded ? 
          <UpOutlined style={{ fontSize: '13px', color: '#555' }} /> : 
          <DownOutlined style={{ fontSize: '13px', color: '#555' }} />
        }
      </div>
    </div>
  );
  
  return (
    <Col 
      xs={24} sm={24} md={24} lg={12} xl={8} 
      className={styles.imageCard}
    >
      {/* ID изображения */}
      <div className={styles.imageIdBlock}>
        <span className={styles.idValue}>
          ID: {image.id}
        </span>
      </div>
      
      {/* Счетчики */}
      <div className={styles.countersBlock}>
        <div className={styles.engagementCounter}>
          <ThunderboltOutlined style={{ fontSize: '14px', color: '#888' }} />
          <span className={styles.counterValue}>
            {image.total_engagement || 0}
          </span>
        </div>
        
        <div className={styles.counterDivider}></div>
        
        <div className={styles.downloadCounter}>
          <DownloadOutlined style={{ fontSize: '14px', color: '#888' }} />
          <span className={styles.counterValue}>
            {image.downloads_count || 0}
          </span>
        </div>
        
        <div className={styles.counterDivider}></div>
        
        <div 
          className={styles.likeCounter}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleLike(image.id);
          }}
        >
          {image.is_liked ? 
            <HeartFilled style={{ fontSize: '14px', color: '#ff4d4f' }} /> : 
            <HeartOutlined style={{ fontSize: '14px', color: '#333' }} />
          }
          <span className={styles.counterValue}>
            {image.likes_count || 0}
          </span>
        </div>
      </div>
      
      {/* Основная карточка */}
      <div ref={cardRef} className={styles.cardWrapper}>
        {/* Изображение */}
        <div 
          className={styles.imageContainer}
          onClick={(e) => {
            if (!e.target.closest(`.${styles.tagBlock}`)) {
              setSelectedImage(index);
              setAlbumSlider(true);
            }
          }}
        >
          {image.url_thumbnail ? (
            <>
              <div className={styles.imageBlurBackground} style={{backgroundImage: `url(${image.url_thumbnail})`}} />
              <div className={styles.imageWrapper}>
                <img 
                  src={image.url_thumbnail} 
                  className={styles.image} 
                  alt={image.original_name || 'Изображение'} 
                />
              </div>
            </>
          ) : (
            <div className={styles.placeholderContainer}>
              Обработка изображения...
            </div>
          )}
        </div>
        
        {/* Информация о карточке */}
        <div className={styles.cardContent}>
          {/* Блок тегов, теперь включает информацию об альбоме и лицензии */}
          <div className={`${styles.tagBlock} ${expanded ? styles.expanded : ''}`} onClick={(e) => e.stopPropagation()}>
            {/* Информация об альбоме и лицензии */}
            <div className={styles.albumInfoInTags}>
              <div className={styles.albumTitle}>
                {album?.title || "Корневой альбом"}
                {image.is_updated_album_id && (
                  <Tooltip title="Изображение было перемещено">
                    <WarningOutlined style={{ marginLeft: '5px', color: '#ee2250' }} />
                  </Tooltip>
                )}
              </div>
              <div className={styles.licenseContainer}>
                <License licenseStatus={image.license_status} editable={false} isCard={true} isSlider={false} />
              </div>
            </div>
            
            {/* Теги */}
            <div className={styles.tagsContainer}>
              {image.tags?.length > 0 || image.album_tags?.length > 0 ? (
                <Tags 
                  imageTags={image.tags} 
                  imageAlbumTags={image.album_tags} 
                  expanded={expanded}
                  handleExpandNeeded={setIsExpandNeeded}
                  handleTagClick={(tag) => {
                    setExpanded(false);
                    handleTagClick(tag);
                  }}
                />
              ) : (
                <span className={styles.noTags}>Теги для фотографии не указаны</span>
              )}
            </div>
            
            {/* Кнопка расширения показывается только если действительно нужно расширение */}
            {image.tags?.length > 0 || image.album_tags?.length > 0 ? 
              isExpandNeeded && (
                <ExpansionButton 
                  expanded={expanded} 
                  toggleExpanded={() => handleExpandChange(!expanded)} 
                  className={styles.expansionButtonContainer}
                />
              ) : null
            }
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ImageCard;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import Provider from './shared/provider/provider';
import PrivateRoute from './PrivateRoute';

import Header from './components/Header/Header';
import { Authentication, Home, Users, Console } from './pages';
import YandexUploadPage from './pages/YandexUploadPage/YandexUploadPage';

dayjs.locale('ru');

function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <Router>
        <Provider>
          <Routes>
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Header />
                  <Routes>
                    <Route index element={<Home isUploadingMode={false} />} />
                    <Route path='/upload' element={<Home isUploadingMode={true} />} />
                    <Route path='/images/yandex' element={<YandexUploadPage />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/console" element={<Console />} />
                  </Routes>
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Authentication />} />
          </Routes>
        </Provider>
      </Router>
    </ConfigProvider>
  );
}

export default App;

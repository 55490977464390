import { Row, Pagination } from 'antd';
import React from 'react';
import ImageCard from '../ImageCard/ImageCard';

import style from './ImagesList.module.css';

const ImagesList = ({ images, albums, meta, handlePageChange, handleTagClick, setAlbumSlider, setSelectedImage }) => {
    return (
        <div>
            <Row gutter={[20, 20]} className={style.imagesList}>
                {images.map((image, index) => (
                    <ImageCard
                        key={index}
                        index={index}
                        image={image}
                        album={albums.find((album) => album.id == image.album_id)}
                        handleTagClick={handleTagClick}
                        setSelectedImage={setSelectedImage}
                        setAlbumSlider={setAlbumSlider}
                    />
                ))}
            </Row>
            { meta && images.length > 0 && (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}>
                    <Pagination onChange={handlePageChange} total={meta?.total} pageSize={meta?.per_page} current={meta?.current_page} defaultCurrent={1} showSizeChanger={false} />
                </div>
            )}
        </div>
    );
};

export default ImagesList;
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Col, Row, Select, DatePicker, Button } from 'antd';
import { CloseCircleOutlined, MinusCircleOutlined, ReloadOutlined, UploadOutlined, FolderOutlined, TagOutlined } from '@ant-design/icons';

import { License } from '../../components/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

import s from './FilterImagesForm.module.css';
import cn from 'classnames';

const FilterImagesForm = ({ handleSearchFormChange, handleActiveAlbumChange, isEditableImage }) => {
  const { Option } = Select;

  const { state, dispatch } = useContext(providerContext);
  const { discoverTags } = getMethods(dispatch);

  const [searchOptions, setSearchOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchTags = (value) => {
    setSearchQuery(value);
    if (value.length > 0) {
      discoverTags(value);
    } else {
      setSearchOptions([]);
    }
  };

  const [tags, setTags] = useState(state.imagesParams.tags);
  const [dateRange, setDateRange] = useState(state.imagesParams.dateRange);
  const [orientation, setOrientation] = useState(state.imagesParams.orientation);
  const [license, setLicense] = useState(state.imagesParams.license);

  function getPathForAlbum(album, albumsMap) {
    let path = [];
    let currentAlbum = album;
    
    while (currentAlbum && currentAlbum.parent_id !== 0 && currentAlbum.parent_id !== null) {
        currentAlbum = albumsMap.find(item => item.id === currentAlbum.parent_id);
        if (currentAlbum) {
          path.unshift(currentAlbum.title);
        }
    }

    path.unshift('.');
    
    return path.join(' / '); 
  }

  useEffect(() => {
    const filteredTags = state.discoverTags.filter((tag) => !tags.includes(tag.value));
    if(searchOptions !== filteredTags) {

      let albums = [];

      if (searchQuery.length > 1) {
        albums = state.albums
          .filter(album => album.title.toLowerCase().includes(searchQuery.toLowerCase()) && album.id != state.imagesParams.album_id) 
          .slice(0, 3) 
          .map(album => ({
            type: "album",
            album_id: album.id,
            key: "album:" + album.id,
            value: album.title,
            path: getPathForAlbum(album, state.albums),
            last: false,
          }))
          .map((album, index, array) => {
            if (index === array.length - 1) {
              return { ...album, last: true };
            }
            return album;
          });
      }
      
      setSearchOptions([...albums, ...filteredTags]);
    }
  }, [state.discoverTags])

  const handleSubmit = (refresh = false) => {

    const formData = {
      tags,
      dateRange: dateRange,
      date_from: dateRange && dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null,
      date_to: dateRange && dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null,
      orientation,
      license,
    };

    handleSearchFormChange(formData, refresh);
  };

  useEffect(() => {
    handleSubmit();
  }, [tags, dateRange, orientation, license]);

  const handleChangeTags = (value) => {
    const option = searchOptions.find(option => option.type == "album" && value.includes(option.key)) ?? null;
    if (option !== null) {
      handleActiveAlbumChange(option.album_id);
      return;
    }
    if (JSON.stringify(tags) !== JSON.stringify(value)) {
      setTags(value);
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dateRange != dates) {
      setDateRange(dates);
    }
  };

  const handleOrientationChange = (value) => {
    if (orientation != value) {
      setOrientation(value);
    }
  };

  const handleLicenseChange = (value) => {
    if (license != value) {
      setLicense(value);
    }
  };

  const handleReset = () => {
    setTags([]);
    setDateRange([]);
    setOrientation(null);
    setLicense(null);
  };

  return (
    <Col className={s.filter_wrapper}>
      <Col className={cn(s.filter_buttons, s.filter_buttons_desktop)}>
        { isEditableImage && 
        <Col>
          <Link to='/upload'>
            <Button icon={<UploadOutlined />} className={s.light} size='large' type="primary" />
          </Link>
        </Col>
        }
        <Col>
          <Button icon={<ReloadOutlined />} className={s.blue} size='large' type="default" block onClick={() => handleSubmit(true)} />
        </Col>
      </Col>
      <Row gutter={[8, 8]} className={s.w_full}>
        <Col xs={24} sm={24} md={24} lg={12} xl={9}>
          <Select
            mode="multiple"
            className={s.w_full}
            size='large'
            filterOption={false}
            placeholder='Поиск изображений'
            onSearch={handleSearchTags}
            onChange={handleChangeTags}
            onSelect={() => {
              setSearchOptions([]);
              setSearchQuery('');
            }}
            onBlur={() => {
              setSearchOptions([]);
              setSearchQuery('');
            }}
            notFoundContent={
              <div className={s.filter_select}>
                {searchQuery ?
                  (
                    <span className={s.grey}>
                      <MinusCircleOutlined /> Теги и альбомы не найдены
                    </span>
                  )
                  :
                  (
                    <span className={s.blue}>
                      &uarr; Введите запрос &uarr;
                    </span>
                  )}
              </div>
            }
            value={tags}
            maxTagCount='responsive'
          >
            {searchOptions.map(option => (
                <Option key={option.key} value={option.type === "album" ? option.key : option.value} className={`${s.option} ${option.type === "album" && option.last && s.option_album_last}`} >
                    {option.type === "album"
                      ? <div className={s.option_album_item}>
                          <div className={s.option_item_title}>
                            <FolderOutlined className={s.option_icon} /> <b>{option.value}</b>
                          </div> <span className={s.option_album_item_hint}>{option.path}</span>
                        </div>
                      : 
                        <div className={s.option_item_title}>
                          <TagOutlined className={s.option_icon} /> {option.value}
                        </div>
                      }
                </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={5}>
          <DatePicker.RangePicker
            size='large'
            className={s.w_full}
            separator={null}
            placeholder={['Дата', '']}
            format='DD-MM-YY'
            onChange={handleDateRangeChange}
            value={dateRange}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={5}>
          <Select
            placeholder='Ориентация'
            className={s.w_full}
            size='large'
            onChange={handleOrientationChange}
            value={orientation}
            options={[
              {
                value: "",
                label: 'Не выбрано',
              },
              {
                value: 'horizontal',
                label: 'Горизонтальная',
              },
              {
                value: 'vertical',
                label: 'Вертикальная',
              }
            ]}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={5}>
          <License editable onChange={handleLicenseChange} license={license} />
        </Col>
      </Row>
      <Col className={s.filter_buttons_desktop}>
        <Button icon={<CloseCircleOutlined />} className={s.pink} size='large' type="default" block onClick={handleReset} />
      </Col>
      <Col className={s.filter_buttons_mobile}>
        <Col className={cn(s.filter_buttons)}>
          { isEditableImage && 
          <Col>
            <Link to='/upload'>
              <Button icon={<UploadOutlined />} className={s.light}size='large' type="primary" />
            </Link>
          </Col>
          }
          <Col>
            <Button icon={<ReloadOutlined />} className={s.blue} size='large' type="default" block onClick={() => handleSubmit(true)} />
          </Col>
        </Col>
        <Col>
          <Button icon={<CloseCircleOutlined />} className={s.pink} size='large' type="default" block onClick={handleReset} />
        </Col>
      </Col>
    </Col>
  );
};

export default FilterImagesForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditAlbumForm_buttons_wrapper__Z\\+92L {
	border-top: 1px solid #ddd;
	background-color: #eee;
	padding: 20px;
	margin-top: 0;
	display: flex;
	gap: 20px;
}

.EditAlbumForm_input__IpCh4 {
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/forms/EditAlbumForm/EditAlbumForm.module.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,sBAAsB;CACtB,aAAa;CACb,aAAa;CACb,aAAa;CACb,SAAS;AACV;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".buttons_wrapper {\n\tborder-top: 1px solid #ddd;\n\tbackground-color: #eee;\n\tpadding: 20px;\n\tmargin-top: 0;\n\tdisplay: flex;\n\tgap: 20px;\n}\n\n.input {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons_wrapper": `EditAlbumForm_buttons_wrapper__Z+92L`,
	"input": `EditAlbumForm_input__IpCh4`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AlbumsContainer,
  ImagesContainer,
  AlbumsContainerSkeleton,
} from '../../components/index.js';

import {
  UploadImagesForm,
} from '../../forms/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

const Home = ({ isUploadingMode }) => {

  const navigate = useNavigate();

  const { state, dispatch } = useContext(providerContext);
  const { getImages, getAlbums, setImagesParams } = getMethods(dispatch);

  const [activeAlbumId, setActiveAlbumId] = useState(null);

  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingAlbums, setLoadingAlbumbs] = useState(true);

  useEffect(() => {

    if (isUploadingMode && state.whoam?.role < 1) {
      navigate('/');
    }

  }, [isUploadingMode, state.whoam?.role]);

  useEffect(() => {
    const data = { ...state.imagesParams, album_id: activeAlbumId };
    try {
      setLoadingImages(true);
      getAlbums();
      getImages(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [state.imagesParams]); 

  useEffect(() => {
    if (state.albums != null) {
      setLoadingAlbumbs(false);
    }
  }, [state.albums]);

  useEffect(() => {
    if (state.images != null) {
      setLoadingImages(false);
    }
  }, [state.images]);

  const handleActiveAlbumChange = (newActiveAlbumId) => {
    if (activeAlbumId !== newActiveAlbumId) {
      setImagesParams({ album_id: newActiveAlbumId, page: 1 });
      setActiveAlbumId(newActiveAlbumId);
    }
  };

  const handleSearchFormChange = (newForm, refresh = false) => {
    if (refresh || JSON.stringify(state.imagesParams) !== JSON.stringify({ ...state.imagesParams, ...newForm })) {
      setImagesParams({ page: (refresh ? state.imagesParams.page : 1), ...newForm });
    }
  };

  const handlePageChange = (newPage) => {
    if (state.imagesParams.page !== newPage) {
      setImagesParams({ page: newPage });
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      { !loadingAlbums ?
        <AlbumsContainer albums={state.albums} activeAlbumId={activeAlbumId} handleActiveAlbumChange={handleActiveAlbumChange} isManagable={state.whoam?.role >= 1} />
        :
        <AlbumsContainerSkeleton count={5} />
      }
      { isUploadingMode ?
        <UploadImagesForm activeAlbumId={activeAlbumId} />
        :
        <ImagesContainer images={state.images} meta={state.imagesMeta} albums={state.albums} activeAlbumId={activeAlbumId} handleSearchFormChange={handleSearchFormChange} handlePageChange={handlePageChange} handleActiveAlbumChange={handleActiveAlbumChange} isEditableImage={state.whoam?.role >= 1} isLoaded={!isUploadingMode && !loadingImages && !loadingAlbums} />
      }
    </div>
  );
}

export default Home;

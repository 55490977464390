// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.License_select__phQ2V {
	width: 100%;
}

.License_select_slider__dHACH {
	margin-top: 5px;
}

.License_text_wrapper__wYxG- {
	display: flex;
}

.License_text__JA-TR {
	margin: 0;
}

.License_text_light__2jDMf {
	font-weight: lighter;
	margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/License/License.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,oBAAoB;CACpB,gBAAgB;AACjB","sourcesContent":[".select {\n\twidth: 100%;\n}\n\n.select_slider {\n\tmargin-top: 5px;\n}\n\n.text_wrapper {\n\tdisplay: flex;\n}\n\n.text {\n\tmargin: 0;\n}\n\n.text_light {\n\tfont-weight: lighter;\n\tmargin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `License_select__phQ2V`,
	"select_slider": `License_select_slider__dHACH`,
	"text_wrapper": `License_text_wrapper__wYxG-`,
	"text": `License_text__JA-TR`,
	"text_light": `License_text_light__2jDMf`
};
export default ___CSS_LOADER_EXPORT___;

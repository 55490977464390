import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Divider, Checkbox, Modal, Row, Col, Input, Popconfirm } from 'antd';
import { 
  ArrowLeftOutlined, 
  CloudUploadOutlined,
  LogoutOutlined,
  FolderOutlined,
  FileImageOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import cn from 'classnames';
import styles from './InnerYandexStructure.module.css';
import pageStyles from '../../YandexUploadPage.module.css';
import EditTagsLicenseImageForm from '../../../../forms/EditTagsLicenseImageForm/EditTagsLicenseImageForm';
import InnerYandexSkeleton from './InnerYandexSkeleton';
import LazyImage from './LazyImage';

const isImageItem = (item) => item.type === 'image';

const InnerYandexStructure = ({ 
  structure, 
  info,
  currentDir, 
  tokenExpiresAt,
  handleCalculateFilesToUpload,
  handleSelectDirectory, 
  handleSetMeta,
  handleStartUpload,
  handleReset,
  handleLogout,
  handleUpload,
  isLoading = false
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(10);

  const [isImagesSettingsModalOpen, setIsImagesSettingsModalOpen] = useState(false);
  const [editNameItem, setEditNameItem] = useState(null);
  const [editNameValue, setEditNameValue] = useState('');
  const [savingNamePath, setSavingNamePath] = useState(null);
  const [savingBulkItems, setSavingBulkItems] = useState([]);
  
  const inputRef = useRef(null);
  
  const [isCalculating, setIsCalculating] = useState(false);
  const [lastCalculationId, setLastCalculationId] = useState(null);
  
  // Перемещаем объявление visibleImageItems и imageItems
  const imageItems = structure && typeof structure === 'object' 
    ? Object.values(structure).filter(isImageItem)
    : [];
  
  const visibleImageItems = imageItems.slice(0, displayLimit);
  
  // Определяем, есть ли еще изображения для показа
  const hasMoreImages = imageItems.length > displayLimit;
  
  // Эффект для фокуса на поле ввода при активации редактирования
  useEffect(() => {
    if (editNameItem && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editNameItem]);
  
  // Эффект для отслеживания изменений в info
  useEffect(() => {
    // Если был запущен пересчет и есть новые данные, сбрасываем состояние загрузки
    if (isCalculating && lastCalculationId !== null) {
      setIsCalculating(false);
      setLastCalculationId(null);
    }
  }, [info, isCalculating, lastCalculationId]);
  
  // Функция для отображения большего количества элементов
  const handleShowMore = () => {
    // Увеличиваем лимит отображения
    setDisplayLimit(prevLimit => {
      const newLimit = prevLimit + 10;
      return newLimit;
    });
    
    // Добавляем небольшую задержку, чтобы DOM успел обновиться
    setTimeout(() => {
      // Принудительно запускаем перерисовку для новых элементов
      window.dispatchEvent(new Event('resize'));
    }, 300);
  };
  
  // Изменим логику перехода в директорию, чтобы сбрасывать выбранные элементы и лимит отображения
  const navigateToDirectory = (path) => {
    setSelectedItems([]);
    setDisplayLimit(10);
    handleSelectDirectory(path);
  };
  
  // Функция для перехода в родительскую директорию
  const goToParentDirectory = () => {
    const parentDir = currentDir.split('/').slice(0, -1).join('/') || '/';
    navigateToDirectory(parentDir);
  };
  
  // Функция для получения имени родительской папки
  const getParentFolderName = () => {
    if (currentDir === '/') return 'Корень';
    
    const pathParts = currentDir.split('/').filter(Boolean);
    if (pathParts.length <= 1) return 'Корень';
    
    // Возвращаем родительскую директорию
    return pathParts[pathParts.length - 2] || 'Корень';
  };
  
  // Функция для выбора/отмены всех изображений
  const handleSelectAllImages = (e) => {
    if (e.target.checked) {
      // Выбираем все изображения
      const imageIds = imageItems.map(item => item.path);
      setSelectedItems(imageIds);
    } else {
      // Снимаем выбор со всех
      setSelectedItems([]);
    }
  };
  
  // Обновляем логику клика по элементу
  const handleItemClick = (item) => {
    // Если директория - переходим в неё
    if (item.type === 'dir') {
      navigateToDirectory(item.path);
    } 
    // Если это изображение - выбираем/снимаем выбор
    else if (isImageItem(item)) {
      // Добавляем или удаляем из выбранных
      if (selectedItems.includes(item.path)) {
        setSelectedItems(selectedItems.filter(id => id !== item.path));
      } else {
        setSelectedItems([...selectedItems, item.path]);
      }
    }
  };
  
  // Обновленный обработчик для чекбокса с полной остановкой всплытия событий
  const handleCheckboxChange = (item, e) => {
    e.stopPropagation(); // Предотвращаем вызов handleItemClick
    
    // Добавляем или удаляем из выбранных
    if (selectedItems.includes(item.path)) {
      setSelectedItems(selectedItems.filter(id => id !== item.path));
    } else {
      setSelectedItems([...selectedItems, item.path]);
    }
  };
  
  // Обертка для лучшей остановки всплытия
  const handleCheckboxContainerClick = (e) => {
    e.stopPropagation(); // Остановка всплытия до клика по карточке
  };
  
  // Настройки изображений
  const handleOpenImagesSettingsModal = () => {
    if (selectedItems.length > 0) {
      setIsImagesSettingsModalOpen(true);
    }
  };
  
  const handleCloseImagesSettingsModal = () => {
    if (savingBulkItems.length === 0) {
      setIsImagesSettingsModalOpen(false);
    }
  };

  const handleSaveImagesSettings = (formData) => {
    console.log('Сохранить:', formData);
    
    // Устанавливаем индикатор сохранения для всех выбранных элементов
    setSavingBulkItems([...selectedItems]);
    
    // Закрываем модальное окно сразу после нажатия кнопки
    setIsImagesSettingsModalOpen(false);
    
    // Вызываем метод сохранения и получаем Promise
    handleSetMeta(selectedItems, formData)
      .then(response => {
        console.log('Массовое сохранение завершено:', response);
        // Очищаем список сохраняемых элементов
        setSavingBulkItems([]);
      })
      .catch(error => {
        console.error('Ошибка при массовом сохранении:', error);
        // Очищаем список сохраняемых элементов даже при ошибке
        setSavingBulkItems([]);
      });
  };

  const handleButtonReset = () => {
    setSelectedItems([]);
    handleReset();
  };

  // Редактирование имени элемента
  const handleClickEditName = (item, e) => {
    e.stopPropagation();
    if (savingNamePath === item.path) return;
    setEditNameItem(item);
    setEditNameValue(item.meta.name);
  };

  const handleSaveEditName = (e, path, name) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (path && name) {
      console.log('Новое имя для:', path, 'Имя:', name);
      setSavingNamePath(path);
      setEditNameItem(null);
      setEditNameValue('');
      
      // Теперь мы уверены, что handleSetMeta всегда возвращает Promise
      handleSetMeta([path], { name: name })
        .then(response => {
          console.log('Сохранение имени успешно завершено:', response);
          setSavingNamePath(null);
        })
        .catch(error => {
          console.error('Ошибка при сохранении имени:', error);
          setSavingNamePath(null);
        });
    }
  };
  
  const handleCancelEditName = (e) => {
    e.stopPropagation();
    setEditNameItem(null);
    setEditNameValue('');
  };

  const handleKeyPressEditName = (e, item) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSaveEditName(e, item.path, editNameValue);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      handleCancelEditName(e);
    }
  };

  // Удаление элемента
  const handleDeleteItem = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (path) {
      console.log('Удалить:', path);
      setSelectedItems(prev => prev.filter(selectedPath => selectedPath !== path));
      
      setSavingNamePath(path);
      
      handleSetMeta([path], { is_deleted: true })
        .then(() => {
          setSavingNamePath(null);
        })
        .catch(error => {
          console.error('Ошибка при удалении:', error);
          setSavingNamePath(null);
        });
    }
  };
  
  // Если идет загрузка, показываем скелетон
  if (isLoading) {
    return <InnerYandexSkeleton 
      currentDir={currentDir} 
      info={info}
      tokenExpiresAt={tokenExpiresAt}
    />;
  }
  
  // Получаем элементы для отображения
  const directoryItems = structure && typeof structure === 'object' 
    ? Object.values(structure).filter(item => item.type === 'dir')
    : [];
  
  return (
    <div className={styles.structureContainer}>
      {/* Черная панель с информацией о директории */}
      <div className={pageStyles.directoryHeader}>
        <div className={pageStyles.directoryHeaderContent}>
          <div className={pageStyles.headerLeftPart}>
            <span className={pageStyles.yandexDiskLabel}>Яндекс.Диск</span>
            <Divider type="vertical" className={pageStyles.headerDivider} />
            <span className={pageStyles.currentDirPath}>Вы здесь: {currentDir}</span>
          </div>
          <Link to="/" className={pageStyles.headerHomeLink}>
            <CloseOutlined className={pageStyles.headerHomeIcon} />
          </Link>
        </div>
      </div>

      {/* Верхний бар с кнопками */}
      <div className={styles.filterBar}>
        <div className={styles.filterBarContent}>
          <div className={styles.filterBarRow}>
            <div className={styles.filterBarInfo}>
              <div className={styles.massActionsHeader}>
                <Space size="small" className={styles.controlsRow}>
                
                <Checkbox 
                  disabled={imageItems.length === 0}
                  onChange={handleSelectAllImages}
                  checked={selectedItems.length === imageItems.length && imageItems.length > 0}
                  indeterminate={selectedItems.length > 0 && selectedItems.length < imageItems.length}
                >
                  Выбрать все
                </Checkbox>

                <Divider type="vertical" className={styles.actionsDivider} /> 
                  <span className={styles.selectedCount}>
                    Выбрано: {selectedItems.length}
                  </span>
                  
                  <Divider type="vertical" className={styles.actionsDivider} />

                  <Button 
                    icon={<EditOutlined />} 
                    size="small"
                    disabled={selectedItems.length === 0}
                    onClick={handleOpenImagesSettingsModal}
                  >
                    Настройки файлов
                  </Button>
                  
                  <Divider type="vertical" className={styles.actionsDivider} />
                  
                  <Popconfirm
                    title="Подтверждение сброса"
                    description={`Вы действительно хотите сбросить форму загрузки?`}
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={(e) => {
                      handleButtonReset();
                    }}
                    onCancel={(e) => e.stopPropagation()}
                  >
                    <Button 
                      icon={<RedoOutlined />}
                      danger
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Сбросить всё
                    </Button>
                  </Popconfirm>
                </Space>
              </div>
            </div>

            <div className={`${styles.uploadInfo} ${styles.discreteInfo}`}>
              <div className={`${styles.infoContent} ${styles.infoContentBordered}`}>
                <span className={styles.infoLabel}>Папок: {info.total_folders}</span>
                <span className={styles.infoSeparator}>•</span>
                <span className={styles.infoLabel}>Файлов: {info.total_files}</span>
                <Button 
                  type="text" 
                  size="small" 
                  icon={isCalculating ? <LoadingOutlined /> : <RedoOutlined />} 
                  className={`${styles.refreshButton} ${isCalculating ? styles.calculatingButton : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    
                    // Генерируем уникальный ID для этого запроса
                    const calculationId = Date.now();
                    setLastCalculationId(calculationId);
                    setIsCalculating(true);
                    
                    // Вызываем функцию пересчета
                    handleCalculateFilesToUpload();
                  }}
                  title="Пересчитать файлы"
                  disabled={isCalculating}
                >
                </Button>
              </div>
            </div>
            
            <div className={styles.actionButtonsWrapper}>
              <Button 
                type="primary"
                icon={<CloudUploadOutlined />}
                onClick={handleStartUpload}
                className={styles.actionButton}
              >
                Начать загрузку
              </Button>
            </div>

          </div>
        </div>
      </div>

      {/* Контент с папками и файлами */}
      <div className={styles.contentContainer}>
        {structure && typeof structure === 'object' && (
          <div className={styles.itemsGrid}>
            {/* Кнопка "Назад" в виде папки */}
            {currentDir !== '/' && (
              <div 
                className={`${styles.itemCard} ${styles.backFolderCard}`}
                onClick={goToParentDirectory}
              >
                <div className={styles.itemIcon}>
                  <ArrowLeftOutlined />
                </div>
                <div className={styles.itemNameContainer}>
                  <div className={styles.itemName}>
                    Назад в {getParentFolderName()}
                  </div>
                </div>
              </div>
            )}
            
            {/* Рендерим папки - их показываем все */}
            {directoryItems.map((item) => (
              <div 
                key={item.path}
                className={`${styles.itemCard} ${styles.folderCard}`}
                onClick={() => handleItemClick(item)}
              >
                {/* Иконка удаления */}
                <Popconfirm
                  title="Подтверждение удаления"
                  description={`Вы действительно хотите удалить папку из формы загрузки?`}
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={(e) => {
                    handleDeleteItem(e, item.path);
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <div onClick={(e) => e.stopPropagation()} className={styles.deleteIconWrapper}>
                    <div className={styles.deleteAction}>
                      <DeleteOutlined 
                        className={`${styles.actionIcon} ${styles.deleteIcon}`}
                      />
                    </div>
                  </div>
                </Popconfirm>
                
                <div className={styles.itemIcon}>
                  <div className={styles.folderIconWrapper}>
                    <FolderOutlined />
                  </div>
                </div>
                
                {/* Название папки с возможностью редактирования */}
                <div className={styles.itemNameContainer}>
                  <div 
                    className={styles.itemName}
                    onClick={(e) => handleClickEditName(item, e)}
                  >
                    {editNameItem && editNameItem.path === item.path ? (
                      <div className={styles.editNameContainer} onClick={(e) => e.stopPropagation()}>
                        <Input
                          ref={inputRef}
                          value={editNameValue}
                          onChange={(e) => setEditNameValue(e.target.value)}
                          onKeyDown={(e) => handleKeyPressEditName(e, item)}
                          onBlur={(e) => handleSaveEditName(e, item.path, editNameValue)}
                          size="small"
                          className={styles.editNameInput}
                          suffix={
                            <Space>
                              <CheckOutlined
                                className={styles.editNameIcon}
                                onClick={(e) => handleSaveEditName(e, item.path, editNameValue)}
                              />
                              <CloseCircleOutlined
                                className={styles.editNameIcon}
                                onClick={handleCancelEditName}
                              />
                            </Space>
                          }
                        />
                      </div>
                    ) : (
                      <span className={styles.itemNameText}>
                        {item.meta.name}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
            
            {/* Рендерим изображения с ограничением */}
            {visibleImageItems.map((item, index) => (
              <div 
                key={`${currentDir}-${item.path}-${index}`}
                className={`${styles.itemCard} ${styles.fileCard} ${selectedItems.includes(item.path) ? styles.selectedItem : ''}`}
                onClick={() => handleItemClick(item)}
              >
                <div 
                  className={styles.itemCheckbox} 
                  onClick={handleCheckboxContainerClick}
                >
                  <Checkbox 
                    checked={selectedItems.includes(item.path)}
                    onChange={(e) => handleCheckboxChange(item, e)}
                  />
                </div>
                
                {/* Иконка удаления */}
                <Popconfirm
                  title="Подтверждение удаления"
                  description={`Вы действительно хотите удалить файл из формы загрузки?`}
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={(e) => {
                    handleDeleteItem(e, item.path);
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <div onClick={(e) => e.stopPropagation()} className={styles.deleteIconWrapper}>
                    <div className={styles.deleteAction}>
                      <DeleteOutlined 
                        className={`${styles.actionIcon} ${styles.deleteIcon}`}
                      />
                    </div>
                  </div>
                </Popconfirm>
                
                <div className={styles.itemIcon}>
                  {item.preview_url ? (
                    <div 
                      className={styles.imagePreview}
                      style={{ 
                        minHeight: '120px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        position: 'relative'
                      }}
                    >
                      {/* Проверяем наличие необходимых параметров перед рендерингом LazyImage */}
                      {item.preview_url && item.mime_type ? (
                        <LazyImage 
                          previewUrl={item.preview_url}
                          mimeType={item.mime_type}
                          alt={item.meta.name || 'Изображение'}
                          className={styles.previewImage}
                          key={`${item.path}-${index}`}
                          // Принудительная загрузка только для первых элементов на новой странице
                          forceLoad={index % 10 < 3} // Загружаем первые 3 элемента каждой "страницы"
                        />
                      ) : (
                        <div style={{ 
                          color: '#ff4d4f', 
                          fontSize: '12px', 
                          textAlign: 'center',
                          padding: '10px'
                        }}>
                          Отсутствуют данные для загрузки изображения
                        </div>
                      )}
                      <div className={styles.imageMetadata}>
                        {/* Контракт с датами */}
                        <div className={styles.metadataRow}>
                          <span className={styles.metadataValue}>
                            {item.meta.contract_number ? `${item.meta.contract_number}` : 'Договор не указан'}
                          </span>
                        </div>
                        
                        {item.meta.contract_date_from || item.meta.contract_date_to ? (
                          <div className={styles.metadataRow}>
                            <span className={styles.metadataValue}>
                              {item.meta.contract_date_from || '?'} — {item.meta.contract_date_to || '?'}
                            </span>
                          </div>
                        ) : null}
                        
                        {/* Теги */}
                        <div className={styles.metadataRow}>
                          <span className={styles.metadataValue}>
                            {item.meta.tags && item.meta.tags.length > 0 
                              ? item.meta.tags.join(', ') 
                              : 'Теги не указаны'
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FileImageOutlined className={styles.fileIcon} />
                  )}
                </div>
                
                {/* Название файла с возможностью редактирования */}
                <div className={styles.itemNameContainer}>
                  <div 
                    className={styles.itemName}
                    onClick={(e) => handleClickEditName(item, e)}
                  >
                    {editNameItem && editNameItem.path === item.path ? (
                      <div className={styles.editNameContainer} onClick={(e) => e.stopPropagation()}>
                        <Input
                          ref={inputRef}
                          value={editNameValue}
                          onChange={(e) => setEditNameValue(e.target.value)}
                          onKeyDown={(e) => handleKeyPressEditName(e, item)}
                          onBlur={(e) => handleSaveEditName(e, item.path, editNameValue)}
                          size="small"
                          className={styles.editNameInput}
                          suffix={
                            <Space>
                              <CheckOutlined
                                className={styles.editNameIcon}
                                onClick={(e) => handleSaveEditName(e, item.path, editNameValue)}
                              />
                              <CloseCircleOutlined
                                className={styles.editNameIcon}
                                onClick={handleCancelEditName}
                              />
                            </Space>
                          }
                        />
                      </div>
                    ) : savingNamePath === item.path || savingBulkItems.includes(item.path) ? (
                      <span className={styles.itemNameText} style={{ color: '#999', borderBottom: 'none' }}>
                        Сохранение...
                      </span>
                    ) : (
                      <span className={styles.itemNameText}>
                        {item.meta.name}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
            
            {/* Кнопка "Показать ещё" */}
            {hasMoreImages && (
              <div 
                className={`${styles.itemCard} ${styles.backFolderCard} ${styles.showMoreCard}`}
                onClick={handleShowMore}
              >
                <div className={styles.itemIcon}>
                  <PlusOutlined />
                </div>
                <div className={styles.itemNameContainer}>
                  <div className={styles.itemName}>
                    Показать ещё ({imageItems.length - displayLimit})
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles.bottomPanel}>
          <div className={styles.sessionInfo}>
            Сессия до: {tokenExpiresAt}
          </div>
          <Popconfirm
            title="Подтверждение выхода"
            description={`Вы действительно хотите выйти и сбросить форму загрузки?`}
            okText="Да"
            cancelText="Нет"
            onConfirm={(e) => {
              handleLogout();
            }}
            onCancel={(e) => e.stopPropagation()}
          >
            <Button 
              icon={<LogoutOutlined />} 
              onClick={(e) => e.stopPropagation()}
              danger={true}
              size="small"
              className={styles.logoutButton}
            >
              Выйти из Яндекс.Диска
            </Button>
          </Popconfirm>
        </div>
      </div>

      {/* Модальное окно для редактирования тегов и лицензий */}
      <Modal
        key={isImagesSettingsModalOpen ? 'open-modal' : 'closed-modal'}
        open={isImagesSettingsModalOpen}
        onCancel={handleCloseImagesSettingsModal}
        closeIcon={true}
        footer={null}
        styles={{ 
          body: { padding: '0', overflow: 'hidden' },
          mask: { background: 'rgba(0, 0, 0, 0.65)' },
          content: { 
            maxHeight: '90vh', 
            minHeight: '472px',
            display: 'flex', 
            flexDirection: 'column' 
          }
        }}
        style={{ top: '5vh' }}
        width={450}
        className={styles.editModal}
      >
        <Row className={styles.modalHeader}>
            <Col className={cn(styles.modalHeaderText)}>
                <h4>Настройки файлов</h4>
            </Col>
        </Row>
        <div className={styles.modalDescription}>Имя и теги определяются автоматически, если соответствующее поле пустое.</div>
        <EditTagsLicenseImageForm 
          imageName={selectedItems.length === 1 ? imageItems.find(item => item.path === selectedItems[0])?.meta.name : null}
          imageContractNumber={selectedItems.length === 1 ? imageItems.find(item => item.path === selectedItems[0])?.meta.contract_number : null}
          imageContractDateFrom={selectedItems.length === 1 ? imageItems.find(item => item.path === selectedItems[0])?.meta.contract_date_from : null}
          imageContractDateTo={selectedItems.length === 1 ? imageItems.find(item => item.path === selectedItems[0])?.meta.contract_date_to : null}
          imageTags={selectedItems.length === 1 ? imageItems.find(item => item.path === selectedItems[0])?.meta.tags : null}
          onSubmit={handleSaveImagesSettings}
          isEditableImage={true}
          isFullForm={true}
        />
      </Modal>
    </div>
  );
};

export default InnerYandexStructure; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Name_input__9eZ0d {
  width: 100%;
  margin-top: 5px;
}

.Name_input_slider__NTgYd {
  width: 100%;
}

.Name_text_wrapper__9EdnJ {
  margin-top: 5px;
}

.Name_text_normal__ybl7O {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
}

.Name_text_light__Yh14f {
  color: rgba(0, 0, 0, 0.85);
}

.Name_editable_text__f1VFq {
  cursor: pointer;
  margin-top: 5px;
  padding: 4px 0;
  font-size: 14px;
}

.Name_editable_text__f1VFq:hover {
  color: #1890ff;
}

.Name_edit_icon__0OM3N {
  margin-left: 8px;
  font-size: 12px;
  opacity: 0.5;
}

.Name_editable_text__f1VFq:hover .Name_edit_icon__0OM3N {
  opacity: 1;
} `, "",{"version":3,"sources":["webpack://./src/components/Name/Name.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".input {\n  width: 100%;\n  margin-top: 5px;\n}\n\n.input_slider {\n  width: 100%;\n}\n\n.text_wrapper {\n  margin-top: 5px;\n}\n\n.text_normal {\n  font-size: 14px;\n  font-weight: normal;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.text_light {\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.editable_text {\n  cursor: pointer;\n  margin-top: 5px;\n  padding: 4px 0;\n  font-size: 14px;\n}\n\n.editable_text:hover {\n  color: #1890ff;\n}\n\n.edit_icon {\n  margin-left: 8px;\n  font-size: 12px;\n  opacity: 0.5;\n}\n\n.editable_text:hover .edit_icon {\n  opacity: 1;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Name_input__9eZ0d`,
	"input_slider": `Name_input_slider__NTgYd`,
	"text_wrapper": `Name_text_wrapper__9EdnJ`,
	"text_normal": `Name_text_normal__ybl7O`,
	"text_light": `Name_text_light__Yh14f`,
	"editable_text": `Name_editable_text__f1VFq`,
	"edit_icon": `Name_edit_icon__0OM3N`
};
export default ___CSS_LOADER_EXPORT___;

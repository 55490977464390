import { actions } from './actions';

export const reducer = (state, action) => {
  switch (action.type) {

    // AUTHENTICATION

    case actions.login:
      return { ...state, isLoggedIn: true, token: action.payload };

    case actions.logout:
      return { ...state, isLoggedIn: false, token: null };

    case actions.whoam:
      return { ...state, whoam: action.payload };

    // IMAGES

    case actions.setImagesParams:
      return {
        ...state,
        imagesParams: {...state.imagesParams, ...action.payload},
      };

    case actions.setImagesParamsConsole:
      return {
        ...state,
        imagesParamsConsole: {...state.imagesParamsConsole, ...action.payload},
      };

    case actions.discoverTags:
      return {
        ...state,
        discoverTags: action.payload
      };

    case actions.uploadImage:
      return {
        ...state,
        images: [...action.payload, ...state.images]
      };

    case actions.listImages:
      return {
        ...state,
        images: action.payload.images,
        imagesMeta: action.payload.meta,
      };

    case actions.updateImage:
      return {
        ...state,
        images: state.images.map(image =>
          image.id === action.payload.id ? { ...image, ...action.payload, is_updated_album_id: (image.album_id != action.payload.album_id || image.is_updated_album_id) } : image
        )
      };

    case actions.deleteImage:
      return {
        ...state,
        images: state.images.filter(image =>
          image.id !== action.payload.id
        )
      };

    // ALBUMS

    case actions.listAlbums:
      return {
        ...state,
        albums: action.payload
      };

    case actions.createAlbum:
      return {
        ...state,
        albums: [...state.albums, action.payload]
      };

    case actions.updateAlbum:
      return {
        ...state,
        albums: state.albums.map(album =>
          album.id === action.payload.id ? { ...album, ...action.payload } : album
        )
      };

    case actions.deleteAlbum:
      const updatedAlbums = state.albums.map(album => {
        if (album.parent_id === action.payload.id) {
          return { ...album, parent_id: action.payload.parent_id };
        }
        return album;
      });

      const filteredAlbums = updatedAlbums.filter(album => album.id !== action.payload.id);

      const updatedImages = state.images.map(image => {
        if (image.album_id === action.payload.id) {
          return { ...image, album_id: action.payload.parent_id };
        }
        return image;
      });

      return {
        ...state,
        albums: filteredAlbums,
        images: updatedImages
      };

    // USERS

    case actions.listUsers:
      return {
        ...state,
        users: action.payload.users,
        usersMeta: action.payload.meta,
      };

    case actions.createUser:
      return {
        ...state,
        users: [...state.users, action.payload]
      };

    case actions.updateUser:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? { ...user, ...action.payload } : user
        )
      };

    case actions.deleteUser:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload.id),
      };

    // DEFAULT

    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';

import { Button, Input, Modal } from 'antd';

import {
  AlbumsList,
} from '../../components/index.js';

import s from './EditAlbumForm.module.css';

const EditAlbumForm = ({ editingAlbum, albums, activeAlbumId, onClick, onSubmit, onCancel, isOpen }) => {
  const [albumTitle, setAlbumTitle] = useState(editingAlbum?.title || '');

  useEffect(() => {
    setAlbumTitle(editingAlbum?.title);
  }, [editingAlbum])

  const handleSubmit = () => {
    onSubmit(editingAlbum.id, activeAlbumId, albumTitle);
    onCancel();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <AlbumsList
        albums={albums.filter((album) => album.id !== editingAlbum?.id)}
        activeAlbumId={activeAlbumId}
        onClick={onClick}
        editable={false}
        isModal={true}
      />
      <div key={1} className={s.buttons_wrapper}>
        <div className={s.input}>
          <Input
            onChange={(e) => setAlbumTitle(e.target.value)}
            value={albumTitle}
            placeholder='Название альбома'
            size='large'
          />
        </div>
        <div>
          <Button
            key="submit"
            type="primary"
            size='large'
            onClick={handleSubmit}
          >
            Переместить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditAlbumForm;

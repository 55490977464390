import React, { useContext, useState } from 'react';

import { Button, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import {
  AlbumsList,
} from '../../components/index.js';

import {
  CreateAlbumForm,
  EditAlbumForm,
  DeleteAlbumForm,
} from '../../forms/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

import s from './AlbumsContainer.module.css'
import style from '../../pages/Home/Home.module.css'

const AlbumsContainer = ({ albums, activeAlbumId, handleActiveAlbumChange, isManagable }) => {

  const [localActiveAlbumId, setLocalActiveAlbumId] = useState(null);

  const [albumManagingMode, setAlbumManagingMode] = useState(false);
  const [creatingAlbum, setCreatingAlbum] = useState(false);
  const [editingAlbum, setEditingAlbum] = useState(null);
  const [deletingAlbum, setDeletingAlbum] = useState(null);

  const { state, dispatch } = useContext(providerContext);
  const { getAlbums, deleteAlbums, updateAlbums, createAlbums } = getMethods(dispatch);
  const [messageApi, contextHolder] = message.useMessage();

  const handleAlbumClick = (album) => {
    if (album === null) {
      return handleActiveAlbumChange(null);
    }

    if (activeAlbumId === album.id) {
      return handleActiveAlbumChange(album.parent_id || null);
    } else {
      return handleActiveAlbumChange(album.id);
    }
  };

  const handleLocalAlbumClick = (album) => {
    if (album === null) {
      return setLocalActiveAlbumId(null);
    }

    if (localActiveAlbumId === album.id) {
      return setLocalActiveAlbumId(album.parent_id || null);
    } else {
      return setLocalActiveAlbumId(album.id);
    }
  };

  // Create album
  const handleOpenCreateAlbumForm = () => {

    setLocalActiveAlbumId(null);

    // Убрали выбор активного альбома по умолчанию, теперь при создании альбома он выбирается вручную
    // if (albums.some((album) => album.parent_id === activeAlbumId)) {
    //   setLocalActiveAlbumId(activeAlbumId);
    // } else {
    //   setLocalActiveAlbumId(albums.find((album) => album.id === activeAlbumId)?.parent_id || null);
    // }

    setCreatingAlbum(true);
  };

  const handleCreatingAlbumSubmit = (newAlbumTitle) => {
    if (newAlbumTitle.trim() === '') {
      return;
    }

    const newAlbum = {
      title: newAlbumTitle,
      parent_id: localActiveAlbumId,
    };

    if (state.albums.find((album) => album.title === newAlbumTitle && album.parent_id === localActiveAlbumId)) {
      messageApi.open({
        type: 'warning',
        content: 'Альбом уже существует',
        duration: 8,
      });
      return;
    }

    createAlbums(newAlbum);
    setCreatingAlbum(false);
  };

  // Edit album
  const handleOpenEditAlbumForm = (album) => {
    setLocalActiveAlbumId(album.parent_id);
    setEditingAlbum(album);
  };

  const handleEditingAlbumSubmit = (id, parent_id, title) => {
    if (title === '') {
      return;
    }

    updateAlbums(id, {parent_id, title});
    setEditingAlbum(null);
  };

  // Delete
  const handleOpenDeleteAlbumForm = (album) => {
    setDeletingAlbum(album);
  };

  const handleDeletingAlbumSubmit = async (id) => {
    try {
      await deleteAlbums(id);
      if (activeAlbumId === id) {
        handleActiveAlbumChange(null);
      }
      getAlbums();
    } catch (error) {
      console.error('Error deleting album:', error);
    }
    setDeletingAlbum(null);
  };

  return (
    <div className={style.albumsContainer}>
      {contextHolder}
      <AlbumsList
        albums={albums}
        activeAlbumId={activeAlbumId}
        onClick={handleAlbumClick}
        onEdit={handleOpenEditAlbumForm}
        onDelete={handleOpenDeleteAlbumForm}
        editable={albumManagingMode}
        fullHeight={!isManagable}
      />

      { isManagable && (
        <div className={style.albumsControls}>
          { (albums?.length > 0 || albumManagingMode) &&
          <div className={s.button_manage}>
            <Button
              onClick={() => setAlbumManagingMode(!albumManagingMode)}
              type="dashed"
              size='large'
              block
              className={s.button}
            >
              {albumManagingMode ? 'Завершить' : 'Управление альбомами' }
            </Button>
          </div>
          }
          <div className={s.button_create}>
            <Button
              onClick={handleOpenCreateAlbumForm}
              icon={<PlusCircleOutlined />}
              size='large'
              block
              className={s.button}
            >
              Создать альбом
            </Button>
          </div>
        </div> 
      )}
      
      { isManagable && <CreateAlbumForm
        albums={albums}
        activeAlbumId={localActiveAlbumId}
        onClick={handleLocalAlbumClick}
        onSubmit={(id, parent_id, title) => handleCreatingAlbumSubmit(id, parent_id, title)}
        onCancel={() => setCreatingAlbum(false)}
        isOpen={creatingAlbum}
      /> }

      { isManagable && <EditAlbumForm
        editingAlbum={editingAlbum}
        albums={albums}
        activeAlbumId={localActiveAlbumId}
        onClick={handleLocalAlbumClick}
        onSubmit={(id, parent_id, title) => handleEditingAlbumSubmit(id, parent_id, title)}
        onCancel={() => setEditingAlbum(null)}
        isOpen={editingAlbum !== null}
      /> }
      
      { isManagable && <DeleteAlbumForm
        deletingAlbum={deletingAlbum}
        onSubmit={(id) => handleDeletingAlbumSubmit(id)}
        onCancel={() => setDeletingAlbum(null)}
        isOpen={deletingAlbum !== null}
      /> }
    </div>
  );
};

export default AlbumsContainer;

import React from 'react';
import { Button, Typography, Divider } from 'antd';
import { CloudOutlined, LoginOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './InnerYandexAuth.module.css';
import pageStyles from '../../YandexUploadPage.module.css';

const { Title, Paragraph } = Typography;

const InnerYandexAuth = ({ oauth_url }) => {
  return (
    <div className={styles.authContainer}>
      <div className={pageStyles.directoryHeader}>
        <div className={pageStyles.directoryHeaderContent}>
          <div className={pageStyles.headerLeftPart}>
            <span className={pageStyles.yandexDiskLabel}>Яндекс.Диск</span>
            <Divider type="vertical" className={pageStyles.headerDivider} />
            <span className={pageStyles.currentDirPath}>Авторизация</span>
          </div>
          <Link to="/" className={pageStyles.headerHomeLink}>
            <CloseOutlined className={pageStyles.headerHomeIcon} />
          </Link>
        </div>
      </div>

      <div className={styles.authCardWrapper}>
        <div className={styles.authCard}>
          <Title level={3} className={styles.authTitle}>
            Подключение Яндекс.Диска
          </Title>
          
          <div className={styles.authContent}>
            <CloudOutlined className={styles.cloudIcon} />
            
            <Paragraph className={styles.authDescription}>
              Для загрузки файлов из Яндекс.Диска необходима авторизация.
              Приложение получит доступ к файлам только на чтение.
            </Paragraph>
            
            <div className={styles.buttonContainer}>
              <a href={oauth_url} className={styles.authButton}>
                <Button 
                  type="primary" 
                  size="large"
                  icon={<LoginOutlined />}
                  className={styles.oauthButton}
                >
                  Авторизоваться в Яндекс
                </Button>
              </a>
              
              <Link to="/" className={styles.cancelLink}>
                Вернуться назад
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerYandexAuth; 
import React, { useState, useEffect } from 'react';

import { Col, Row, Button } from 'antd';
import { SaveOutlined, EditOutlined } from '@ant-design/icons';

import {
    Tags,
    Name,
    DateComponent,
    Contract,
  } from '../../components/index.js';

const EditTagsLicenseImageForm = ({ onSubmit, isEditableImage = false, isFullForm = false, imageId = null, imageName = null, imageOriginalName = null, imageDate = null, imageContractNumber = null, imageContractDateFrom = null, imageContractDateTo = null, imageTags = null, imageAlbumTags = null }) => {

    const [isEditingImage, setIsEditingImage] = useState(false);

    useEffect(() => {
        setIsEditingImage(isFullForm);
        if (imageId) {
            setName(imageName || '');
            setDate(imageDate || '');
            setContractNumber(imageContractNumber || '');
            setContractDateFrom(imageContractDateFrom || '');
            setContractDateTo(imageContractDateTo || '');
            setTags(imageTags || []);
        }
    }, [imageId]);

    const [name, setName] = useState(imageName || '');
    const [date, setDate] = useState(imageDate || '');
    const [contractNumber, setContractNumber] = useState(imageContractNumber || '');
    const [contractDateFrom, setContractDateFrom] = useState(imageContractDateFrom || '');
    const [contractDateTo, setContractDateTo] = useState(imageContractDateTo || '');
    const [tags, setTags] = useState(imageTags || []);

    const handleSubmit = () => {
        const formData = {
            tags,
            name,
            date,
            contract_number: contractNumber,
            contract_date_from: contractDateFrom,
            contract_date_to: contractDateTo
        };
        if (!isFullForm) {  
            setIsEditingImage(false);
            onSubmit(imageId, formData);
        } else {
            onSubmit(formData);
        }
    };

    const handleChangeTags = (value) => {
        setTags(value);
    };

    const handleNameChange = (value) => {
        setName(value);
    };

    const handleDateChange = (value) => {
        setDate(value);
    };

    const handleContractChange = (values) => {
        setContractNumber(values.contractNumber);
        setContractDateFrom(values.contractDateFrom);
        setContractDateTo(values.contractDateTo);
    };

  return (
    <>
      <Row align="top">
          <Col span={20} style={{ padding: '20px', width: '100%' }}>
              <div style={{ marginBottom: '15px', width: '100%' }}>
                  <Name 
                      name={name} 
                      originalName={imageOriginalName}
                      editable={isEditingImage} 
                      title={true} 
                      isSlider={true} 
                      onChange={handleNameChange} 
                  />
              </div>
              {(!isFullForm) && (<div style={{ marginBottom: '15px', width: '100%' }}>
                  <DateComponent 
                      date={date} 
                      editable={isEditingImage} 
                      title={true} 
                      isSlider={true} 
                      onChange={handleDateChange} 
                  />
              </div>)}
              <div style={{ marginBottom: '15px', width: '100%' }}>
                  <Contract 
                      contractNumber={contractNumber}
                      contractDateFrom={contractDateFrom}
                      contractDateTo={contractDateTo}
                      editable={isEditingImage} 
                      title={true} 
                      isSlider={true} 
                      onChange={handleContractChange} 
                  />
              </div>
              <div>
                  <div style={{ marginTop: '5px' }}><b>Теги:</b></div>
                  <Tags 
                      imageTags={tags} 
                      imageAlbumTags={imageAlbumTags} 
                      isEditingTags={isEditingImage} 
                      expanded={true}
                      handleChangeTags={handleChangeTags} 
                  />
              </div>
          </Col>
          <Col span={4} style={{ padding: '20px', textAlign: 'right' }}>
              {
              isEditableImage && !isFullForm &&
              <div>
                  <Button 
                      type={isEditingImage ? "primary" : "dashed"} 
                      icon={isEditingImage ? <SaveOutlined /> : <EditOutlined />} 
                      onClick={() => isEditingImage ? handleSubmit() : setIsEditingImage(true)} 
                      size="small"
                  />
              </div>
              }
          </Col>
      </Row>

      {/* Кнопка сохранения внизу формы для полного режима */}
      {isFullForm && (
        <div style={{ 
            borderTop: '1px solid #ddd',
            backgroundColor: '#eee',
            padding: '15px 20px',
            marginTop: 'auto',
            display: 'flex',
            gap: '20px',
            position: 'relative',
            zIndex: 10
        }}>
          <Row justify="end" style={{ width: '100%' }}>
            <Button 
              type="primary" 
              icon={<SaveOutlined />} 
              onClick={handleSubmit}
              size="large"
            >
              Сохранить изменения
            </Button>
          </Row>
        </div>
      )}
    </>
  );
};

export default EditTagsLicenseImageForm;

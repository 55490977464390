export const actions = {
  login: "login",
  logout: "logout",
  whoam: "whoam",

  listUsers: "listUsers",
  createUser: "createUser",
  updateUser: "updateUser",
  deleteUser: "deleteUser",

  listAlbums: "listAlbums",
  createAlbum: "createAlbum",
  updateAlbum: "updateAlbum",
  deleteAlbum: "deleteAlbum",

  listImages: "listImages",
  updateImage: "updateImage",
  deleteImage: "deleteImage",
  uploadImage: "uploadImage",
  incrementImageDownloads: "incrementImageDownloads",
  toggleLikeImage: "toggleLikeImage",

  setImagesParams: "setImagesParams",
  setImagesParamsConsole: "setImagesParamsConsole",

  discoverTags: "discoverTags",

  getYandexUploadStatus: "getYandexUploadStatus",
  setYandexUploadMeta: "setYandexUploadMeta",
  startYandexUpload: "startYandexUpload",
  cancelYandexUpload: "cancelYandexUpload",
  resetYandexUpload: "resetYandexUpload",
  logoutYandexUpload: "logoutYandexUpload",
  calculateFilesToUpload: "calculateFilesToUpload",
};

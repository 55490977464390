import React, { useState } from 'react';

import { Form, Divider, Input, Select, Row, Col, Button, Modal } from 'antd';

const CreateUserForm = ({ onSubmit, onCancel, isOpen }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 0,
    password: '',
  });

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        onSubmit(formData);
        onCancel();
        form.resetFields();
      })
      .catch((error) => {
        console.log('Ошибка валидации:', error);
      });
  };

  return (
    <Modal
      centered
      onCancel={() => onCancel()}
      title={<h4 style={{ margin: ' 20px' }}>Создание пользователя</h4>}
      footer={null}
      open={isOpen}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', margin: '00px 0', padding: '0px 20px' }}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          form={form}
          autoComplete="off"
        >
          <Divider style={{ marginTop: '0px' }} />
          <Form.Item
            label="ФИО"
            name="name"
            rules={[{ required: true, message: 'Имя обязательно для заполнения' }]}
          >
            <Input onChange={(e) => handleInputChange('name', e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Электронная почта"
            name="email"
            validateDebounce={300}
            rules={[{ required: true, message: 'Адрес электронной почты обязателен для заполнения' }, { type: 'email', message: 'Введите корректный адрес электронной почты' }]}
          >
            <Input onChange={(e) => handleInputChange('email', e.target.value)} />
          </Form.Item>
          <Form.Item
              label="Уровень доступа"
              name="role"
          >
            <Select
              defaultValue={0}
              onChange={(value) => handleInputChange('role', value)}
              options={[
                {
                  value: 0,
                  label: "Пользователь"
                },
                {
                  value: 1,
                  label: "Менеджер"
                },
                {
                  value: 2,
                  label: "Администратор"
                }
              ]}
            />
          </Form.Item>
          <Divider style={{ marginTop: '0px' }} />
          <Form.Item
            label="Пароль"
            name="password"
            validateDebounce={300}
            rules={[{ required: true, message: 'Пароль обязателен для заполнения' }, {min: 8, message: 'Пароль должен содержать не менее 8 символов'}]}
          >
            <Input.Password onChange={(e) => handleInputChange('password', e.target.value)} />
          </Form.Item>
        </Form>
      </div>
      <Row key={1} style={{ borderTop: '1px solid #ddd', backgroundColor: '#eee', padding: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Col>
          <Button
            size="large"
            onClick={() => onCancel()}
            key={1}
          >
            Отменить
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ marginLeft: '10px', backgroundColor: '#29355c' }}
            key={2}
            onClick={() => handleSubmit()}
          >
            Создать
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateUserForm;

export const actions = {
  login: "login",
  logout: "logout",
  whoam: "whoam",

  listUsers: "listUsers",
  createUser: "createUser",
  updateUser: "updateUser",
  deleteUser: "deleteUser",

  listAlbums: "listAlbums",
  createAlbum: "createAlbum",
  updateAlbum: "updateAlbum",
  deleteAlbum: "deleteAlbum",

  listImages: "listImages",
  updateImage: "updateImage",
  deleteImage: "deleteImage",
  uploadImage: "uploadImage",

  setImagesParams: "setImagesParams",
  setImagesParamsConsole: "setImagesParamsConsole",

  discoverTags: "discoverTags",
};

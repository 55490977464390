import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Form, Input, Modal, Col, Row, Divider } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import providerContext from '../../shared/provider/providerContext';
import { getToken } from '../../shared/api';

import komitet from '../../assets/img/Komitet.png'

const Authentication = () => {

  const { state, dispatch } = useContext(providerContext);
  const navigate = useNavigate();

  const [isInvalidCreditanals, setIsInvalidCreditanals] = useState(false);

  useEffect(() => {
    if (state.isLoggedIn) {
      navigate('/');
    }
  }, [state.isLoggedIn]);

  const handleLogin = async (values) => {
    setIsInvalidCreditanals(false);
    const token = await getToken(values.email, values.password);
    if (token) {
      dispatch({
          type: "login",
          payload: token
      });
    } else {
      setIsInvalidCreditanals(true);
    }
  }
  
  return (
    <div style={{backgroundImage: `url('/img/bg.png')`, opacity: '100%', backgroundSize: '5px' , height: '100vh', width: '100vw', position: 'absolute', content: '', top: 0}}>
      <Modal centered open={true} closeIcon={false} footer={null}>
        <Row style={{width: '100%', justifyContent: 'center', padding: '20px'}}> 
          <Col style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <img src={komitet} style={{height: "55px"}} alt='komitet'/>
              </div>
          </Col>
        </Row>
        <Divider style={{marginTop: '0px'}} />
        { isInvalidCreditanals && <div style={{ margin: '0 20px', marginTop: '20px', padding: '20px', border: '1px solid #ee2250', backgroundColor: '#ffeeee' }}><b>Ваш доступ недействителен.</b> Попробуйте ещё раз или обратитесь к администратору.</div>}
        <Form name="authenticate" style={{ paddingTop: '30px' }} onFinish={handleLogin}>
          <Form.Item name="email" rules={[{ required: true, type: "email", message: 'Пожалуйста введите почту!' }]} style={{ padding: '0px 80px', paddingBottom: '20px' }}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" placeholder="Электронная почта" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста введите пароль!' }]} style={{ padding: '0px 80px', paddingBottom: '30px' }}>
            <Input size="large" prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Пароль" />
          </Form.Item>
          <Row key={1} justify='space-between' style={{width: '100%', borderTop: '1px solid #ddd', backgroundColor: '#eee', padding: '20px', justifyContent: 'center'}}>
            <Col style={{width: '50%'}}>
              <Form.Item style={{marginBottom: 0}}>
                <Button size="large" style={{width: '100%', backgroundColor: '#29355c'}} type="primary" htmlType="submit" className="login-form-button">
                  Войти
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal> 
    </div>
  );
};

export default Authentication;

import React, { useState } from 'react';

import { Button, Input, Modal } from 'antd';

import {
  AlbumsList,
} from '../../components/index.js';

import s from './CreateAlbumForm.module.css';

const CreateAlbumForm = ({ albums, activeAlbumId, onClick, onSubmit, onCancel, isOpen }) => {
  const [albumTitle, setAlbumTitle] = useState('');

  const handleSubmit = () => {
    onSubmit(albumTitle);
    setAlbumTitle('');
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <AlbumsList
        albums={albums}
        activeAlbumId={activeAlbumId}
        onClick={onClick}
        editable={false}
        isModal={true}
      />
      <div key={1} className={s.buttons_wrapper}>
        <div className={s.input}>
          <Input
            onChange={(e) => setAlbumTitle(e.target.value)}
            value={albumTitle}
            placeholder='Название альбома'
            size='large'
          />
        </div>
        <div>
          <Button
            key="submit"
            type="primary"
            size='large'
            onClick={handleSubmit}
          >
            Создать
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAlbumForm;

import React, { useEffect, useRef, useState } from 'react';

import { Input, Space, Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const Tags = ({ imageTags, imageAlbumTags, isEditingTags, onChange }) => {

  const [tags, setTags] = useState(imageTags);
  const [albumTags, setAlbumTags] = useState(imageAlbumTags);

  const [isCreatingNewTag, setIsCreatingNewTag] = useState(false);
  const [creatingTagValue, setCreatingTagValue] = useState('');

  const [editingTagIndex, setEditingTagIndex] = useState(-1);
  const [editingTagValue, setEditingTagValue] = useState('');
  
  const newTagInputRef = useRef(null);
  const editTagInputRef = useRef(null);

  useEffect(() => {
    setTags(imageTags);
  }, [imageTags]);

  useEffect(() => {
    setAlbumTags(imageAlbumTags);
  }, [imageAlbumTags]);

  useEffect(() => {
    if (isEditingTags) {
      onChange(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (isCreatingNewTag) {
      newTagInputRef.current?.focus();
    }
  }, [isCreatingNewTag]);

  useEffect(() => {
    editTagInputRef.current?.focus();
  }, [editingTagIndex]);

  const handleCreatingTagChange = (e) => {
    setCreatingTagValue(e.target.value);
  };

  const handleCreatingTagConfirm = () => {
    if (creatingTagValue && !tags.includes(creatingTagValue)) {
      setTags([...tags, creatingTagValue]);
    }
    setIsCreatingNewTag(false);
    setCreatingTagValue('');
  };

  const handleEditingTagChange = (e) => {
    setEditingTagValue(e.target.value);
  };

  const handleEditingTagConfirm = () => {
    setTags(tags => {
      tags[editingTagIndex] = editingTagValue;
      return [...tags];
    });
    setEditingTagIndex(-1);
    setEditingTagValue('');
  };
  
  const handleDeleteTag = (removingTag) => {
    setTags(tags.filter((tag) => tag !== removingTag));
  };

  const tagInputStyle = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const tagPlusStyle = {
    height: 22,
    borderStyle: 'dashed',
  };

  return(
    <div>
      { isEditingTags ? (

        <Space size={[0, 8]} wrap>

          {tags.map((tag, index) => {

            const isTooLongTag = tag.length > 20;

            if (editingTagIndex === index) {
              return (
                <Input
                  ref={editTagInputRef}
                  key={tag}
                  size="small"
                  style={tagInputStyle}
                  value={editingTagValue}
                  onChange={handleEditingTagChange}
                  onBlur={handleEditingTagConfirm}
                  onPressEnter={handleEditingTagConfirm}
                />
              );
            }

            return(
              <Tooltip title={isTooLongTag ? tag : ''} key={tag}>
                <Tag
                  key={tag}
                  closable={true}
                  style={{
                    userSelect: 'none',
                  }}
                  onClose={() => handleDeleteTag(tag)}
                >
                  <span
                    onDoubleClick={(e) => {
                      setEditingTagIndex(index);
                      setEditingTagValue(tag);
                      e.preventDefault();
                    }}
                  >
                    {isTooLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              </Tooltip>
            );
            
          })}
          
          {isCreatingNewTag ? (
            <Input
              ref={newTagInputRef}
              type="text"
              size="small"
              style={tagInputStyle}
              value={creatingTagValue}
              onChange={handleCreatingTagChange}
              onBlur={handleCreatingTagConfirm}
              onPressEnter={handleCreatingTagConfirm}
            />
          ) : (
            <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={() => setIsCreatingNewTag(true)}>
              Новый тег
            </Tag>
          )}
        </Space>





      ) : (

        <Space size={[0, 8]} wrap>
          {albumTags && albumTags.map((tag, index) => {

            const isTooLongTag = tag.name.length > 20;

            return (
              <Tooltip title={isTooLongTag ? tag.name : ''} key={tag.name}>
                <Tag key={tag.name} bordered={tag.tag_type == 'album' ? false : true} color="red">{tag.name}</Tag>
              </Tooltip>
            )
          })}
          {tags && tags.map((tag, index) => {

            const isTooLongTag = tag.length > 20;

            return (
              <Tooltip title={isTooLongTag ? tag : ''} key={tag}>
                <Tag key={index} bordered={true}>{isTooLongTag ? `${tag.slice(0, 20)}...` : tag}</Tag>
              </Tooltip>
            )
          })}
          {(!albumTags || albumTags.length === 0) && (!tags || tags.length === 0) && <span style={{ color: '#ccc' }}>Теги для фотографии не указаны</span>}
        </Space>

      )}
      
    </div>
  );
}

export default Tags;

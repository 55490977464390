import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Row, Space, Table, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';

import {
  CreateUserForm,
  EditUserForm,
  DeleteUserForm,
} from '../../forms/index.js';
import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

import s from './Users.module.css'

const Users = () => {
  const { state, dispatch } = useContext(providerContext);
  const { getUsers, createUser, updateUser, deleteUser } = getMethods(dispatch);

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    setUsers(state.users);
  }, [state.users])

  useEffect(() => {
    setUsersMeta(state.usersMeta);
  }, [state.usersMeta])

  useEffect(() => {
    const fetchData = async () => {
      try {
        getUsers(page);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [page]);

  const [creatingUser, setCreatingUser] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);

  // Create user
  const handleOpenCreateUserForm = () => {
    setCreatingUser(true);
  };
  const handleCreatingUserSubmit = (form) => {
    createUser(form);
    setCreatingUser(false);
  };

  // Edit user
  const handleOpenEditUserForm = (user) => {
    setEditingUser(user);
  };
  const handleEditingUserSubmit = (id, form) => {
    updateUser(id, form);
    setEditingUser(null);
  };

  // Delete user
  const handleOpenDeleteUserForm = (user) => {
    setDeletingUser(user);
  };
  const handleDeletingUserSubmit = (id) => {
    deleteUser(id);
    setDeletingUser(null);
  };

  const columns = [
    {
      title: 'Имя', dataIndex: 'name', key: 'name', width: 400,
    },
    {
      title: 'Уровень доступа', dataIndex: 'role', key: 'role', width: 180,
      render: (text) => (text === 2) ? <b style={{ color: '#ee2250' }}>Администратор</b> : (text === 1) ? <b>Менеджер</b> : <span>Пользователь</span>,
    },
    {
      title: 'Email', dataIndex: 'email', key: 'email',
    },
    {
      title: 'Количество авторизаций', dataIndex: 'login_count', key: 'login_count', width: 180,
      render: (text) => text || 0,
    },
    {
      title: 'Действия', key: 'action', fixed: 'right', width: 115,
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => handleOpenEditUserForm(record)}
            icon={<EditOutlined />}
            key="list-loadmore-edit"
            shape="circle"
          />
          <Button
            onClick={() => handleOpenDeleteUserForm(record)}
            icon={<DeleteOutlined />}
            danger
            key="list-loadmore-more"
            shape='circle'
          />
        </Space>
      ),
    },
  ];

  const skeletonCount = 10;
  const skeletonUsers = Array.from({ length: skeletonCount }, (_, i) => ({ id: i }));
  const skeletonColumns = [
    {
      title: 'Имя', dataIndex: 'id', key: 'name', width: 400,
        render: (text) => (
          <Skeleton.Input style={{ width: '150px', height: '15px' }} size="small" />
        ),
      },
      {
        title: 'Уровень доступа', dataIndex: 'id', key: 'role', width: 180,
          render: (text) => (
            <Skeleton.Input style={{ width: '90px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'Email', dataIndex: 'id', key: 'email',
          render: (text) => (
            <Skeleton.Input style={{ width: '200px', height: '15px' }} size="small" />
          ),
      },
      {
        title: 'Количество авторизаций', dataIndex: 'id', key: 'login_count', width: 180,
        render: (text) => (
          <Skeleton.Input style={{ width: '50px', height: '15px' }} size="small" />
        ),
      },
      {
        title: 'Действия', key: 'action', fixed: 'right', width: 115,
        render: (_, record) => (
          <Space size="middle">
            <Skeleton.Button size="small" shape="circle" />
            <Skeleton.Button size="small" shape="circle" />
          </Space>
        ),
      },
  ];

  const customLocale = {
    emptyText: 'Нет данных',
  };

  return (
    <div>
      <div className={s.user_header}>
        <div>
          <Row gutter={[40, 20]}>
            <Col className={s.user_total}>
              <b>Всего пользователей:</b>
              {usersMeta?.total !== undefined
                ?
                (usersMeta?.total)
                :
                <SyncOutlined spin />
              }
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[40, 20]}>
            <Col className={s.user_btn}>
              <Button
              size="large"
              icon={<PlusCircleOutlined/>}
              onClick={() => handleOpenCreateUserForm(true)}
              >
               Создать нового
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <Table
        columns={users ? columns : skeletonColumns}
        dataSource={users ? users : skeletonUsers}
        rowKey="id"
        locale={customLocale}
        scroll={{ x: 768 }}
        tableLayout='auto'
        bordered
        className={s.user_table}
        pagination={{
          onChange: handlePageChange,
          total: usersMeta?.total,
          pageSize: usersMeta?.per_page,
          current: usersMeta?.current_page,
          defaultCurrent: 1,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
      />

      <CreateUserForm
        onSubmit={handleCreatingUserSubmit}
        onCancel={() => setCreatingUser(false)}
        isOpen={creatingUser}
      />

      <EditUserForm
        editingUser={editingUser}
        onSubmit={handleEditingUserSubmit}
        onCancel={() => setEditingUser(null)}
        isOpen={editingUser !== null}
      />

      <DeleteUserForm
        deletingUser={deletingUser}
        onSubmit={(id) => handleDeletingUserSubmit(id)}
        onCancel={() => setDeletingUser(null)}
        isOpen={deletingUser !== null}
      />
    </div>
  );
};

export default Users;

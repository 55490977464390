import React, { useContext, useState } from 'react';
import providerContext from '../../shared/provider/providerContext';

import styles from './YandexUploadPage.module.css';
import { getMethods } from '../../shared/provider/methods';
import { actions } from '../../shared/provider/reducer/actions';
import InnerYandexStructure from './components/InnerYandexStructure/InnerYandexStructure';
import InnerYandexAuth from './components/InnerYandexAuth/InnerYandexAuth';
import InnerYandexLoading from './components/InnerYandexLoading/InnerYandexLoading';

const YandexUploadPage = () => {

  const { state, dispatch } = useContext(providerContext);
  const { getYandexUploadStatus, setYandexUploadMeta, startYandexUpload, cancelYandexUpload, resetYandexUpload, logoutYandexUpload, calculateFilesToUpload } = getMethods(dispatch);
  const [currentDirectory, setCurrentDirectory] = useState('/');
  const [isLoading, setIsLoading] = useState(false);

  const handleCalculateFilesToUpload = () => {
    calculateFilesToUpload(currentDirectory);
  };

  const handleSelectDirectory = async (path) => {
    setIsLoading(true);
    
    try {
      await getYandexUploadStatus(path);
      
      setCurrentDirectory(path);
    } catch (error) {
      console.error("Ошибка при получении структуры директории:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateStatus = () => {
    getYandexUploadStatus(currentDirectory);
  };

  const handleSetMeta = (elements, meta) => {
    return setYandexUploadMeta(currentDirectory, elements, meta)
      .then(response => {
        return getYandexUploadStatus(currentDirectory)
          .then(() => response);
      });
  };

  const handleStartUpload = () => {
    startYandexUpload(currentDirectory);
  };

  const handleCancelUpload = () => {
    cancelYandexUpload(currentDirectory);
  };

  const handleReset = () => {
    dispatch({
      type: actions.resetYandexUpload,
      payload: {
        status: 'SCANNING_DISK_STRUCTURE',
      },
    });
    resetYandexUpload();
  };

  const handleLogout = () => {
    logoutYandexUpload();
  };

  // Рендеринг компонентов в зависимости от статуса
  if (state.yandexUpload.status === 'NO_TOKEN') {
    return (
      <div className={styles.authWrapper}>
        <InnerYandexAuth oauth_url={state.yandexUpload.oauth_url} />
      </div>
    );
  }

  if (state.yandexUpload.status === 'SCANNING_DISK_STRUCTURE' || state.yandexUpload.status === 'CHECKING_COMPLETION') {
    return (
      <InnerYandexLoading 
        yandexUploadData={state.yandexUpload}
        handleUpdateStatus={handleUpdateStatus}
        handleLogout={handleLogout}
      />
    );
  }

  if (state.yandexUpload.status === 'READY_TO_PROCESS') {
    return (
      <InnerYandexStructure 
        structure={state.yandexUpload.structure} 
        info={state.yandexUpload.info}
        currentDir={currentDirectory}
        tokenExpiresAt={state.yandexUpload.token_expires_at}
        handleCalculateFilesToUpload={handleCalculateFilesToUpload}
        handleSelectDirectory={handleSelectDirectory}
        handleSetMeta={handleSetMeta}
        handleStartUpload={handleStartUpload}
        handleReset={handleReset}
        handleLogout={handleLogout}
        isLoading={isLoading}
      />
    );
  }

  if (state.yandexUpload.status === 'PLANNING_UPLOAD' || state.yandexUpload.status === 'UPLOADING_FILES') {
    return (
      <InnerYandexLoading 
        yandexUploadData={state.yandexUpload}
        handleUpdateStatus={handleUpdateStatus}
        handleCancelUpload={handleCancelUpload}
      />
    );
  }

  if (state.yandexUpload.status === 'UPLOAD_COMPLETED') {
    return (
      <InnerYandexLoading 
        yandexUploadData={state.yandexUpload}
        handleUpdateStatus={handleUpdateStatus}
        handleReset={handleReset}
      />
    );
  }

  return (
    <InnerYandexLoading
      yandexUploadData={state.yandexUpload}
      handleUpdateStatus={handleUpdateStatus}
      handleLogout={handleLogout}
    />
  );
};

export default YandexUploadPage; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteImageForm_modal__XgaVX {
	padding: 20px;
}

.DeleteImageForm_title__bKzT7 {
	margin: 0 0 20px;
}

.DeleteImageForm_wrapper__oDMw0 {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/forms/DeleteImageForm/DeleteImageForm.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,yBAAyB;CACzB,SAAS;AACV","sourcesContent":[".modal {\n\tpadding: 20px;\n}\n\n.title {\n\tmargin: 0 0 20px;\n}\n\n.wrapper {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tgap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DeleteImageForm_modal__XgaVX`,
	"title": `DeleteImageForm_title__bKzT7`,
	"wrapper": `DeleteImageForm_wrapper__oDMw0`
};
export default ___CSS_LOADER_EXPORT___;

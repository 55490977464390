// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditAlbumIdImageForm_buttons_wrapper__kdHsM {
	border-top: 1px solid #ddd;
	background-color: #eee;
	padding: 20px;
	margin-top: 0;
	display: flex;
    justify-content: flex-end;
	gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/forms/EditAlbumIdImageForm/EditAlbumIdImageForm.module.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,sBAAsB;CACtB,aAAa;CACb,aAAa;CACb,aAAa;IACV,yBAAyB;CAC5B,SAAS;AACV","sourcesContent":[".buttons_wrapper {\n\tborder-top: 1px solid #ddd;\n\tbackground-color: #eee;\n\tpadding: 20px;\n\tmargin-top: 0;\n\tdisplay: flex;\n    justify-content: flex-end;\n\tgap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons_wrapper": `EditAlbumIdImageForm_buttons_wrapper__kdHsM`
};
export default ___CSS_LOADER_EXPORT___;

export const endpoints = {
  authenticate: {
    token: '/api/token',
    whoam: '/api/whoam',
  },
  ftp: {
    info: '/api/ftp',
    check: '/api/ftp',
  },
  users: {
    list: (page) => `/api/users?page=${page}`,
    create: '/api/users',
    update: (id) => `/api/users/${id}`,
    delete: (id) => `/api/users/${id}`,
  },
  albums: {
    list: '/api/albums',
    create: '/api/albums',
    update: (id) => `/api/albums/${id}`,
    delete: (id) => `/api/albums/${id}`,
  },
  tags: {
    discover: '/api/tags',
  },
  images: {
    list: '/api/images',
    update: (id) => `/api/images/${id}`,
    delete: (id) => `/api/images/${id}`,
    upload: '/api/images',
    rethumbnailing: '/api/images/rethumbnailing',
    retagging: '/api/images/retagging',
    albumRetagging: '/api/images/album-retagging',
  },
}

import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import { QuestionCircleOutlined, StopOutlined, ExclamationCircleOutlined, CheckSquareOutlined, GlobalOutlined } from '@ant-design/icons';

import cn from 'classnames';
import s from './License.module.css';

const License = ({ license, editable, title, isCard, isSlider, onChange }) => {
  const { Option } = Select;

  const [selectedLicense, onLicenseChange] = useState(license ? license : '')

  const licenseList = [
    {
      value: 'unknown',
      label:
        <span>
          <QuestionCircleOutlined />
          {' Лицензия не указана'}
        </span>,
      status:
        <span>
          {'Лицензия не указана '}
          <QuestionCircleOutlined />
        </span>,
    },
    {
      value: 'restricted',
      label:
        <span>
          <StopOutlined style={{ color: '#ee2250' }} />
          {' Использование запрещено'}
        </span>,
      status:
        <span>
          {'Использование запрещено '}
          <StopOutlined style={{ color: '#ee2250' }} />
        </span>,
    },
    {
      value: 'limited',
      label:
        <span>
          <ExclamationCircleOutlined style={{ color: '#dcb400' }} />
          {' Ограниченное использование'}
        </span>,
      status:
        <span>
          {'Ограниченное использование '}
          <ExclamationCircleOutlined style={{ color: '#dcb400' }} />
        </span>,
    },
    {
      value: 'allowed',
      label:
        <span>
          <CheckSquareOutlined style={{ color: '#66cc66' }} />
          {' Использование без ограничений'}
        </span>,
      status:
        <span>
          {'Использование без ограничений '}
          <CheckSquareOutlined style={{ color: '#66cc66' }} />
        </span>,
    },
    {
      value: 'public',
      label:
        <span>
          <GlobalOutlined style={{ color: '#29355c' }} />
          {' Общественное достояние'}
        </span>,
      status:
        <span>
          {'Общественное достояние '}
          <GlobalOutlined style={{ color: '#29355c' }} />
        </span>,
    }
  ];

  const handleLicenseChange = (value) => {
    onLicenseChange(value || '');
    onChange(value);
  };

  useEffect(() => {
    onLicenseChange(license || '')
  }, [license])

  return (
    <div>
      { title &&
        <div>
          <b>Лицензия:</b>
        </div>
      }
      { editable ?
        <Select
          size={ isSlider ? 'small' : 'large' }
          placeholder='Лицензия'
          value={selectedLicense}
          className={cn( s.select, {[s.select_slider]: isSlider })}
          onChange={handleLicenseChange}
          options={ isSlider ? licenseList : [{
            value: '',
            label: 'Не выбрано',
          }, ...licenseList] }
        />
        :
        isCard ?
          <div className={s.text_wrapper}>
            <h4 className={s.text}>
              <div className={s.text_light} style={ selectedLicense == 'unknown' ? {color: '#ccc'} : {}}>
                {licenseList.find(item => item.value === selectedLicense)?.status}
              </div>
            </h4>
          </div>
          :
          <div style={ selectedLicense == 'unknown' ? {color: '#ccc'} : {}}>{licenseList.find(item => item.value === selectedLicense)?.status}</div>
      }
    </div>
  );
};

export default License;


import React, { useEffect, useState } from 'react';

import { Form, Divider, Input, Select, Row, Col, Button, Modal } from 'antd';

const EditUserForm = ({ editingUser, onSubmit, onCancel, isOpen }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 0,
    password: '',
  });

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const formDataToSend = { ...formData };
        delete formDataToSend.id;
        if (!formData.password) {
          delete formDataToSend.password;
        }

        onSubmit(formData.id, formDataToSend);
        onCancel();
        setFormData({
          name: '',
          email: '',
          role: 0,
          password: '',
        });
      })
      .catch((error) => {
        console.log('Ошибка валидации:', error);
      });
  };

  useEffect(() => {
    if (editingUser) {
      setFormData(editingUser);
      form.setFieldsValue(editingUser);
    } else {
      form.resetFields();
    }
  }, [editingUser, form]);

  return (
    <Modal
        centered
        onCancel={() => onCancel()}
        open={isOpen}
        title={<h4 style={{margin: ' 20px'}}>Редактирование пользователя</h4>}
        footer={null}
      >
        <div style={{display: 'flex', flexDirection: 'column', gap: '0px', margin: '00px 0', padding: '0px 20px'}}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
          >
            <Divider style={{ marginTop: '0px' }} />
            <Form.Item
              label="ФИО"
              name="name"
              validateDebounce={300}
              rules={[{ required: true, message: 'Имя обязательно для заполнения' }]}
            >
              <Input value={formData?.name} onChange={(e) => handleInputChange('name', e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Электронная почта"
              name="email"
              validateDebounce={300}
              rules={[{ required: true, message: 'Адрес электронной почты обязателен для заполнения' }, { type: 'email', message: 'Введите корректный адрес электронной почты' }]}
            >
              <Input value={formData?.email} onChange={(e) => handleInputChange('email', e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Уровень доступа"
              name="role"
            >
              <Select
                value={formData?.role}
                onChange={(value) => handleInputChange('role', value)}
                options={[
                  {
                    value: 0,
                    label: "Пользователь"
                  },
                  {
                    value: 1,
                    label: "Менеджер"
                  },
                  {
                    value: 2,
                    label: "Администратор"
                  }
                ]}
              />
            </Form.Item>
            <Divider style={{ marginTop: '0px' }} />
            <div style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #eee' }}>
              Оставьте поле <b>пароля</b> пустым, чтобы оставить его без изменения.
            </div>
            <Form.Item
              label="Пароль"
              name="password"
              validateTrigger={['onChange', 'onBlur']}
              validateDebounce={300}
              validateFirst={true}
              rules={[
                {
                  validator: async (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value.length < 8) {
                      return Promise.reject('Пароль должен содержать не менее 8 символов');
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password onChange={(e) => handleInputChange('password', e.target.value)} />
            </Form.Item>
          </Form>
        </div>
        <Row key={1} style={{borderTop: '1px solid #ddd', backgroundColor: '#eee', padding: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
          <Col>
            <Button
              size="large"
              onClick={() => onCancel()}
              key={1}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              size="large"
              style={{marginLeft: '10px', backgroundColor: '#29355c'}}
              key={2}
              onClick={() => handleSubmit()}
            >
              Редактировать
            </Button>
          </Col>
        </Row>
      </Modal>
  );
};

export default EditUserForm;

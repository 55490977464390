import React, { useEffect, useReducer } from 'react';

import ProviderContext from './providerContext';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/initialState';

const Provider = ({children}) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.token) {
      localStorage.setItem('token', state.token);
    } else {
      localStorage.removeItem('token');
    }
  }, [state.token]);

  return (
    <ProviderContext.Provider value={{ state, dispatch }}>
      {children}
    </ProviderContext.Provider>
  );
};

export default Provider;

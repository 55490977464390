import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Input, Space, Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import style from '../../pages/Home/Home.module.css';

const EditableTagsSection = ({ 
  tags, 
  setTags, 
  handleChangeTags 
}) => {
  
  const [isCreatingNewTag, setIsCreatingNewTag] = useState(false);
  const [creatingTagValue, setCreatingTagValue] = useState('');
  const [editingTagIndex, setEditingTagIndex] = useState(-1);
  const [editingTagValue, setEditingTagValue] = useState('');
  
  const newTagInputRef = useRef(null);
  const editTagInputRef = useRef(null);

  useEffect(() => {
    if (isCreatingNewTag) {
      newTagInputRef.current?.focus();
    }
  }, [isCreatingNewTag]);

  useEffect(() => {
    if (editingTagIndex >= 0) {
      editTagInputRef.current?.focus();
    }
  }, [editingTagIndex]);

  useEffect(() => {
    handleChangeTags(tags);
  }, [tags, handleChangeTags]);

  const handleCreatingTagChange = (e) => {
    setCreatingTagValue(e.target.value);
  };

  const handleCreatingTagConfirm = () => {
    if (creatingTagValue && !tags.includes(creatingTagValue)) {
      setTags([...tags, creatingTagValue]);
    }
    setIsCreatingNewTag(false);
    setCreatingTagValue('');
  };

  const handleEditingTagChange = (e) => {
    setEditingTagValue(e.target.value);
  };

  const handleEditingTagConfirm = () => {
    setTags(tags => {
      const newTags = [...tags];
      newTags[editingTagIndex] = editingTagValue;
      return newTags;
    });
    setEditingTagIndex(-1);
    setEditingTagValue('');
  };
  
  const handleDeleteTag = (removingTag) => {
    setTags(tags.filter((tag) => tag !== removingTag));
  };

  const tagInputStyle = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const tagPlusStyle = {
    height: 22,
    borderStyle: 'dashed',
  };

  return (
    <Space size={[0, 8]} wrap>
      {tags.map((tag, index) => {
        const isTooLongTag = tag.length > 20;

        if (editingTagIndex === index) {
          return (
            <Input
              ref={editTagInputRef}
              key={tag}
              size="small"
              style={tagInputStyle}
              value={editingTagValue}
              onChange={handleEditingTagChange}
              onBlur={handleEditingTagConfirm}
              onPressEnter={handleEditingTagConfirm}
            />
          );
        }

        return(
          <Tooltip title={isTooLongTag ? tag : ''} key={tag}>
            <Tag
              key={tag}
              closable={true}
              style={{
                userSelect: 'none',
              }}
              onClose={() => handleDeleteTag(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  setEditingTagIndex(index);
                  setEditingTagValue(tag);
                  e.preventDefault();
                }}
              >
                {isTooLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          </Tooltip>
        );
      })}
      
      {isCreatingNewTag ? (
        <Input
          ref={newTagInputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={creatingTagValue}
          onChange={handleCreatingTagChange}
          onBlur={handleCreatingTagConfirm}
          onPressEnter={handleCreatingTagConfirm}
        />
      ) : (
        <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={() => setIsCreatingNewTag(true)}>
          Новый тег
        </Tag>
      )}
    </Space>
  );
};

const ViewTagsSection = ({ 
  tags, 
  albumTags, 
  expanded = false,
  handleExpandNeeded,
  handleTagClick
}) => {
  
  const [internalExpanded, setInternalExpanded] = useState(expanded);
  const [needsExpansion, setNeedsExpansion] = useState(false);
  const [needsScrolling, setNeedsScrolling] = useState(false);
  
  const tagsContainerRef = useRef(null);
  
  const fullViewMode = expanded && !handleExpandNeeded;
  const tagsClickable = !!handleTagClick;

  useEffect(() => {
    setInternalExpanded(expanded);
    
    if (!expanded && tagsContainerRef.current) {
      tagsContainerRef.current.scrollTop = 0;
    }
  }, [expanded]);
  
  useEffect(() => {
    if (fullViewMode) {
      setNeedsExpansion(false);
      setInternalExpanded(true);
      return;
    }
    
    if (tagsContainerRef.current) {
      const container = tagsContainerRef.current;
      const content = container.children[0];
      if (content) {
        const contentHeight = content.scrollHeight;
        const needsExp = contentHeight > 90;
        const needsScroll = contentHeight > 330;
        
        setNeedsExpansion(needsExp);
        setNeedsScrolling(needsScroll);
        
        if (handleExpandNeeded) {
          handleExpandNeeded(needsExp);
        }
      }
    }
  }, [tags, albumTags, fullViewMode, handleExpandNeeded]);

  const onTagClickInternal = useCallback((tagName, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    
    if (handleTagClick) {
      handleTagClick(tagName);
    }
  }, [handleTagClick]);
  
  const containerStyle = {
    overflow: fullViewMode ? 'visible' : (internalExpanded ? (needsScrolling ? 'auto' : 'visible') : 'hidden'),
    position: 'relative',
    transition: 'none',
    maskImage: 'none',
    WebkitMaskImage: 'none',
    margin: 0,
    padding: 0,
    opacity: 1,
    maxHeight: fullViewMode ? 'none' : (internalExpanded ? (needsScrolling ? '270px' : 'none') : '90px')
  };

  return (
    <div style={{ 
      position: 'relative',
      margin: 0,
      padding: 0,
      minHeight: needsExpansion ? '64px' : 'auto'
    }}>
      <div 
        ref={tagsContainerRef} 
        style={containerStyle} 
        className={`${fullViewMode ? style.tagsContainerSlider : style.tagsContainer} ${internalExpanded ? style.expanded : ''}`}
      >
        <Space size={[0, 8]} wrap>
          {albumTags && albumTags.map((tag, index) => {
            const isTooLongTag = tag.name.length > 20;
            return (
              <Tooltip title={isTooLongTag ? tag.name : ''} key={tag.name}>
                <Tag 
                  bordered={true}
                  style={{ cursor: tagsClickable ? 'pointer' : 'default' }}
                  onClick={tagsClickable ? (e) => onTagClickInternal(tag.name, e) : undefined}
                >
                  {tag.name}
                </Tag>
              </Tooltip>
            );
          })}
          {tags && tags.map((tag, index) => {
            const isTooLongTag = tag.length > 20;
            return (
              <Tooltip title={isTooLongTag ? tag : ''} key={tag}>
                <Tag 
                  bordered={true}
                  style={{ cursor: tagsClickable ? 'pointer' : 'default' }}
                  onClick={tagsClickable ? (e) => onTagClickInternal(tag, e) : undefined}
                >
                  {isTooLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              </Tooltip>
            );
          })}
          {(!albumTags || albumTags.length === 0) && (!tags || tags.length === 0) && 
            <span style={{ color: '#ccc' }}>Теги для фотографии не указаны</span>
          }
        </Space>
      </div>
    </div>
  );
};

const Tags = ({ 
  imageTags = [], 
  imageAlbumTags = [], 
  isEditingTags = false, 
  expanded = false,
  handleExpandNeeded = null,
  handleTagClick = null, 
  handleChangeTags = () => {}
}) => {
  
  const [tags, setTags] = useState(imageTags);
  const [albumTags, setAlbumTags] = useState(imageAlbumTags);

  useEffect(() => {
    setTags(imageTags);
  }, [imageTags]);

  useEffect(() => {
    setAlbumTags(imageAlbumTags);
  }, [imageAlbumTags]);

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {isEditingTags ? (
        <EditableTagsSection 
          tags={tags}
          setTags={setTags}
          handleChangeTags={handleChangeTags}
        />
      ) : (
        <ViewTagsSection 
          tags={tags} 
          albumTags={albumTags} 
          expanded={expanded}
          handleExpandNeeded={handleExpandNeeded}
          handleTagClick={handleTagClick}
        />
      )}
    </div>
  );
};

export default Tags;

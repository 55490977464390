import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';

import {
  AlbumsList,
} from '../../components/index.js';

import s from './EditAlbumIdImageForm.module.css';

const EditAlbumIdImageForm = ({ editingImage, albums, onSubmit, onCancel, isOpen }) => {
    const [newAlbumId, setNewAlbumId] = useState(editingImage?.album_id || null);
    const [hasNewAlbumId, setHasNewAlbumId] = useState(false);

    useEffect(() => {
        setNewAlbumId(editingImage?.album_id || null);
    }, [editingImage])

    useEffect(() => {
        if (editingImage && editingImage?.album_id != newAlbumId) {
            setHasNewAlbumId(true);
        } else {
            setHasNewAlbumId(false);
        }
    }, [newAlbumId])

    const handleSubmit = () => {
        if (hasNewAlbumId) {
            const formData = {
                album_id: newAlbumId,
            };
            onSubmit(editingImage.id, formData);
        }
        onCancel();
    };

    const handleAlbumClick = (album) => {
        if (album === null) {
            return setNewAlbumId(null);
        }

        if (newAlbumId === album.id) {
            return setNewAlbumId(album.parent_id || null);
        } else {
            return setNewAlbumId(album.id);
        }
    }

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <AlbumsList
        albums={albums}
        activeAlbumId={newAlbumId}
        onClick={handleAlbumClick}
        editable={false}
        isModal={true}
      />
      <div key={1} className={s.buttons_wrapper}>
        <div>
          <Button
            key="submit"
            type={ hasNewAlbumId ? 'primary' : 'default' }
            size='large'
            onClick={handleSubmit}
          >
            { hasNewAlbumId ? 'Переместить' : 'Отменить' }
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditAlbumIdImageForm;

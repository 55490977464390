import { Col, Row, Skeleton, Card } from 'antd';

const ImagesListSkeleton = ({ count }) => {

    const renderSkeletons = (count) => {
        const skeletons = [];
        for (let i = 0; i < count; i++) {
          skeletons.push(
              <Col key={i} xs={24} sm={24} md={24} lg={12} xl={8}>
              <Card
                hoverable
                style={{ display: 'flex', flexDirection: 'column', height: '100%', borderBottom: '10px', borderTopLeftRadius: '19px', borderTopRightRadius: '19px' }}
                cover={
                  <div key={i} style={{ width: 'auto', height: '197px', borderTopLeftRadius: '19px', borderTopRightRadius: '19px', borderBottom: '3px solid #999', position: 'relative', overflow: 'hidden' }}>
                    <Skeleton.Image
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        padding: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '0px'
                      }}
                    />
                  </div>
                }
                bodyStyle={{ padding: '0', height: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                >
                  <Row style={{ flex: 1, padding: '8px 15px 4px 15px', fontSize: '12px', color: '#888' }}>
                      <Col style={{ display: 'flex', justifyContent: 'left', paddingRight: '20px' }}>
                        <Skeleton.Input style={{ width: '50px', height: '12px' }} size="small" />
                      </Col>
                      <Col style={{ flex: 1, display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
                        <Skeleton.Input style={{ width: '150px', height: '12px' }} size="small" />
                      </Col>
                  </Row>
                  <Row style={{ gap: '10px', backgroundColor: '#fff', padding: '15px 15px 15px 15px', borderTop: '0px solid #eee', backgroundColor: '#fff', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                    <Skeleton.Button style={{ height: '20px' }} size="small" />
                  </Row>
                </div>
              </Card>
            </Col>
          );
        }
        return skeletons;
      };

    return (
        <div>
            <Row gutter={[20, 20]} style={{ padding: '20px 10px', margin: 0 }}>
                {renderSkeletons(count)}
            </Row>
        </div>
    );
}

export default ImagesListSkeleton;

import React, { useEffect, useRef, useState, useContext } from 'react';

import { Modal, Row, Col, Button, Typography } from 'antd';
import { DeleteOutlined, DownloadOutlined, NodeIndexOutlined } from '@ant-design/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard, Virtual } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { saveAs } from 'file-saver';

import {
  EditAlbumIdImageForm,
  EditTagsLicenseImageForm,
  DeleteImageForm,
} from '../../forms/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

import styles from './ImagesSlider.module.css';

const ImagesSlider = ({ images, albums, selectedImageIndex, handlerSelectedImageIndex, isOpenedImagesSlider, handlerIsOpenedImagesSlider, isEditableImage }) => {

  const { state, dispatch } = useContext(providerContext);
  const { updateImage, deleteImage } = getMethods(dispatch);

  const selectedImage = images[selectedImageIndex];

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [editingImage, setEditingImage] = useState(null);
  const [deletingImage, setDeletingImage] = useState(null);

  const swiperRef = useRef(null);
  const { Paragraph } = Typography;

  const handleCancel = () => {
    setEditingImage(null);
    handlerIsOpenedImagesSlider(false);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(selectedImageIndex, 0);
    }
  }, [selectedImageIndex, swiperRef]);  

  useEffect(() => {
    if (deletingImage == null && selectedImageIndex == null) {
      handleCancel();
    }
  }, [deletingImage]);

  const handleOpenEditImageForm = (image) => {
    setEditingImage(image);
  };
  const handleEditingImageSubmit = (id, formData) => {
    updateImage(id, formData);
    setEditingImage(null);
  };

  const handleOpenDeleteImageForm = (image) => {
    setDeletingImage(image);
  };
  const handleDeletingImageSubmit = (id) => {
    deleteImage(id);
    handlerSelectedImageIndex(null);
    setDeletingImage(null);
  };

  const handleDownload = async (image) => {
    try {
      setDownloadLoading(true);

      const response = await fetch(image.url_original);
      const blob = await response.blob();
      saveAs(blob, image.original_name);

      setDownloadLoading(false);
    } catch (error) {
      console.error('Error downloading image:', error);
      setDownloadLoading(false);
    }
  };

  return (
    <Modal
      open={isOpenedImagesSlider}
      onCancel={handleCancel}
      className={styles.antModal}
      footer={null}
      transitionName="" 
      maskTransitionName=""
      styles={{ mask: {backgroundColor: '#000', opacity: 0.9 }}}
    >
      <Row className={styles.sliderContainer}>
        <Col className={styles.sliderImage}>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            className={styles.sliderImageSwiper}
            navigation
            keyboard
            modules={[Navigation, Keyboard]}
            initialSlide={selectedImageIndex}
            ref={swiperRef}
            onSlideChange={(swiper) => {
              handlerSelectedImageIndex(swiper.activeIndex);
              setEditingImage(null);
            }}
          >
            {images.map((image, index) => {
              return (
                ( image.url_thumbnail ?
                <SwiperSlide key={index} style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${image.url_fullsize})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      filter: 'blur(10px)',
                      scale: '1.04',
                      zIndex: -1,
                    }}
                  />
                  <img
                    src={image.url_fullsize}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </SwiperSlide>
                :
                <SwiperSlide key={index} style={{ position: 'relative' }}>
                  <div key={index} style={{ color: '#ccc', fontSize: '20px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#666' }}>
                      Обработка изображения...
                  </div>
                </SwiperSlide>
                )
              )
            })}
          </Swiper>
        </Col>
        { selectedImage && (
          <Col className={styles.sliderImageInfo}>
            <div style={{ fontSize: '12px', overflow: 'auto', maxHeight: '100%' }} className={styles.description}>
        
              <div style={{position: 'sticky', top: 0, zIndex: 1000}}>
                <Row style={{ display: 'flex', gap: '15px', padding: '20px', borderBottom: '1px solid #ddd', backgroundColor: '#eee'}}>
                  <Col style={{ padding: 0 }}>
                    <Button type="primary" icon={<DownloadOutlined />} loading={downloadLoading} size="small" onClick={ () => handleDownload(selectedImage) }>Скачать</Button>
                  </Col>
                  <Col>
                    <b style={{ fontSize: '12px', color: '#999' }}>{selectedImage.size}</b>
                  </Col>
                  <Col>
                    <b style={{ fontSize: '12px', color: '#999' }}>{selectedImage.resolution}</b>
                  </Col>
                  <Col>
                    <b style={{ fontSize: '12px', color: '#ccc' }}>id: {selectedImage.id}</b>
                  </Col>
                </Row>
              </div>

              <Row align="middle" style={{ borderBottom: '1px solid #ddd' }}>
                <Col span={20} style={{ padding: '20px' }}>
                  <div>
                    <div><b>Альбом:</b></div>
                    <span>{albums.find((album) => album.id == selectedImage?.album_id)?.title || "Корневой альбом"}</span>  
                  </div>
                </Col>
                <Col span={4} style={{ padding: '20px', textAlign: 'right' }}>
                  {
                    isEditableImage 
                    &&
                    <Button onClick={() => handleOpenEditImageForm(selectedImage)} icon={<NodeIndexOutlined />} size="small"/> 
                  }
                </Col>
              </Row>

              <EditTagsLicenseImageForm image={selectedImage} isEditableImage={isEditableImage} onSubmit={handleEditingImageSubmit} />

              <Row align="middle" style={{ borderTop: '1px solid #ddd' }}>
                <Col style={{ padding: '20px' }}>
                  <div>
                    <div><b>Дата фотографии:</b></div>
                    <span>{selectedImage?.taken_at || "Дата не указана"}</span>
                  </div>
                </Col>
              </Row>

              <Row align="middle" style={{ borderTop: '1px solid #ddd' }}>
                <Col style={{ padding: '20px' }}>
                  <div>
                    <div><b>Время загрузки:</b></div>
                    <span>{selectedImage.created_at} <span style={{ color: '#999' }}> | Изменено {selectedImage.updated_at}</span></span>
                  </div>
                </Col>
              </Row>

              { selectedImage.exif != "[]" && (
                <div>              
                  <div style={{ fontSize: '14px', borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', backgroundColor: '#eee', padding: '10px', marginTop: '20px', textAlign: 'center', color: '#aaa' }}>
                    <b>EXIF-информация</b>
                  </div> 
                  <div style={{ fontSize: '14px', padding: '20px' }}>
                    <Paragraph style={{ margin: '5px 0 5px 0', backgroundColor: '#f5f5dc', fontSize: '12px', color: '#333' }}>
                      <pre style={{ margin: '0px', padding: '20px' }}>
                        <ExifComponent data={JSON.parse(selectedImage.exif)} />
                      </pre>
                    </Paragraph>
                  </div>
                </div>
              )}

              { isEditableImage && (<div style={{ padding: '20px', borderTop: '1px solid #ddd', textAlign: 'center' }}>
                <Button onClick={() => handleOpenDeleteImageForm(selectedImage)} icon={<DeleteOutlined />} size="middle" danger>Удалить</Button>
              </div>) }

            </div>
          </Col>
          
        )}
      </Row>

      <EditAlbumIdImageForm
        editingImage={editingImage}
        albums={albums}
        onSubmit={handleEditingImageSubmit}
        onCancel={() => setEditingImage(null)}
        isOpen={editingImage !== null}
      />
      <DeleteImageForm
        deletingImage={deletingImage}
        onSubmit={(id) => handleDeletingImageSubmit(id)}
        onCancel={() => setDeletingImage(null)}
        isOpen={deletingImage !== null}
      />
    </Modal>
  );
};

const ExifComponent = ({ data }) => {
  return (
    <div>
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <b>{key}:</b> {value}
        </div>
      ))}
    </div>
  );
};

export default ImagesSlider;

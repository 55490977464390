import React, { useEffect, useState } from 'react';
import { Progress, Typography, Divider, Button } from 'antd';
import { SyncOutlined, CloseOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './InnerYandexLoading.module.css';
import pageStyles from '../../YandexUploadPage.module.css';

const { Title } = Typography;

const InnerYandexLoading = ({ 
  yandexUploadData,
  handleUpdateStatus,
  handleReset,
  handleLogout,
  handleCancelUpload
}) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(5);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Обновление текущего времени каждую секунду для корректного отображения времени загрузки
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);

  // Функция для расчета прошедшего времени
  const getElapsedTime = (startedAt) => {
    if (!startedAt) return 'Н/Д';
    
    // Преобразуем Unix timestamp (секунды) в миллисекунды
    const startTime = new Date(Number(startedAt) * 1000);
    const elapsed = Math.floor((currentTime - startTime) / 1000); // разница в секундах
    
    const hours = Math.floor(elapsed / 3600);
    const minutes = Math.floor((elapsed % 3600) / 60);
    const seconds = elapsed % 60;
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (handleUpdateStatus) {
      setTimeLeft(5);
      const fetchData = async () => {
        try {
          await handleUpdateStatus();
          setError(null);
        } catch (err) {
          setError(err.message || 'Ошибка при загрузке данных');
        }
      };
      fetchData();
    }
  }, [yandexUploadData.status]);

  useEffect(() => {
    if (handleUpdateStatus) {
      setTimeLeft(5);
      const timerInterval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            setRefreshCount(c => c + 1);
            handleUpdateStatus();
            return 5;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [yandexUploadData.status]);

  const getStatusText = (status) => {
    switch (status) {
      case 'SCANNING_DISK_STRUCTURE':
        return 'Сканирование структуры Яндекс.Диска';
      case 'CHECKING_COMPLETION':
        return 'Проверка завершения сканирования';
      case 'PLANNING_UPLOAD':
        return 'Планирование загрузки';
      case 'UPLOADING_FILES':
        return 'Загрузка файлов с Яндекс.Диска';
      case 'UPLOAD_COMPLETED':
        return 'Загрузка завершена';
      case 'ERROR':
        return 'Произошла ошибка';
      default:
        return 'Инициализация Яндекс.Диска';
    }
  };

  return (
    <div className={styles.loadingContainer}>
      {/* Верхний заголовок */}
      <div className={pageStyles.directoryHeader}>
        <div className={pageStyles.directoryHeaderContent}>
          <div className={pageStyles.headerLeftPart}>
            <span className={pageStyles.yandexDiskLabel}>Яндекс.Диск</span>
            <Divider type="vertical" className={pageStyles.headerDivider} />
            <span className={pageStyles.currentDirPath}>
              {getStatusText(yandexUploadData.status)}
            </span>
          </div>
          <Link to="/" className={pageStyles.headerHomeLink}>
            <CloseOutlined className={pageStyles.headerHomeIcon} />
          </Link>
        </div>
      </div>

      <div className={styles.loadingContent}>
        <div className={styles.loadingCard}>
          {(yandexUploadData.status === 'UPLOAD_COMPLETED') ? (
            <CheckCircleOutlined className={styles.CompletedIcon} />
          ) : yandexUploadData.status === 'ERROR' ? (
            <ExclamationCircleOutlined className={styles.errorIcon} />
          ) : (
            <SyncOutlined spin className={styles.syncIcon} />
          )}
          
          <Title level={3} className={styles.loadingTitle}>
            {getStatusText(yandexUploadData.status)}
          </Title>
          
          <div className={styles.progressWrapper}>
            {(yandexUploadData.status === 'UPLOADING_FILES') && (
              <div className={styles.customProgressBar}>
                <Progress 
                  percent={yandexUploadData.progress ? parseFloat(yandexUploadData.progress.percent) : Math.min(100 - (timeLeft * 20), 95)}
                  status="normal"
                  showInfo={true}
                  strokeColor="#ee2250"
                  strokeWidth={16}
                  trailColor="#f0f0f0"
                  format={percent => `${percent}%`}
                />
              </div>
            )}

            {yandexUploadData.status === 'UPLOADING_FILES' || yandexUploadData.status === 'UPLOAD_COMPLETED' ? (
              <div className={styles.refreshInfo}>
                <table className={styles.statsTable}>
                  <tbody>
                    <tr>
                      <td><b>Время загрузки:</b></td>
                      <td><b>{getElapsedTime(yandexUploadData.info?.started_at)}</b></td>
                    </tr>
                    <tr>
                      <td><b>Обработано папок:</b></td>
                      <td><b>{yandexUploadData.progress.total_folders} / {yandexUploadData.info.total_folders}</b></td>
                    </tr>
                    <tr>
                      <td><b>Обработано файлов:</b></td>
                      <td><b>{yandexUploadData.progress.total_files} / {yandexUploadData.info.total_files}</b></td>
                    </tr>
                    <tr>
                      <td>Успешно загружено:</td>
                      <td>{yandexUploadData.progress.files_success}</td>
                    </tr>
                    <tr>
                      <td>Файл был уже загружен ранее:</td>
                      <td>{yandexUploadData.progress.files_duplicate}</td>
                    </tr>
                    <tr>
                      <td>Неподдерживаемый формат файла:</td>
                      <td>{yandexUploadData.progress.files_invalid_format}</td>
                    </tr>
                    <tr>
                      <td>Отправленно на повторную загрузку:</td>
                      <td>{yandexUploadData.progress.files_rescheduled}</td>
                    </tr>
                    <tr>
                      <td>Ошибок после повторных загрузок:</td>
                      <td>{yandexUploadData.progress.files_error}</td>
                    </tr>
                    <tr>
                      <td><b>Файлов в обработке:</b></td>
                      <td><b>{yandexUploadData.progress.in_queue}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (yandexUploadData.status !== 'UPLOAD_COMPLETED' && yandexUploadData.status !== 'ERROR') ? (
              <div>
                {yandexUploadData.status === 'SCANNING_DISK_STRUCTURE' && yandexUploadData.count_yandex_all_dirs ? (
                  <table className={styles.statsTable}>
                    <tbody>
                      <tr>
                        <td><b>В процессе сканирования:</b></td>
                        <td><b>{yandexUploadData.count_yandex_resources_founded_to_do}</b></td>
                      </tr>
                      <tr>
                        <td><b>Обнаружено директорий:</b></td>
                        <td><b>{yandexUploadData.count_yandex_all_dirs}</b></td>
                      </tr>
                      <tr>
                        <td><b>Обнаружено файлов:</b></td>
                        <td><b>{yandexUploadData.count_yandex_all_files}</b></td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <>Пожалуйста, подождите...</>
                )}
              </div>
            ) : yandexUploadData.status === 'ERROR' && (
              <div className={styles.refreshInfo}>
                {yandexUploadData.error_message}
              </div>
            )}
          </div>

          <div className={styles.buttonsContainer}>
            {handleCancelUpload && (
              <Button 
                onClick={handleCancelUpload}
                icon={<CloseOutlined />}
                className={styles.formButton}
                type="default"
                size="small"
              >
                Отменить загрузку
              </Button>
            )}

            {handleReset && (
              <Button 
                onClick={handleReset}
                icon={<SyncOutlined />}
                className={styles.formButton}
                type="default"  
                size="small"
              >
                Начать новую загрузку
              </Button>
            )}
            
            {handleLogout && (
              <Button 
                onClick={handleLogout}
                icon={<CloseOutlined />}
                className={styles.formButton}
                type="default"
                size="small"
                danger
              >
                Сбросить авторизацию
              </Button>
            )}
          </div>

          {(yandexUploadData.status === 'SCANNING_DISK_STRUCTURE' || 
            yandexUploadData.status === 'UPLOADING_FILES') && (
            <div className={styles.cardFooter}>
              <div className={styles.noteInfo}>
                Вы можете уйти с этой страницы — процесс не будет прерван
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InnerYandexLoading; 
import React from 'react';

import { CheckOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons';

const ImagesUpload = ({ file, handleRemoveFile, uploading = true }) => {

  return (
    <div>
      <div
        style={{
          display: 'flex', border: '1px solid #eee', padding: '5px', borderRadius: '5px'
        }}
      >
        <div style={{ width: '100%', height: '150px', borderRadius: '5px',position: 'relative', overflow: 'hidden' }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${file.image})`,
              backgroundSize: 'cover',
              scale: '1.04',
              backgroundPosition: 'center',
              filter: file.uploaded ? 'blur(10px) saturate(0%) opacity(0.2)' : 'blur(10px)',
            }}
          />
          <img
            src={file.image}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              zIndex: 1,
              filter: file.uploaded && 'saturate(0%) opacity(0.4)',
            }}
          />
          {!file.uploaded && !uploading && (
            <button style={{position: 'absolute', content: '', top: '3px', right: '3px', height: '30px', width: '30px', backgroundColor: 'rgba(5, 5, 5, 0.7)', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '50%', zIndex: 1}} onClick={() => handleRemoveFile(file)}>
                <DeleteOutlined style={{color: '#fff'}} />
            </button>
            )}
          {
            file.uploaded &&
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '30px', width: '30px', backgroundColor: 'green', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', zIndex: 1 }}>
              <CheckOutlined style={{color: '#fff'}} />
            </div>
          }
          {
            !file.uploaded && uploading &&
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '30px', width: '30px', backgroundColor: 'white', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', zIndex: 1 }}>
              <SyncOutlined style={{ color: '#000' }} spin />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ImagesUpload;

import React, { useEffect, useState } from 'react';
import { Input, DatePicker, Row, Col, ConfigProvider } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import ruRU from 'antd/lib/locale/ru_RU';
import s from './Contract.module.css';

// Устанавливаем русскую локаль глобально
dayjs.locale('ru');

const Contract = ({ contractNumber, contractDateFrom, contractDateTo, editable, title, isCard, isSlider, onChange }) => {
  const [number, setNumber] = useState(contractNumber || '');
  const [dateFrom, setDateFrom] = useState(contractDateFrom ? dayjs(contractDateFrom) : null);
  const [dateTo, setDateTo] = useState(contractDateTo ? dayjs(contractDateTo) : null);

  const formatDate = (date) => {
    if (!date) return null;
    return date.format('YYYY-MM-DD');
  };

  const formatDisplayDate = (date) => {
    if (!date) return '...';
    return date.format('DD.MM.YYYY');
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
    onChange({
      contractNumber: value,
      contractDateFrom: dateFrom ? formatDate(dateFrom) : null,
      contractDateTo: dateTo ? formatDate(dateTo) : null
    });
  };

  const handleDateFromChange = (date) => {
    setDateFrom(date);
    onChange({
      contractNumber: number,
      contractDateFrom: date ? formatDate(date) : null,
      contractDateTo: dateTo ? formatDate(dateTo) : null
    });
  };

  const handleDateToChange = (date) => {
    setDateTo(date);
    onChange({
      contractNumber: number,
      contractDateFrom: dateFrom ? formatDate(dateFrom) : null,
      contractDateTo: date ? formatDate(date) : null
    });
  };

  useEffect(() => {
    setNumber(contractNumber || '');
    setDateFrom(contractDateFrom ? dayjs(contractDateFrom) : null);
    
    if (contractDateTo) {
      const dateToObj = dayjs(contractDateTo);
      setDateTo(dateToObj);
    } else {
      setDateTo(null);
    }
  }, [contractNumber, contractDateFrom, contractDateTo]);

  return (
    <ConfigProvider locale={ruRU}>
      <div>
        {editable ? (
          <div>
            {title && (
              <div className={s.title}>
                <b>Номер контракта:</b>
              </div>
            )}
            <Input
              size={isSlider ? 'small' : 'large'}
              placeholder="Введите номер договора"
              value={number}
              onChange={handleNumberChange}
              className={cn(s.select, { [s.select_slider]: isSlider })}
            />
            {title && (
              <div className={s.title} style={{ marginTop: '16px' }}>
                <b>Срок полезного использования:</b>
              </div>
            )}
            <Row gutter={16}>
              <Col span={12}>
                <DatePicker
                  size={isSlider ? 'small' : 'large'}
                  placeholder="От"
                  value={dateFrom}
                  onChange={handleDateFromChange}
                  format="DD.MM.YYYY"
                  className={cn(s.select, { [s.select_slider]: isSlider })}
                  name="dateFrom"
                  id="contract-date-from"
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  size={isSlider ? 'small' : 'large'}
                  placeholder="До"
                  value={dateTo}
                  onChange={handleDateToChange}
                  format="DD.MM.YYYY"
                  className={cn(s.select, { [s.select_slider]: isSlider })}
                  name="dateTo"
                  id="contract-date-to"
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div className={isCard ? s.text_wrapper : ''}>
            <div className={s.text_normal}>
              {title && <div className={s.title}><b>Контракт</b></div>}
              <div className={s.text_light}>
                <div style={{ color: number ? 'inherit' : '#ccc' }}>
                  { number || 'Номер контракта не указан'}
                </div>
                {(dateFrom || dateTo) ? (
                  <div style={{ marginTop: isCard ? '8px' : '0' }}>
                    {formatDisplayDate(dateFrom)} - {formatDisplayDate(dateTo)}
                  </div>
                ) : (
                  <div style={{ marginTop: isCard ? '8px' : '0', color: '#ccc' }}>
                    Срок полезного использования не указан
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};

export default Contract;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImagesList_imagesList__mvNz9 {
    margin: 0 !important;
    padding: 20px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ImagesList/ImagesList.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".imagesList {\n    margin: 0 !important;\n    padding: 20px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imagesList": `ImagesList_imagesList__mvNz9`
};
export default ___CSS_LOADER_EXPORT___;

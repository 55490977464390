import Header from './Header/Header';
import AlbumsContainer from './AlbumsContainer/AlbumsContainer';
import AlbumsContainerSkeleton from './AlbumsContainer/AlbumsContainerSkeleton';
import AlbumsList from './AlbumsList/AlbumsList';
import ImagesContainer from './ImagesContainer/ImagesContainer';
import ImagesList from './ImagesList/ImagesList';
import ImagesListSkeleton from './ImagesList/ImagesListSkeleton';
import ImagesLogs from './ImagesLogs/ImagesLogs';
import ImagesLogsSkeleton from './ImagesLogs/ImagesLogsSkeleton';
import ImagesSlider from './ImagesSlider/ImagesSlider';
import ImagesUpload from './ImagesUpload/ImagesUpload';
import License from './License/License';
import Tags from './Tags/Tags';

export {
    Header,

    AlbumsContainer,
    AlbumsContainerSkeleton,
    AlbumsList,

    ImagesContainer,
    ImagesList,
    ImagesListSkeleton,
    ImagesLogs,
    ImagesLogsSkeleton,
    ImagesSlider,
    ImagesUpload,

    License,
    Tags
};
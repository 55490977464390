import { api } from '..'
import { endpoints } from '../endpoints'

export const apiFTPInfo = async () => { 
    try {
        const result = await api.get(endpoints.ftp.info); 
        return result.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized error. Redirect to login page or perform other actions.');
        } else {
            console.error('Error fetching users:', error.message);
        }
        return false;
    }
}

export const apiFTPCheck = async () => { 
    try {
        const result = await api.post(endpoints.ftp.check); 
        return result.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized error. Redirect to login page or perform other actions.');
        } else {
            console.error('Error fetching users:', error.message);
        }
        return false;
    }
}
import React from 'react';

import { Skeleton, Table } from 'antd';

const ImagesLogsSkeleton = ({ count }) => {

  const columns = [
    {
        title: '', dataIndex: 'id', key: 'image', fixed: 'left', width: 90,
        render: (text) => (
          <Skeleton.Avatar size={80} shape="square" />
        ),
      },
      {
          title: 'ID', dataIndex: 'id', key: 'type', width: 40,
          render: (text) => (
            <Skeleton.Input style={{ width: '40px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'Время', dataIndex: 'id', key: 'type', width: 90,
          render: (text) => (
            <Skeleton.Input style={{ width: '90px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'Тип', dataIndex: 'id', key: 'type', width: 90,
          render: (text) => (
            <Skeleton.Input style={{ width: '90px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'Кропы', dataIndex: 'id', key: 'thumbnails', width: 90,
          render: (text) => (
            <Skeleton.Input style={{ width: '90px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'ИИ', dataIndex: 'id', key: 'openai', width: 90,
          render: (text) => (
            <Skeleton.Input style={{ width: '90px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'А.Теги', dataIndex: 'id', key: 'album_tags', width: 90,
          render: (text) => (
            <Skeleton.Input style={{ width: '90px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'Альбом', dataIndex: 'id', key: 'album', width: 256,
          render: (text) => (
            <Skeleton.Input style={{ width: '150px', height: '15px' }} size="small" />
          ),
      },
      {
          title: 'Теги', dataIndex: 'id', key: 'tags',
          render: (text) => (
            <Skeleton.Input style={{ width: '350px', marginTop: '3px', height: '15px' }} size="small" />
          ),
      },
  ];

  const dataSkeleton = Array.from({ length: count }, (_, i) => ({ id: i }));
  
  return (
    <div style={{ flex: '1' }}> 
        <Table
        rowSelection={{
            type: 'checkbox',
        }}
        columns={columns}
        dataSource={dataSkeleton}
        scroll={{ x: 1400 }}
        tableLayout='auto'
        bordered
        pagination={false}
        />
    </div>
  );
  
};

export default ImagesLogsSkeleton;

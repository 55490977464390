import React, { useState, useEffect } from 'react';

import { Col, Row, Button } from 'antd';
import { SaveOutlined, EditOutlined } from '@ant-design/icons';

import {
    Tags,
    License,
  } from '../../components/index.js';

const EditTagsLicenseImageForm = ({ image, isEditableImage, onSubmit }) => {

    const [isEditingImage, setIsEditingImage] = useState(false);

    useEffect(() => {
        setIsEditingImage(false);
    }, [image]);

    const [tags, setTags] = useState(image.tags);
    const [license, setLicense] = useState(image.license);

    const handleSubmit = () => {
        setIsEditingImage(false);
        const formData = {
            tags,
            license,
        };
        onSubmit(image.id, formData);
    };

    const handleChangeTags = (value) => {
        setTags(value);
    };

    const handleLicenseChange = (value) => {
        setLicense(value);
    };

  return (
    <Row align="top">
        <Col span={20} style={{ padding: '20px', width: '100%' }}>
            <div style={{ marginBottom: '5px', width: '100%' }}>
                <License license={image.license} isSlider={true} editable={isEditingImage} title={true} onChange={handleLicenseChange} />
            </div>
            <div>
                <div style={{ marginTop: '20px' }}><b>Теги:</b></div>
                <Tags imageTags={image.tags} imageAlbumTags={image.album_tags} isEditingTags={isEditingImage} onChange={handleChangeTags} />
            </div>
        </Col>
        <Col span={4} style={{ padding: '20px', textAlign: 'right' }}>
            {
            isEditableImage
            &&
            <div><Button type={isEditingImage ? "primary" : "dashed"} icon={isEditingImage ? <SaveOutlined /> : <EditOutlined />} onClick={() => isEditingImage ? handleSubmit() : setIsEditingImage(true) } size="small"/></div> //setIsEditingImage(!isEditingImage)
            }
        </Col>
    </Row>
  );
};

export default EditTagsLicenseImageForm;

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Col, Row, Button, Upload, Divider } from 'antd';
import { CloseCircleOutlined, UploadOutlined, LeftOutlined, InboxOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  ImagesUpload,
} from '../../components/index.js';

import providerContext from '../../shared/provider/providerContext';
import { getMethods } from '../../shared/provider/methods';

import style from '../../pages/Home/Home.module.css'
import s from '../../pages/Users/Users.module.css'

const UploadImagesForm = ({ activeAlbumId }) => {
  
    const { state, dispatch } = useContext(providerContext);
    const { uploadImages } = getMethods(dispatch);

    const { Dragger } = Upload;

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');

    const [uploadedFileList, setUploadedFileList] = useState([]);

    const beforeUpload = (file) => {
        setFileList((prevFileList) => [
          ...prevFileList,
          { image: URL.createObjectURL(file), file, uploaded: false }
        ]);
        return false;
      };
    
      const upload = async () => {
        try {
          setUploading(true);
          const formData = new FormData();
          if (activeAlbumId !== null) {
            formData.append('album_id', activeAlbumId);
          }
    
          fileList.forEach((file) => {
            formData.append(`images[]`, file.file);
          });
    
          await uploadImages(formData)
            .then(() => {
              setError('');
    
              fileList.map((file) => {
                setUploadedFileList((prevFileList) => [
                    ...prevFileList,
                    file.uploaded ? file : { ...file, uploaded: true }
                ]
              )
              });
    
              setFileList([]);
          });
        } catch (error) {
          setError(error.message)
          console.error('Ошибка при загрузке изображений', error);
        } finally {
          setUploading(false);
        }
      };
    
      const onRemove = (file) => {
        const newFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(newFileList);
      };
    
      const onRemoveAll = () => {
        const newFileList = fileList.filter((item) => item.uploaded);
        setFileList(newFileList);
      }

    return (
        <div className={style.imagesContainer}>
          <div className={s.user_header} style={{ gap: '20px' }}>
            <div>
              <Row>
                <Col className={s.user_total}>
                  <Link to='/'>
                      <Button icon={<LeftOutlined />} size='large' type="default" />
                  </Link>
                </Col>
              </Row>
            </div>
            <div>
              <Row style={{ gap: '10px' }}>
                <Col className={s.user_total} style={{ alignItems: 'center' }}>
                  <div>
                    <b>Выбрано:</b>&nbsp;{fileList.length}
                  </div>
                </Col>
                <Col className={s.user_btn}>
                  <Row style={{ gap: '10px' }}>
                    <Col className={s.user_btn}>
                      <Button
                        icon={<CloseCircleOutlined />}
                        onClick={onRemoveAll}
                        size="large"
                        disabled={uploading || fileList.length === 0}
                      >
                        Очистить
                      </Button>
                    </Col>
                    <Col className={s.user_btn}>
                      <Button
                        type="primary"
                        size="large"
                        icon={<UploadOutlined />}
                        disabled={uploading || fileList.length === 0 || fileList.length > 10}
                        style={!uploading && fileList.length > 0 && 10 < fileList.length && {backgroundColor: '#29355c'}}
                        loading={uploading}
                        onClick={upload}
                      >
                        Загрузить
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div style={{ padding: '60px' }}>
            <Dragger multiple onRemove={onRemove} showUploadList={false} fileList={fileList} style={{ padding: '0 40px' }} beforeUpload={beforeUpload}>
                <p className="ant-upload-drag-icon">
                <InboxOutlined style={{color: '#29355c'}} />
                </p>
                <p className="ant-upload-text">Нажмите или перетащите фото в это поле для загрузки</p>
                <p className="ant-upload-hint">
                За один раз можно загрузить не более 10 файлов
                </p>
            </Dragger>
          </div>
          <div style={{ padding: '20px', borderTop: '1px dotted #ddd' }}>
            <Row gutter={[16, 16]}>
            {[...uploadedFileList, ...fileList].slice().reverse().map((file) => (
                <Col key={file.uid} className="gutter-row" xs={24} sm={12} md={8} lg={6} xl={4}>
                <div key={file.uid} style={{position: 'relative'}}>
                    <ImagesUpload file={file} handleRemoveFile={onRemove} uploading={uploading} />
                </div>
                </Col>
            ))}
            </Row>
          </div>
        </div>
    );
};

export default UploadImagesForm;

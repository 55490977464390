import React from 'react';
import { Space, Divider, Button, Checkbox } from 'antd';
import { 
  ArrowLeftOutlined, 
  CloudUploadOutlined, 
  LogoutOutlined,
  EditOutlined,
  RedoOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './InnerYandexStructure.module.css';
import pageStyles from '../../YandexUploadPage.module.css';

const InnerYandexSkeleton = ({ currentDir, info, tokenExpiresAt, itemsCount = 8 }) => {
  const skeletonItems = Array.from({ length: itemsCount }, (_, index) => (
    <div key={index} className={`${styles.itemCard} ${styles.skeletonCard}`}>
      {/* Пустой блок вместо иконки-изображения */}
      <div className={styles.skeletonImage}></div>
      
      {/* Скелетон для имени */}
      <div className={styles.itemNameContainer}>
        <div className={styles.skeletonText}></div>
      </div>
    </div>
  ));

  return (
    <div className={styles.structureContainer}>
      {/* Черная панель с информацией о директории */}
      <div className={pageStyles.directoryHeader}>
        <div className={pageStyles.directoryHeaderContent}>
          <div className={pageStyles.headerLeftPart}>
            <span className={pageStyles.yandexDiskLabel}>Яндекс.Диск</span>
            <Divider type="vertical" className={pageStyles.headerDivider} />
            <span className={pageStyles.currentDirPath}>Вы здесь: {currentDir}</span>
          </div>
          <Link to="/" className={pageStyles.headerHomeLink}>
            <CloseOutlined className={pageStyles.headerHomeIcon} />
          </Link>
        </div>
      </div>

      {/* Верхний бар с кнопками - используем тот же, что и в основном компоненте */}
      <div className={styles.filterBar}>
        <div className={styles.filterBarContent}>
          <div className={styles.filterBarRow}>
            <div className={styles.filterBarInfo}>
              <div className={styles.massActionsHeader}>
                <Space size="small" className={styles.controlsRow}>
                  <Checkbox 
                    disabled={true}
                  >
                    Выбрать все
                  </Checkbox>

                  <Divider type="vertical" className={styles.actionsDivider} />

                  <span className={styles.selectedCount}>
                    Выбрано: 0
                  </span>
                  
                  <Divider type="vertical" className={styles.actionsDivider} />
                  
                  <Button 
                    icon={<EditOutlined />} 
                    size="small"
                    disabled={true}
                  >
                    Настройки файлов
                  </Button>
                  
                  <Divider type="vertical" className={styles.actionsDivider} />
                  
                  <Button 
                    icon={<RedoOutlined />}
                    danger
                    size="small"
                  >
                    Сбросить всё
                  </Button>
                </Space>
              </div>
            </div>

            <div className={`${styles.uploadInfo} ${styles.discreteInfo}`}>
              <div className={styles.infoContent}>
                <span className={styles.infoLabel}>Папок: {info.total_folders}</span>
                <span className={styles.infoSeparator}>•</span>
                <span className={styles.infoLabel}>Файлов: {info.total_files}</span>
              </div>
            </div>
            
            <div className={styles.actionButtonsWrapper}>
              <Button 
                type="primary"
                icon={<CloudUploadOutlined />}
                className={styles.actionButton}
              >
                Начать загрузку
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Контент с скелетонами папок и файлов */}
      <div className={styles.contentContainer}>
        <div className={styles.itemsGrid}>
          {/* Кнопка "Назад" в виде папки */}
          <div className={`${styles.itemCard} ${styles.folderCard} ${styles.backFolderCard} ${styles.skeletonCard}`}>
            <div className={styles.itemIcon}>
              <div className={styles.folderIconWrapper}>
                <ArrowLeftOutlined className={styles.backFolderIcon} />
              </div>
            </div>
            <div className={styles.itemNameContainer}>
              <div className={styles.skeletonText}></div>
            </div>
          </div>
          
          {/* Скелетоны для папок и файлов */}
          {skeletonItems}
        </div>
      </div>

      <div className={styles.bottomPanel}>
        <div className={styles.sessionInfo}>
          Сессия до: {tokenExpiresAt}
        </div>
        <Button 
          icon={<LogoutOutlined />} 
          danger={true}
          size="small"
          className={styles.logoutButton}
        >
          Выйти из Яндекс.Диска
        </Button>
      </div>
    
    </div>
  );
};

export default InnerYandexSkeleton; 
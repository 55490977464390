import { handleApiError, api, endpoints } from '../api';
import { actions } from './reducer/actions';

export const getMethods = (dispatch) => {

  // AUTHENTICATION

  async function whoam() {
    console.log('-- whoam --');

    try {
      const result = await api.get(endpoints.authenticate.whoam);
      dispatch({
        type: actions.whoam,
        payload: result.data
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  // IMAGES

  async function setImagesParams(params, console = false) {
    dispatch({
      type: !console ? actions.setImagesParams : actions.setImagesParamsConsole,
      payload: params
    });
  }

  async function discoverTags(name) {
    try {
      const result = await api.get(endpoints.tags.discover, { params: { "name": name }}).then(response => response.data);
      dispatch({
        type: actions.discoverTags,
        payload: result.length > 0 ? result : []
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function uploadImages(form) {
    try {
      const result = await api.post(endpoints.images.upload, form).then(response => response.data)
      dispatch({
        type: actions.uploadImage,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function getImages(data) {
    console.log('-- getImages --');
    console.log(data);

    try {
      const result = await api.get(endpoints.images.list, { params: data });
      dispatch({
        type: actions.listImages,
        payload: result.data
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function updateImage(id, form) {
    try {
      const result = await api.put(endpoints.images.update(id), form).then(response => response.data);
      dispatch({
        type: actions.updateImage,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function deleteImage(id) {
    try {
      const result = await api.delete(endpoints.images.delete(id)).then(response => response.data);
      dispatch({
        type: actions.deleteImage,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function rethumbnailingImages(ids) {
    try {
      const result = await api.post(endpoints.images.rethumbnailing, {ids: ids}).then(response => response.data);
      console.log(result.data);
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function retaggingImages(ids) {
    try {
      const result = await api.post(endpoints.images.retagging, {ids: ids}).then(response => response.data);
      console.log(result.data);
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function albumRetaggingImages(ids) {
    try {
      const result = await api.post(endpoints.images.albumRetagging, {ids: ids}).then(response => response.data);
      console.log(result.data);
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }


  // ALBUMS

  async function getAlbums() {
    console.log('-- getAlbums --');

    try {
      const result = await api.get(endpoints.albums.list);
      dispatch({
        type: actions.listAlbums,
        payload: result.data
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function createAlbums(form) {
    try {
      const result = await api.post(endpoints.albums.create, form).then(response => response.data);
      dispatch({
        type: actions.createAlbum,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function updateAlbums(id, form) {
    try {
      const result = await api.put(endpoints.albums.update(id), form).then(response => response.data);
      dispatch({
        type: actions.updateAlbum,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function deleteAlbums(id) {
    try {
      const result = await api.delete(endpoints.albums.delete(id)).then(response => response.data);
      dispatch({
        type: actions.deleteAlbum,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  // USERS

  async function getUsers(page) {
    console.log('-- getUsers, page: ', page, ' --');

    try {
      const result = await api.get(endpoints.users.list(page));
      dispatch({
        type: actions.listUsers,
        payload: result.data
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function createUser(form) {
    try {
      const result = await api.post(endpoints.users.create, form).then(response => response.data);
      dispatch({
        type: actions.createUser,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function updateUser(id, form) {
    try {
      const result = await api.put(endpoints.users.update(id), form).then(response => response.data);
      dispatch({
        type: actions.updateUser,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }

  async function deleteUser(id) {
    try {
      const result = await api.delete(endpoints.users.delete(id)).then(response => response.data);
      dispatch({
        type: actions.deleteUser,
        payload: result
      });
    } catch (error) {
      handleApiError(error, dispatch);
    }
  }  

  return {
    whoam, 

    setImagesParams,
    discoverTags,
    uploadImages,
    getImages,
    updateImage,
    deleteImage,
    rethumbnailingImages,
    retaggingImages,
    albumRetaggingImages,
    
    getAlbums,
    createAlbums,
    updateAlbums,
    deleteAlbums,

    getUsers,
    createUser,
    updateUser,
    deleteUser,
  }
}

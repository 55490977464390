import React from 'react';
import { Layout, Skeleton } from 'antd';

import style from '../../pages/Home/Home.module.css'

const AlbumsContainerSkeleton = ({ count }) => {

  const {Sider} = Layout;

  const blockStyleSider = {
    overflow: 'auto',
    position: 'fixed',
    left: '0',
    top: '0',
    bottom: '0',
    borderRight: '1px solid #ddd',
    marginTop: '64px',
    zIndex: '2',
    marginBottom: '95px',
    backgroundColor: '#fff',
  };
  
  const blockStyleButtons = {
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '256px',
    zIndex: '3',
    backgroundColor: '#fff',
    borderRight: '1px solid #ddd',
  };
  
  const blockStyleButtonManage = {
    padding: '20px',
    textAlign: 'center',
    paddingBottom: '30px',
  };
  
  const blockStyleButtonCreate = {
    padding: '20px',
    borderTop: '1px solid #ddd',
    paddingBottom: '30px',
  };

  const blockStyleButton = {
    height: '40px',
  };

  const blockStyleTitle = {
    borderBottom: '1px solid #ddd',
    paddingTop: '3px',
    paddingLeft: '15px',
  };

  const blockStyleParagraph = {
    borderBottom: '1px solid #eee',
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingLeft: '15px',
    paddingRight: '15px',
  };

  const renderBlocks = (count) => {
    const blocks = [];
    for (let i = 0; i < count; i++) {
      blocks.push(
        <div key={i} style={i == 0 ? blockStyleTitle : blockStyleParagraph}>
          <Skeleton paragraph={false} title={ i == 0 ? { width: '40%' } : { width : '70%'} } />
        </div>
      );
    }
    return blocks;
  };

  return (
    <div className={style.albumsContainer}>
      <div style={{ paddingBottom: '20px' }}>{renderBlocks(count)}</div>
      <div className={style.albumsControls}>
        <div style={blockStyleButtonManage}>
          <Skeleton.Button size="larder" type="dashed" block style={blockStyleButton} />
        </div>
        <div style={blockStyleButtonCreate}>
          <Skeleton.Button size="larder" type="dashed" block style={blockStyleButton} />
        </div>
      </div>
    </div>
  );
  
};

export default AlbumsContainerSkeleton;

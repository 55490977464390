import { Card, Col, Row, Pagination, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import {
    Tags,
    License,
  } from '../../components/index.js';

import style from '../../pages/Home/Home.module.css'

const ImagesList = ({ images, albums, meta, handlePageChange, setAlbumSlider, setSelectedImage }) => {

    return (
        <div>
            <Row gutter={[20, 20]} className={style.imagesList}>
                {images.map((image, index) => (
                    <Col key={index} xs={24} sm={24} md={24} lg={12} xl={8} className={style.imageCard}>
                    <Card
                        hoverable
                        style={{ display: 'flex', flexDirection: 'column', height: '100%', borderTopLeftRadius: '19px', borderTopRightRadius: '19px' }}
                        cover={
                            ( image.url_thumbnail ?
                                <div key={index} style={{ borderBottom: '3px solid #333', width: 'auto', height: '200px', borderTopLeftRadius: '19px', borderTopRightRadius: '19px', position: 'relative', overflow: 'hidden', backgroundColor: '#666' }}>
                                    <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: `url(${image.url_thumbnail})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        filter: 'blur(10px)',
                                        scale: '1.04',
                                    }}
                                    />
                                    <img
                                    src={image.url_thumbnail}
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        zIndex: 1
                                    }}
                                    />
                                </div>
                                :
                                <div key={index} style={{ borderBottom: '3px solid #999', color: '#ccc', fontSize: '16px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#eee', width: 'auto', height: '200px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', position: 'relative', overflow: 'hidden' }}>
                                    Обработка изображения...
                                </div>
                            )
                        }
                        onClick={() => {
                            setSelectedImage(index);
                            setAlbumSlider(true);
                        }}
                        bodyStyle={{padding: '0', height: '100%'}}
                    >
                        <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                        >
                        <Row style={{ flex: 1, padding: '5px 15px 0px 15px', fontSize: '12px', alignItems: "start", color: '#888' }}>
                            <Col style={{ fontSize: '12px', display: 'flex', paddingRight: '20px' }}>
                                {albums.find((album) => album.id == image.album_id)?.title || "Корневой альбом"}
                                { image.is_updated_album_id &&
                                    <Tooltip title="Изображение было перемещено">
                                        <WarningOutlined style={{ marginLeft: '5px', color: '#ee2250' }} />
                                    </Tooltip>
                                }
                            </Col>
                            <Col style={{ fontSize: '12px', flex: 1, display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
                                <License license={image.license} editable={false} isCard={true} />
                            </Col>
                        </Row>
                        { image.tags?.length > 0 || image.album_tags?.length > 0 ?
                            <div style={{ padding: '15px 15px 15px 15px', borderTop: '0px solid #eee', backgroundColor: '#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                <Tags imageTags={image.tags} imageAlbumTags={image.album_tags} editable={false} style={{ borderTop: '1px solid #eee' }} />
                            </div>
                        :
                            <div style={{ color: '#ccc', fontSize: '12px', display: 'flex', justifyContent: 'left', backgroundColor: '#fff', borderTop: '0px solid #eee', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', padding: '15px' }}>
                                Теги для фотографии не указаны
                            </div>
                        }
                        </div>
                    </Card>
                    </Col>
                ))}

            </Row>
            { meta && images.length > 0 && (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}>
                    <Pagination onChange={handlePageChange} total={meta?.total} pageSize={meta?.per_page} current={meta?.current_page} defaultCurrent={1} showSizeChanger={false} />
                </div>
            )}

        </div>
    );
}

export default ImagesList;

import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Layout, Button, Col, Row, Drawer } from 'antd';
import { LoginOutlined, MenuOutlined, CameraOutlined, TeamOutlined, CodeOutlined, VideoCameraOutlined } from '@ant-design/icons';

import providerContext from '../../shared/provider/providerContext';

import cn from 'classnames';
import komitet from '../../assets/img/Komitet.png';
import s from './Header.module.css';

const Header = () => {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(providerContext);

  const { Header } = Layout;
  const location = useLocation();
  const navigate = useNavigate();


  const handleLogout = () => {
    dispatch({ type: 'logout' });
    navigate('/login');
  };

  return (
    <Header className={s.header}>
      <Row gutter={0} className={s.wrapper}>
        <Col xs={6} xl={4} className={s.logo}>
          <Link to='/' className={s.logo_link}>
            <div className={s.logo_wrapper}>
              {false && (<img src={komitet} className={s.logo_komitet} alt='komitet'/>)}
            </div>
          </Link>
        </Col>
        
        <Col xs={0} xl={16} className={s.links}>
          <div>
            <Link
              to='/'
              className={cn(s.link, {[s.link_active]: location.pathname === '/' || location.pathname === '/upload' || location.pathname === '/images/yandex'})}
            >
              <Button
                type='link'
                className={s.button}
              >
                <CameraOutlined /> Изображения
              </Button>
            </Link>
            <Link
              to='/'
              className={cn(s.link, {[s.link_active]: location.pathname === '/videos' || location.pathname === '/videos/yandex'})}
            >
              <Button
                type='link'
                className={s.button}
              >
                <VideoCameraOutlined /> Видеофайлы
              </Button>
            </Link>
            { (state.whoam?.role >= 2) && <Link
              to='/users'
              className={cn(s.link, {[s.link_active]: location.pathname === '/users'})}
            >
              <Button
                type='link'
                className={s.button}
              >
                <TeamOutlined /> Пользователи
              </Button>
            </Link> }
            { state.whoam?.role >= 2 && <Link
              to='/console'
              className={cn(s.link, {[s.link_active]: location.pathname === '/console'})}
            >
              <Button
                type='link'
                className={s.button}
              >
                <CodeOutlined /> Консоль
              </Button>
            </Link> }
          </div>
        </Col>

        <Col xs={0} xl={4} className={s.menu}>
          <div className={s.userEmail}>{state.whoam?.email}</div>
          <div className={s.userAction}>
            <Button icon={<LoginOutlined />} danger onClick={handleLogout} type="link">
              Выйти
            </Button>
          </div>
        </Col>

        <Col className={s.drawer} xs={18} xl={0}>
          <button className={s.drawer_close} onClick={() => setOpen(!open)}>
            <MenuOutlined className={s.drawer_close_icon}/>
          </button>
          <Drawer
            styles={{ body: {display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}}
            open={open}
            onClose={() => setOpen(!open)}
            placement="right"
          >
            <Col className={s.drawer_wrapper} style={{ justifyContent: 'center' }}>
              <Link
                to='/'
                className={cn(s.drawer_link, {[s.drawer_link_active]: location.pathname === '/' || location.pathname === '/upload'})}
              >
                <Button
                  icon={<CameraOutlined />}
                  type='link'
                  className={s.drawer_button}
                  onClick={() => setOpen(false)}
                >
                  Изображения
                </Button>
              </Link>
              <Link
                to='/videos'
                className={cn(s.drawer_link, {[s.drawer_link_active]: location.pathname === '/videos'})}
              >
                <Button
                  icon={<VideoCameraOutlined />}
                  type='link'
                  className={s.drawer_button}
                  onClick={() => setOpen(false)}
                >
                  Видеофайлы
                </Button>
              </Link>
              { state.whoam?.role >= 2 && <Link
                to='/users'
                className={cn(s.drawer_link, {[s.drawer_link_active]: location.pathname === '/users'})}
              >
                <Button
                  icon={<TeamOutlined />}
                  type='link'
                  className={s.drawer_button}
                  onClick={() => setOpen(false)}
                >
                  Пользователи
                </Button>
              </Link> }
              { state.whoam?.role >= 2 && <Link
                to='/console'
                className={cn(s.drawer_link, {[s.drawer_link_active]: location.pathname === '/console'})}
              >
               <Button
                  icon={<CodeOutlined />}
                  type='link'
                  className={s.drawer_button}
                  onClick={() => setOpen(false)}
                >
                  Консоль
                </Button>
              </Link> }
            </Col>
            <Col className={s.drawerColLogout}>
              <div style={{ color: '#666', padding: '10px', borderTop: '1px solid #eee', borderBottom: '1px solid #eee', marginBottom: '20px' }}>{state.whoam?.email}</div>
              <Button className={s.drawer_logout} icon={<LoginOutlined />} danger onClick={handleLogout} type="link">
                Выйти
              </Button>
            </Col>
          </Drawer>
        </Col>
      </Row>
    </Header>
  );
};

export default Header;

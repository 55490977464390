import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PartitionOutlined,
  RightOutlined,
} from '@ant-design/icons';

import cn from 'classnames';
import s from '../../components/AlbumsContainer/AlbumsContainer.module.css';
import style from '../../pages/Home/Home.module.css'

const AlbumsList = ({ albums, activeAlbumId, onClick, onDelete, onEdit, editable = true, isModal = false, fullHeight }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  
  const [openedAlbumId, setOpenedAlbumId] = useState(null);
  
  const getAncestors = (albumId) => {
    if (!albumId) return [];
    
    const ancestors = [];
    let currentAlbum = albums.find(album => album.id === albumId);
    
    while (currentAlbum && currentAlbum.parent_id) {
      ancestors.push(currentAlbum.parent_id);
      currentAlbum = albums.find(album => album.id === currentAlbum.parent_id);
    }
    
    return ancestors;
  };
  
  const shouldBeExpanded = (albumId) => {

    if (editable) return true;

    if (albumId === openedAlbumId) return true;
    
    const openedAlbumAncestors = getAncestors(openedAlbumId);
    if (openedAlbumAncestors.includes(albumId)) return true;

    // Убрали принудительное открытие альбома, если он является предком активного альбома
    // setOpenedAlbumId(albumId);
    // const activeAlbumAncestors = getAncestors(activeAlbumId);
    // if (activeAlbumAncestors.includes(albumId)) return true;

    return false;
  };
  
  const openedAlbumAncestors = React.useRef([]);
  const activeAlbumAncestors = React.useRef([]);
  
  React.useEffect(() => {
    openedAlbumAncestors.current = getAncestors(openedAlbumId);
  }, [openedAlbumId]);
  
  React.useEffect(() => {
    activeAlbumAncestors.current = getAncestors(activeAlbumId);
  }, [activeAlbumId]);
  
  React.useEffect(() => {
    if (!activeAlbumId) return;
    
    const children = albums.filter(album => album.parent_id === activeAlbumId);
    
    if (children.length > 0) {
      onClick(children[0]);
      setOpenedAlbumId(activeAlbumId);
    }
  }, [albums]);
  
  const handleModalAlbumClick = (album) => {
    if (openedAlbumId === album.id) { // Если альбом раскрыт, то закрываем его
      const parent = albums.find(a => a.id === album.parent_id);
      onClick(parent || null);
      setOpenedAlbumId(parent ? parent.id : null);
    } else { // Если альбом не раскрыт, то открываем его
      onClick(album);
      setOpenedAlbumId(album.id);
    }
  };
  
  const handleRegularAlbumClick = (album) => {

    // Если альбом является активным, то сбрасываем выбор
    if (album.id === activeAlbumId) {
      onClick(null);
      return;
    }

    // Убрали проверку на предка активного альбома, так как она не нужна
    // Нельзя ничего сделать с альбомом, если он является предком активного альбома
    // const isAncestorOfActiveAlbum = activeAlbumAncestors.current.includes(album.id);
    // if (isAncestorOfActiveAlbum) {
    //   return;
    // }

    const hasChildren = albums.some(item => item.parent_id === album.id);
    
    if (hasChildren) { // Если альбом имеет детей
      const isAncestorOfOpenedAlbum = openedAlbumAncestors.current.includes(album.id);
      if (album.id === openedAlbumId || isAncestorOfOpenedAlbum) { // Если альбом раскрыт, то закрываем его
        const parent = albums.find(a => a.id === album.parent_id);
        setOpenedAlbumId(parent ? parent.id : null);
      } else { // Если альбом не раскрыт, то открываем его
        setOpenedAlbumId(album.id);
      }
    }
    
    if (!hasChildren) { // Если альбом не имеет детей, то выбираем его
      onClick(album);
    }
  };
  
  const handleAlbumClick = (album) => {
    
    if (!album) {
      onClick(null);
      return;
    }
    
    if (isModal) {
      handleModalAlbumClick(album);
    } else {
      handleRegularAlbumClick(album);
    }
  };
  
  const renderAlbumItems = (parentId = null, level = 0) => {
    const albumsToRender = albums.filter(album => album.parent_id === parentId);
    
    return albumsToRender.map(album => {
      const hasChildren = albums.some(item => item.parent_id === album.id);
      const isExpanded = shouldBeExpanded(album.id);
      
      return (
        <React.Fragment key={album.id}>
          <li>
            <Button
              type='text'
              onClick={() => !editable && handleAlbumClick(album)}
              className={cn(s.list, {
                [s.list_selected]: activeAlbumId === album.id,
                [s.list_manage]: editable,
                [s.list_contains_selected]: !isModal && !isExpanded && activeAlbumAncestors.current.includes(album.id)
              })}
              style={{ paddingLeft: `${level * 20 + 16}px` }}
            >
              {isModal && activeAlbumId !== album.id && !hasChildren && (
                <EllipsisOutlined className={s.title_icon_light}/>
              )}
              {isModal && activeAlbumId !== album.id && hasChildren && (
                <PartitionOutlined className={s.title_icon_light}/>
              )}
              {isModal && activeAlbumId === album.id && (
                <CheckCircleOutlined className={s.title_icon} />
              )}
              <span className={cn(s.list_item, {[s.list_item_bold]: isExpanded})}>
                {album.title}
              </span>
              {
                editable &&
                <div className={s.list_buttons}>
                  <Button
                    onClick={() => onEdit(album)}
                    icon={<EditOutlined />}
                    key="list-loadmore-edit"
                    size='small'
                    shape="circle"
                  />
                  <Button
                    onClick={() => onDelete(album)}
                    icon={<DeleteOutlined />}
                    danger
                    key="list-loadmore-more"
                    size='small'
                    shape='circle'
                  />
                </div>
              }
              {!editable && hasChildren && (
                isExpanded ? 
                  <DownOutlined className={cn(s.title_icon, s.left_auto)}/> :
                  <RightOutlined className={cn(s.title_icon, s.left_auto)}/>
              )}
              {!editable && !isModal && activeAlbumId === album.id && (
                currentPath === '/upload'
                  ? <CheckCircleOutlined className={cn(s.title_icon, s.left_auto)}/>
                  : <EyeOutlined className={cn(s.title_icon, s.left_auto)}/>
              )}
            </Button>
          </li>
          
          {isExpanded && renderAlbumItems(album.id, level + 1)}
        </React.Fragment>
      );
    });
  };
  
  return (
    <div>
      <Row className={style.albumsList}>
        <Col className={cn(s.text_wrapper, {[s.text_modal]: !isModal })}>
          <h4 className={s.album_title}>
            {isModal ? 'Корневой альбом' : 'Все альбомы'}
          </h4>
          {isModal && activeAlbumId === null && (
            <CheckCircleOutlined className={cn(s.title_icon, s.left10)}/>
          )}
          {!isModal && activeAlbumId === null && (
            currentPath === '/upload'
              ? <CheckCircleOutlined className={cn(s.title_icon, s.left20)}/>
              : <EyeOutlined className={cn(s.title_icon, s.left20)}/>
          )}
        </Col>
      </Row>
      <div className={fullHeight ? s.items_wrapper_full : cn(s.items_wrapper, {[s.items_modal]: isModal})}>
        <ul className={s.list_wrapper}>
          {renderAlbumItems()}
        </ul>
      </div>
    </div>
  );
};

export default AlbumsList;

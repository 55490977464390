import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Provider from './shared/provider/provider';
import PrivateRoute from './PrivateRoute';

import Header from './components/Header/Header';
import { Authentication, Home, Users, Console } from './pages';

function App() {
  return (
    <Router>
      <Provider>
        <Routes>
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Header />
                <Routes>
                  <Route index element={<Home isUploadingMode={false} />} />
                  <Route path='/upload' element={<Home isUploadingMode={true} />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/console" element={<Console />} />
                </Routes>
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Authentication />} />
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';

import {
    ImagesList,
    ImagesListSkeleton,
    ImagesLogs,
    ImagesSlider,
  } from '../../components/index.js';
  
  import {
    FilterImagesForm,
  } from '../../forms/index.js';

import style from '../../pages/Home/Home.module.css'

const ImagesContainer = ({ images, meta, albums, activeAlbumId = null, handleSearchFormChange, handlePageChange, handleActiveAlbumChange, isEditableImage, loadingImages, loadingAlbums, logsView = false, isLoaded = false }) => {

    const [isOpenedImagesSlider, setIsOpenedImagesSlider] = useState(false);
    const [selectedImage, setSelectedImage] = useState(false);

    const [tagToAdd, setTagToAdd] = useState(null);
    const handleTagClick = (tagName) => {
        setTagToAdd(tagName);
    };

    return (
        <div style={{ flex: '1' }}>
            { !logsView ?
                <div className={style.imagesContainer}>
                    <FilterImagesForm albums={albums} activeAlbumId={activeAlbumId} handleSearchFormChange={handleSearchFormChange} handleActiveAlbumChange={handleActiveAlbumChange} isEditableImage={isEditableImage} tagToAdd={tagToAdd} />
                    
                    {isLoaded ?
                        <ImagesList images={images} albums={albums} meta={meta} handlePageChange={handlePageChange} handleTagClick={handleTagClick} setSelectedImage={setSelectedImage} setAlbumSlider={setIsOpenedImagesSlider} />    
                    :
                        <ImagesListSkeleton count={9} />   
                    }
                </div>
                : 
                <ImagesLogs images={images} albums={albums} meta={meta} handleSearchFormChange={handleSearchFormChange} handlePageChange={handlePageChange} setSelectedImage={setSelectedImage} setAlbumSlider={setIsOpenedImagesSlider} loadingImages={loadingImages} loadingAlbums={loadingAlbums} />
                
            }
            { images && 
            <ImagesSlider
                images={images}
                albums={albums}
                selectedImageIndex={selectedImage}
                handlerSelectedImageIndex={setSelectedImage}
                isOpenedImagesSlider={isOpenedImagesSlider}
                handlerIsOpenedImagesSlider={setIsOpenedImagesSlider}
                isEditableImage={isEditableImage}  
            />
            }
        </div>
    );
};

export default ImagesContainer;
